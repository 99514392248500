import { Formik, FormikProps } from "formik"
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material"
import {
  BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
  PAGE_LABEL_BANK_REFERENCE,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"
import SectionWhenComponent from "../SectionWhenComponent"

export default function CreditSectionBankReferenceDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const page = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_BANK_REFERENCE,
  )

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  const getConfigFormControlValueField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.value = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  value: event.target.checked,
                })
              }
            }}
          />
        }
        label={label + " Config Value"}
      />
    )
  }

  const getConfigFormControlRequiredField = (
    value: boolean,
    name: string,
    label: string,
    props: FormikProps<Page>,
  ) => {
    return (
      <FormControlLabel
        control={
          <Switch
            size="small"
            name={name}
            checked={value}
            onChange={(event) => {
              const config = getConfigFromPage(props.values, label) as Config
              if (config) {
                props.setFieldValue(
                  "config",
                  props.values.config.map((c) => {
                    if (c.label === label) {
                      c.required = event.target.checked
                    }
                    return c
                  }),
                )
              } else {
                props.values.config.push({
                  label: label,
                  description: label,
                  required: event.target.checked,
                  value: true,
                })
              }
            }}
          />
        }
        label={label + " Config Required"}
      />
    )
  }

  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={page ? page : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="required"
                      checked={props.values.required as boolean}
                      onChange={props.handleChange}
                    />
                  }
                  label="Require Section"
                />
                {getConfigFormControlValueField(
                  getConfigFromPage(
                    props.values,
                    BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
                  )?.value as boolean,
                  "routingNumberValue",
                  BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
                  props,
                )}
                {getConfigFormControlRequiredField(
                  getConfigFromPage(
                    props.values,
                    BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
                  )?.required as boolean,
                  "routingNumberRequired",
                  BANK_REFERENCE_CONFIG_ROUTING_NUMBER,
                  props,
                )}
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
        {page && (
          <SectionWhenComponent
            page={page}
            template={applicationTemplate}
            refetch={refetch}
          />
        )}
      </Stack>
    </>
  )
}
