import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

export function useDeleteTradeReferenceRequest(onSuccess?: () => void) {
  const d = useTypedMutation<object>(
    "post/application/reference/request",
    onSuccess,
  )

  const execute = (
    reference_id?: string | undefined,
    onSuccess?: () => void,
  ) => {
    const ENDPOINT = `/application/reference/${reference_id}/request`

    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT,
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...d, execute }
}
