import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

type Request = {
  id: string
  recipientEmail?: string[]
  type: "complete-application"
}

export function useRequestCompleteApplication(onSuccess?: () => void) {
  const ENDPOINT = "/application/base/reminder"
  const post = useTypedMutation<Request>(
    "post/application/base/reminder",
    onSuccess,
  )

  const execute = (
    app_id: string,
    recipientEmail?: string,
    onSuccess?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {
          id: app_id,
          recipientEmail: recipientEmail ? [recipientEmail] : undefined,
          type: "complete-application",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
