import { useQuery } from "react-query"
import { ApplicationsSearchResponse } from "src/types"

export function useSearchApplications(query: string) {
  return useQuery<ApplicationsSearchResponse>(
    "/application/base/search?q=" + query,
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
