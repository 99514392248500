import PropTypes from "prop-types"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
// @mui
import { styled, alpha } from "@mui/material/styles"
import { Box, Drawer, Typography, Avatar } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import DropdownMenuButton from "src/components/dropdown-menu-button"
// hooks
import useResponsive from "src/hooks/useResponsive"
// components
import Logo from "src/components/logo"
import Scrollbar from "src/components/scrollbar"
import NavSection from "src/components/nav-section"
//
import getNavConfig from "./config"
import { useUser } from "src/queries"
import { useSeller } from "src/queries/base/useSeller"
import { useUserToBusinesses } from "src/queries/base/useUserToBusinesses"
import { usePostUserToBusiness } from "src/queries/base/usePostUserToBusiness"
import { Check, CheckCircle, Domain } from "@mui/icons-material"
import Label from "src/components/label"

// ----------------------------------------------------------------------

const NAV_WIDTH = 280

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}))

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}

export default function Nav({ openNav, onCloseNav }) {
  const { auth0User: user, data } = useUser()
  const { pathname } = useLocation()

  const { data: business } = useSeller(data?.userToBusiness?.business?.id)

  const { data: userToBusinesses } = useUserToBusinesses()

  const { execute: setActiveBusiness } = usePostUserToBusiness(() => {
    window.location = "/"
  })

  const isDesktop = useResponsive("up", "lg")

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <StyledAccount>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
          >
            <Box
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Avatar src={user?.picture} alt="photoURL" />

              <Box sx={{ ml: 2 }} style={{ wordBreak: "break-word" }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {data?.firstName} {data?.lastName}
                </Typography>

                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {data?.userToBusiness?.business?.name}
                </Typography>
              </Box>
            </Box>
            {userToBusinesses && userToBusinesses.length > 1 && (
              <DropdownMenuButton
                variant="outlined"
                width="100%"
                hideArrowDown
                title={
                  <>
                    <Domain style={{ marginRight: "8px" }} />
                    Change Business
                  </>
                }
              >
                {userToBusinesses
                  ?.sort((a, b) => {
                    if (a.active) return -1
                    if (b.active) return 1
                    if (a.business.name[0] < b.business.name[0]) return -1
                  })
                  ?.map((u2b) => (
                    <MenuItem
                      key={u2b.id}
                      disableRipple
                      onClick={() => {
                        setActiveBusiness(u2b.business.id)
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "12px",
                        }}
                      >
                        {u2b.business.name}
                        {u2b.active && <Label color="success">Current</Label>}
                      </Box>
                    </MenuItem>
                  ))}
              </DropdownMenuButton>
            )}
          </Box>
        </StyledAccount>
      </Box>

      <NavSection data={getNavConfig(data, business?.services || ["credit"])} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
