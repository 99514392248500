import { Button, Stack } from "@mui/material"
import ViewCashApplicationDialog from "../ViewCashApplicationDialog"
import { useState } from "react"

export default function CashApplicationsCard({ id }: { id: string }) {
  const [openCashDialog, setOpenCashDialog] = useState(false)
  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCashDialog(true)
          }}
        >
          View Cash Applications
        </Button>
      </Stack>
      {openCashDialog && (
        <ViewCashApplicationDialog
          open={openCashDialog}
          onClose={() => {
            setOpenCashDialog(false)
          }}
          businessId={id}
        />
      )}
    </>
  )
}
