import { URLShortenerRequest } from "src/types"
import { useTypedMutation } from "../useTypedMutation"
import { QueryClient } from "react-query"

export function useCreateInternalUrlShorterner(onSuccess?: () => void) {
  const post = useTypedMutation<URLShortenerRequest>(
    "post/internal/url-shorterner/",
    onSuccess,
  )
  const execute = (body: URLShortenerRequest, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/internal/url-shorterner/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/url-shorterner".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
