import * as React from "react"
import { FormikProps } from "formik"

import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"

import { useAuth0 } from "@auth0/auth0-react"
import { useLocation, useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { CashApplication, User } from "src/types"
import { confirm } from "src/components/confirm"

import { BrowserView, MobileView } from "react-device-detect"
import { getUserEnabledSchema } from "../sections/@dashboard/intake_sections/schemas/UserRegistrationSectionSchema"

import { AxiosError } from "axios"
import { Helmet } from "react-helmet-async"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import queryString from "query-string"
import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { error, info } from "src/utils/logger"
import ApplicationFormikForm, {
  validateErrorsAgainstSchema,
} from "src/sections/@dashboard/ApplicationFormikForm"
import CashFormComponent from "src/sections/@dashboard/cod/forms/CashFormComponent"
import auth0 from "auth0-js"
import { LogoutOutlined } from "@mui/icons-material"
import { useCashApplication } from "src/queries/cod/useCashApplication"
import { usePatchBuyerCashApplication } from "src/queries/cod/usePatchBuyerCashApplication"
import { usePostBuyerCashApplication } from "src/queries/cod/usePostBuyerCashApplication"
import { useCashApplicationTemplate } from "src/queries/cod/useCashApplicationTemplate"
import { usePostGenerateCashApplication } from "src/queries/cod/usePostGenerateCashApplication"
import { usePostCompleteCashApplication } from "src/queries/cod/usePostCompleteCashApplication"
import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"

export default () => {
  const [activeStep, setActiveStep] = React.useState(0)
  // used after redirection from auth0
  // if the user already filled the first page and logged in, skip the first page
  const [autoNavigate, setAutoNavigate] = React.useState(true)
  const params = useParams()
  const { id } = params

  const navigate = useNavigate()
  const { user: auth0User, isAuthenticated, logout } = useAuth0()

  const { data: application, refetch: refetchApplication } =
    useCashApplication(id)

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const consentResult = queryParams.get("result") || ""
  const businessId = queryParams.get("business_id") || application?.seller?.id

  // any query params will be passed to formik as initial values
  const prefilledData = queryString.parse(search)
  delete prefilledData["business_id"]

  const {
    data: template,
    dataSchema,
    steps,
    applicationError,
  } = useCashApplicationTemplate(businessId || undefined, true, true, id)

  const { data: businessData } = useAnonymousBusiness(businessId || "")

  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  // used to show a blocking error if completion fails
  const [completionError, setCompletionError] = React.useState<
    AxiosError | undefined
  >(undefined)

  const [loading, setLoading] = React.useState(false)
  const [verifyCode, setVerifyCode] = React.useState<string | undefined>(
    undefined,
  )

  const formRef = React.useRef<FormikProps<CashApplication>>(null)

  const { execute: generateApplication, isLoading } =
    usePostGenerateCashApplication((data: CashApplication) => {
      if (data.data["signingUrl"]) {
        saveAnalytics("submit", "cash", data.id || "", "Awaiting Signature")
        setActiveStep((prev) => prev + 1)
      } else {
        setActiveStep(99)
      }
    })

  const startPasswordlessLogin = React.useCallback(
    (values: CashApplication) => {
      const data = values ? { ...values.data } : undefined
      if (data) {
        delete data["customFields"]
        delete data["customResponsesToBeDeleted"]
        data["email"] = data["email"]?.toLowerCase()
        setLoading(true)
        let redirectUri = ""
        if (data["id"]) {
          redirectUri =
            window.location.origin +
            "/trade-credit/redirect?" +
            queryString.stringify({
              application_id: data["id"],
              business_id: data.seller,
              type: "cod",
            })
        } else {
          redirectUri =
            window.location.origin +
            "/cod/base?" +
            queryString.stringify({
              ...data,
              business_id: data.seller,
            })
        }
        new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
          redirectUri,
          responseType: "token",
        }).passwordlessStart(
          {
            send: "code",
            email: data.email,
            connection: "email",
          },
          (err) => {
            setLoading(false)
            if (!err) {
              setVerifyCode("")
            } else {
              error(err)
            }
          },
        )
      }
    },
    [],
  )

  const { execute: saveApplicationProgress, isLoading: isPatching } =
    usePatchBuyerCashApplication(() => {
      queryClient.removeQueries()
      enqueueSnackbar("Application Saved.", {
        variant: "info",
      })
    })

  const { execute: completeApplication, isLoading: isCompleting } =
    usePostCompleteCashApplication()

  const { execute: saveAnalytics } = usePostAnalytics()

  const onIncrementStep = React.useCallback(
    (
      id: string,
      values: CashApplication,
      onError = () => {
        console.log("no-op")
      },
    ) => {
      saveApplicationProgress(
        id,
        values,
        () => {
          saveAnalytics("save", "cash", id, steps[activeStep].label, {
            seller: values.seller?.name || "",
            buyer: `${values.data.firstName} ${values.data.lastName}`,
            buyerEmail: auth0User?.email || "",
            buyerPhoneNumber: values.data.userPhoneNumber,
          })
          refetchApplication()
            // eslint-disable-next-line promise/no-nesting
            .then(() => {
              return setActiveStep((prevActiveStep) => prevActiveStep + 1)
            })
            // eslint-disable-next-line promise/no-nesting
            .catch((err) => {
              error(err)
            })
        },
        onError,
      )
    },
    [refetchApplication, saveApplicationProgress],
  )

  const { execute: createApplication } = usePostBuyerCashApplication(
    (newApplication) => {
      if (isAuthenticated && newApplication.id && formRef.current) {
        // saving is required to upload the files in memory
        saveApplicationProgress(
          newApplication.id,
          formRef.current.values,
          () => {
            navigate(
              `/cod/${newApplication.id}?` +
                queryString.stringify({
                  business_id: businessId,
                }),
            )
          },
        )
      } else if (!isAuthenticated && newApplication.id && formRef.current) {
        new auth0.WebAuth({
          domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
          redirectUri:
            window.location.origin +
            "/trade-credit/redirect?" +
            queryString.stringify({
              application_id: newApplication?.id || "",
              business_id: businessId,
              type: "cod",
            }),
          responseType: "token",
        }).passwordlessLogin(
          {
            connection: "email",
            email: newApplication.data.email || "",
            verificationCode: verifyCode || "",
          },
          () => {
            setLoading(false)
            enqueueSnackbar("Vaidation failed. Please try again.", {
              variant: "error",
            })
            // TODO: delete application
          },
        )
      }
    },
  )
  const initialValue = React.useMemo(() => {
    if (!id) {
      return {
        data: {
          seller: businessId,
          ...prefilledData,
        },
        stage: 1,
      } as CashApplication
    }

    if (application) {
      const values = { ...application, ...prefilledData }
      if (!values?.salesRep) {
        values.salesRep = { id: "-1" } as User
      }
      return values
    }

    // will never happen because if ther is an id
    // we dont render the form until application is loaded
    return {
      data: {
        seller: businessId,
      },
      stage: 1,
    } as CashApplication
  }, [id, application, businessId, prefilledData])

  const onSubmit = React.useCallback(() => {
    if (id && formRef.current) {
      const values = formRef?.current.values
      saveApplicationProgress(id, values, () => {
        values.data["id"] = values.id
        if (businessData && template) {
          saveAnalytics("save", "cash", id, steps[activeStep].label, {
            seller: values.seller?.name || "",
            buyer: `${values.data.firstName} ${values.data.lastName}`,
            buyerEmail: auth0User?.email || "",
            buyerPhoneNumber: values.data.userPhoneNumber,
          })
          refetchApplication()
            .then((res) => {
              return generateApplication(res)
            })
            .catch((err) => console.log(err))
        }
      })
    }
  }, [
    businessData,
    generateApplication,
    id,
    refetchApplication,
    saveApplicationProgress,
    template,
  ])

  const onNextStep = React.useCallback(
    (
      stepsRemaining?: boolean,
      onFailed = () => {
        console.log("no-op")
      },
    ) => {
      if (activeStep === 0) {
        if (
          isAuthenticated &&
          auth0User?.email !== formRef?.current?.values.data.email
        ) {
          confirm(
            `You are currently logged in as ${auth0User?.email}. Would you like to log out and continue with ${formRef?.current?.values.data.email}?`,
          )
            // eslint-disable-next-line promise/no-nesting
            .then(
              () => {
                logout({
                  logoutParams: {
                    returnTo:
                      window.location.origin +
                      "/ua-cod/base?" +
                      queryString.stringify({
                        ...formRef?.current?.values.data,
                        applicationId: id,
                        business_id: businessId,
                        // used to automatically
                        // move the user to 2FA verification. see related useEffect
                        verify: true,
                      }),
                  },
                })
                return
              },
              () => {
                onFailed()
                info("cancelled")
              },
            )
            // eslint-disable-next-line promise/no-nesting
            .catch((e: any) => {
              console.log("error while logout", e)
            })
        } else if (!isAuthenticated) {
          startPasswordlessLogin(formRef?.current?.values as CashApplication)
        } else {
          // user is logged in, redirect to the protected route
          if (id) {
            onIncrementStep(
              id,
              formRef?.current?.values as CashApplication,
              onFailed,
            )
          } else {
            createApplication(
              formRef?.current?.values as CashApplication,
              () => undefined,
              onFailed,
            )
          }
        }
      } else if (activeStep === steps.length - 2) {
        onSubmit()
      } else if (activeStep === steps.length - 1) {
        if (id) {
          setActiveStep(98)
          completeApplication(
            id,
            () => {
              saveAnalytics("complete", "cash", id, steps[activeStep].label)
              setActiveStep(99)
            },
            (error: AxiosError) => {
              setCompletionError(error)
            },
          )
        }
      } else {
        if (id) {
          onIncrementStep(
            id,
            formRef?.current?.values as CashApplication,
            onFailed,
          )
        }
      }
    },
    [
      activeStep,
      auth0User?.email,
      businessId,
      completeApplication,
      createApplication,
      id,
      isAuthenticated,
      logout,
      onIncrementStep,
      onSubmit,
      startPasswordlessLogin,
      steps.length,
    ],
  )

  React.useEffect(() => {
    if (
      autoNavigate &&
      template &&
      // make sure we had any saved data - if not, we don't need to do this
      (Object.keys(prefilledData).length > 0 || !!application) &&
      // make sure the template is fully loaded
      Object.keys(getUserEnabledSchema(template, formRef.current?.values.data))
        .length > 0 &&
      // for now we only do this on the first step
      activeStep === 0 &&
      formRef.current &&
      !consentResult
    ) {
      const userEnabledschema = getUserEnabledSchema(
        template,
        formRef.current?.values.data,
      )

      validateErrorsAgainstSchema(
        formRef,
        () => {
          setAutoNavigate(false)
          if (
            isAuthenticated &&
            auth0User?.email === formRef.current?.values.data.email
          ) {
            if (!id && !application && formRef.current) {
              if (queryParams.get("applicationId")) {
                navigate({
                  pathname: `/cod/${queryParams.get("applicationId")}`,
                  search: queryString.stringify({
                    ...formRef.current.values.data,
                    business_id: businessId,
                  }),
                })
              }
            } else if (id && formRef.current) {
              onIncrementStep(id, formRef?.current?.values)
            }
          } else {
            setAutoNavigate(false)
            startPasswordlessLogin(formRef?.current?.values as CashApplication)
          }
        },
        userEnabledschema,
        () => {
          setAutoNavigate(false)
          setActiveStep(0)
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData, template, autoNavigate, activeStep])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  if (!template || steps.length === 0) return <></>

  if (id && !application)
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {![401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && <Typography>Loading application...</Typography>}
        {[401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && (
          <>
            <Typography>
              The email you are logged in is not associated with this
              application. Please log out using the button below and make sure
              you log in using the email address associated that:
            </Typography>
            <ul>
              <li>you initially submitted this application with, or</li>
              <li>you received the communication at.</li>
            </ul>
            <Button
              variant="contained"
              startIcon={<LogoutOutlined />}
              style={{ marginTop: "1rem" }}
              onClick={() => logout({})}
            >
              Log out
            </Button>
          </>
        )}
      </Box>
    )

  return (
    <>
      <Helmet>
        <title>
          {businessData?.name
            ? `${businessData?.name} Application`
            : "Application"}
        </title>
      </Helmet>
      <MobileView>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              gap: "1rem",
              padding: "10%",
            }}
          >
            <Typography variant="h1">Ah! Hello there!</Typography>
            <Typography variant="h3">
              We love your enthusiasm to use our product on your phone. We are
              working hard to bring you amazing features.
            </Typography>
            <Typography variant="h3">
              However, the application is only fillable using a laptop or a
              desktop computer at the moment.
            </Typography>
          </Box>
        </Backdrop>
      </MobileView>
      <BrowserView>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || isPatching || isCompleting}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <CircularProgress color="inherit" />
            <Typography>Please wait...</Typography>
          </Box>
        </Backdrop>
        <ApplicationFormikForm
          template={template}
          steps={steps}
          dataSchema={dataSchema}
          application={application as CashApplication}
          activeStep={activeStep}
          initialValues={initialValue}
          handleNext={onNextStep}
          handleBack={handleBack}
          formRef={formRef}
          completionError={completionError}
          Component={CashFormComponent}
        />
        <Dialog
          open={verifyCode !== undefined}
          onClose={() => {
            setLoading(false)
            setVerifyCode(undefined)
          }}
        >
          <DialogTitle>Verify your email</DialogTitle>
          <DialogContent>
            To ensure the privacy and security of your information, we have sent
            a verification code to your email. Please enter it below to
            continue.
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Verification Code"
              type="number"
              value={verifyCode}
              onChange={(event) => {
                setVerifyCode(event.target.value)
              }}
              helperText="Not finding your verification email? Please check your spam folder."
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setLoading(false)
                setVerifyCode(undefined)
              }}
            >
              Close
            </Button>
            <LoadingButton
              id="verify-button"
              loading={loading}
              disabled={!verifyCode}
              onClick={() => {
                if (formRef?.current?.values) {
                  setLoading(true)
                  createApplication({
                    data: formRef?.current?.values.data,
                    seller: businessId,
                  })
                } else {
                  console.log("formRef.current.values is undefined")
                }
              }}
            >
              Verify
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </BrowserView>
    </>
  )
}
