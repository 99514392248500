import { Button, Paper, Stack, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalCreditRiskMonitorCredentials } from "src/queries/internal/useCreateInternalCreditRiskMonitorCredentials"
import { useListInternalCreditRiskMonitorCredentials } from "src/queries/internal/useListInternalCreditRiskMonitorCredentials"
import { usePatchInternalCreditRiskMonitorCredentials } from "src/queries/internal/usePatchInternalCreditRiskMonitorCredentials"
import { ClientCreditRiskMonitorCredentials } from "src/types"
import { confirm } from "src/components/confirm/"
import { useDeleteInternalCreditRiskMonitorCredentials } from "src/queries/internal/useDeleteInternalCreditRiskMonitorCredentials"

export default function ClientExternalCredentialsDetails({
  id,
}: {
  id: string
}) {
  const { data, refetch: refetchCredentials } =
    useListInternalCreditRiskMonitorCredentials(id)
  const { execute: executeCreate, isLoading: isLoadingCreateRequest } =
    useCreateInternalCreditRiskMonitorCredentials(() => {
      enqueueSnackbar("Credit Risk Monitor Credentials Added", {
        variant: "success",
      })
    })
  const { execute: executePatch, isLoading: isLoadingPatchRequest } =
    usePatchInternalCreditRiskMonitorCredentials(() => {
      enqueueSnackbar("Credit Risk Monitor Credentials Edit", {
        variant: "success",
      })
    })

  const { execute: executeDelete, isLoading: isLoadingDeleteRequest } =
    useDeleteInternalCreditRiskMonitorCredentials(() => {
      enqueueSnackbar("Credit Risk Monitor Credentials Deleted", {
        variant: "success",
      })
      refetchCredentials()
    })
  const formFields = () => {
    return (
      <>
        <Formik
          initialValues={
            data ? data : ({ seller: id } as ClientCreditRiskMonitorCredentials)
          }
          onSubmit={(values) => {
            if (data) {
              confirm(
                "Are you Sure you want to Update the credentials for this user?",
              )
                .then(
                  () => {
                    executePatch(values, () => {
                      refetchCredentials()
                    })
                    return
                  },
                  () => {
                    console.log("error")
                  },
                )
                .catch(() => {
                  console.log("error")
                })
            } else {
              executeCreate(values, () => {
                refetchCredentials()
              })
            }
          }}
        >
          {(props) => (
            <>
              <TextField
                fullWidth
                label="Username"
                id="username"
                name="username"
                margin="normal"
                type="text"
                value={props.values?.username}
                onChange={props.handleChange}
                error={Boolean(props.errors?.username)}
              />
              <TextField
                fullWidth
                label="Password"
                id="password"
                name="password"
                margin="normal"
                type="text"
                value={props.values?.password}
                onChange={props.handleChange}
                error={Boolean(props.errors?.password)}
              />
              <TextField
                fullWidth
                label="API Key"
                id="apiKey"
                name="apiKey"
                margin="normal"
                type="text"
                value={props.values?.apiKey}
                onChange={props.handleChange}
                error={Boolean(props.errors?.apiKey)}
              />
              <TextField
                fullWidth
                label="Layout"
                id="layout"
                name="layout"
                margin="normal"
                type="text"
                value={props.values?.layout}
                onChange={props.handleChange}
                error={Boolean(props.errors?.layout)}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleSubmit()
                  }}
                  disabled={isLoadingCreateRequest || isLoadingPatchRequest}
                >
                  {data ? "Update" : "Create"}
                </Button>
                {data && data.id && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      confirm(
                        "Are you Sure you want to Delete the credentials for this user?",
                      )
                        .then(
                          () => {
                            executeDelete(data)
                            return
                          },
                          () => {
                            console.log("error")
                          },
                        )
                        .catch(() => {
                          console.log("error")
                        })
                    }}
                    disabled={isLoadingDeleteRequest}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Formik>
      </>
    )
  }
  return (
    <>
      <Paper variant="outlined" sx={{ marginY: 4, p: 1 }}>
        <Typography variant="h6">Credit Risk Monitor Credentials</Typography>
        {data && formFields()}
        {!data && formFields()}
      </Paper>
    </>
  )
}
