import { useQuery } from "react-query"
import { Invoice } from "../../types"

export function useInvoice(id: string) {
  return useQuery<Invoice>(`/invoices/${id}`, {
    enabled: !!id,
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      return (error as any)?.response?.status !== 404
    },
  })
}
