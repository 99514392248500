import { useRef } from "react"
// @mui
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material"

// components

import { ApplicationTemplate, TemplateCustomField } from "src/types"
import * as yup from "yup"
import { isEqual } from "lodash"
import { usePatchBusiness, useUser } from "src/queries"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

import { useFormik, FormikProps } from "formik"
import { useSnackbar } from "notistack"
import CreditApplicationConfigCustomFields from "../credit-application-config-customfields"
import CreditApplicationConfigMainFields from "../credit-application-config-mainfields"
import CreditApplicationBrandingInfo from "../credit-application-branding-info"
import CreditApplicationConfigToolbar from "../CreditApplicationConfigToolbar"

// ----------------------------------------------------------------------

export default function CreditApplicationConfigView() {
  const { data: userData } = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { data, refetch, isLoading: isFetching } = useApplicationTemplate()

  const { execute: patchBusiness, isLoading: isPatching } = usePatchBusiness()

  const validationSchema = yup.object({
    data: yup.object().shape({
      color: yup.string().max(6),
    }),
  })

  const formik = useFormik({
    initialValues: (data as Pick<ApplicationTemplate, "color">) || {
      color: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values: Pick<ApplicationTemplate, "color">) => {
      if (userData?.userToBusiness?.business.id && !isEqual(values, data)) {
        patchBusiness(
          userData?.userToBusiness?.business.id,
          { color: values.color },
          () => {
            refetch()
            enqueueSnackbar("Color successfully updated.", {
              variant: "success",
            })
          },
        )
      }
    },
  })

  // ref for customfields formik
  const customFieldsBagRef = useRef<FormikProps<TemplateCustomField[]> | null>(
    null,
  )

  // ref for main fields formik
  const mainFieldsBagRef = useRef<FormikProps<
    ApplicationTemplate["formTemplate"]
  > | null>(null)

  if (!data) {
    return <></>
  }

  let applicationUrl = ""
  if (location.host.includes("my.netnow.io")) {
    applicationUrl =
      "https://app.netnow.io/" +
      userData?.userToBusiness?.business.name.replaceAll(" ", "").toLowerCase()
  } else {
    applicationUrl =
      location.protocol +
      "//" +
      location.host +
      "/ua-trade-credit/base?business_id=" +
      data.seller
  }

  return (
    <Container>
      <CreditApplicationConfigToolbar
        backLink={"/applications"}
        enableSave={true}
        isLoading={isFetching || isPatching}
        onTogglePreview={(enabled) => {
          // setPreview(enabled)
        }}
        inPreviewMode={false}
        onSave={() => {
          formik.submitForm()
          mainFieldsBagRef.current?.submitForm()
          customFieldsBagRef.current?.submitForm()
        }}
      />

      {/* <Card>
        <CardContent>
        <Tabs
          sx={{
            px: 2.5,
            boxShadow: (theme) =>
              `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
          value={1}
        >
          <Tab label="Organization" value={1} />
          <Tab label="Custom Fields" value={2} />
        </Tabs>
        
      </Card> */}
      <Grid container spacing={3}>
        {data.logo && data.terms && (
          <Grid item xs={12} sm={6}>
            <Card>
              <CardHeader title="Organization Application Link" />

              <CardContent>
                <Typography>
                  This is the unique credit application form for your
                  organization. You can share this link with your prospective
                  clients. Once they submit an application, you will receive an
                  email and the application will appear in your dashboard.
                </Typography>
                <TextField
                  value={applicationUrl}
                  style={{
                    margin: "1rem 0 1rem 0",
                  }}
                  fullWidth
                  multiline
                  label="Click to copy"
                  onClick={() => {
                    enqueueSnackbar("Link copied to clipboard.", {
                      variant: "info",
                    })
                    navigator.clipboard.writeText(applicationUrl)
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sm={6} style={{ height: "100%" }}>
          <CreditApplicationBrandingInfo
            template={formik.values}
            onColorChanged={(field, value) => {
              if (
                // i wrote this on a plane with no internet. cut me some slack.
                value.length < 7 &&
                Array.from(new Set(value)).every(
                  (char) => char.toLowerCase() < "g",
                )
              ) {
                formik.setFieldValue(field, value)
              }
            }}
            onFileChanged={() => {
              refetch()
            }}
          />
        </Grid>
      </Grid>
      {/* <CreditApplicationConfigMainFields bagRef={mainFieldsBagRef} /> */}
      {userData &&
        userData.userToBusiness &&
        userData.userToBusiness?.business &&
        userData.userToBusiness?.business.id && (
          <CreditApplicationConfigCustomFields
            bagRef={customFieldsBagRef}
            businessId={userData.userToBusiness?.business.id}
          />
        )}
    </Container>
  )
}
