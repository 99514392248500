import { useState } from "react"
// @mui
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"
// routes
// components
import Iconify from "../../../components/iconify"
import {
  Check,
  Close,
  EditNoteOutlined,
  EditOutlined,
  PictureAsPdf,
  SupportAgentOutlined,
  Send,
  UploadFileOutlined,
  BusinessOutlined,
  PendingActions,
} from "@mui/icons-material"
import { confirm } from "src/components/confirm"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { useSnackbar } from "notistack"
import { getStatusString } from "../utils"
import Label from "src/components/label"
import { fDateTime } from "src/utils/formatTime"
import { formatCurrency } from "src/utils/formatNumber"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useUsers } from "src/queries/base/useUsers"
import { useAssignApplication } from "src/queries/credit/useAssignApplication"
import { stringAvatar } from "src/utils/avatar"
import ApprovalDialog from "./components/ApprovalDialog"
import DropdownMenuButton from "src/components/dropdown-menu-button"
import RequestInformationDialog from "./components/RequestInformationDialog"
import { error } from "src/utils/logger"
import { CreditApplication, Group } from "src/types"
import { Icon } from "@iconify/react"
import RequestApprovalDialog from "./components/RequestApprovalDialog"
import SendCompletionReminderDialog from "./components/SendCompletionReminderDialog"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { convertNetTerms } from "src/statics"
import RequestCustomDocumentDialog from "./components/RequestCustomDocumentDialog"
import InvisibleBankReferenceChart from "./components/InvisibleBankReferenceChart"
import { useNavigate } from "react-router-dom"
import useDownloadApplicationPackage from "src/hooks/useDownloadApplicationPackage"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import StoreAssignmentDialog from "src/components/assignment/StoreAssignmentDialog"
import { useUser } from "src/queries"
import { useSeller } from "src/queries/base/useSeller"
import { useListAdditionalDataRequest } from "src/queries/credit/additional/useListAdditionalDataRequests"
import { useSalesReps } from "src/queries/base/useSalesReps"

export default function CreditApplicationDetailsToolbar(
  {
    application,
    refreshCommentTrigger,
    setRefreshCommentTrigger,
    refetchTimeline,
  }: {
    application: CreditApplication
    backLink: string
    refreshCommentTrigger: { current: number }
    setRefreshCommentTrigger: (value: number) => void
    refetchTimeline: () => void
  }, // onChangeStatus,
) {
  const [showReminderDialog, setShowReminderDialog] = useState(false)

  const { execute: assignApplication } = useAssignApplication()

  const { data: template } = useApplicationTemplate(
    (application && application.seller && application.seller.id) || undefined,
  )

  const { refetch: refetchRequests } = useRequestedCustomDocument(
    undefined,
    application.id,
  )

  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const { data: users } = useUsers()

  const { data: salesReps } = useSalesReps(
    application?.seller?.id || "",
    application?.store,
  )

  const { data: userData } = useUser()

  const { data: business } = useSeller(
    userData?.userToBusiness?.business?.id || "",
  )

  const { refetch } = useCreditApplication(application.id)

  const { execute: patchApplication } = usePatchApplication(() => {
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const [showRequestApprovalDialog, setShowRequestApprovalDialog] =
    useState(false)
  const [showApprovalDialog, setShowApprovalDialog] = useState(false)
  const [showRequestInfoDialog, setShowRequestInfoDialog] = useState(false)
  const [showCustomDocuments, setShowCustomDocuments] = useState(false)

  const [showAssignmentDialog, setShowAssignmentDialog] = useState<
    string | undefined
  >(undefined)

  const {
    downloadAllSignedPDFs,
    downloadSingleSignedPDF,
    downloadAllUnsignedPDFs,
    downloadSingleUnsignedPDF,
    isFetchingPdf,
    singleUnsignedPDFLoading,
    allUnsignedPDFLoading,
    allSignedPDFLoading,
    bankReferenceChartData,
  } = useDownloadApplicationPackage(application)
  const { pendingRequests, refetch: refetchDataRequests } =
    useListAdditionalDataRequest(application.id || "")

  const [showPendingRequests, setShowPendingRequests] = useState(false)

  const getStoreButton = !application.store ? (
    <Button
      variant="outlined"
      startIcon={<BusinessOutlined />}
      onClick={() => setShowAssignmentDialog("STORE")}
    >
      Store
    </Button>
  ) : (
    <Button
      variant="outlined"
      endIcon={<EditOutlined />}
      onClick={() => setShowAssignmentDialog("STORE")}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <BusinessOutlined />
        {
          business?.stores?.find((store) => store.id === application.store)
            ?.name
        }
      </Box>
    </Button>
  )

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {application.data.legalBusinessName ||
                  `${application.data.firstName} ${application.data.lastName}`}
              </Typography>
              {application.data.customerId && (
                <Label variant="soft" color="default">
                  Customer Number {application.data.customerId}
                </Label>
              )}
              <Label
                variant="soft"
                color={
                  (application.stage === 800 && "success") ||
                  (application.stage < 800 && "warning") ||
                  (application.stage === 900 && "error") ||
                  "default"
                }
              >
                {getStatusString(application.stage)}
              </Label>
              {pendingRequests && pendingRequests.length > 0 && (
                <Box
                  onClick={() => {
                    setShowRequestInfoDialog(true)
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Label
                    variant="soft"
                    color="warning"
                    sx={{ cursor: "pointer" }}
                  >
                    <PendingActions /> Requests Pending
                  </Label>
                </Box>
              )}
            </Stack>
            {application.approvedAt && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Latest Approval
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.approvedAt as string)}
                </Label>
                {application.data.creditLimit && (
                  <Label variant="soft" color="default">
                    {formatCurrency(
                      application.data.creditLimit,
                      application.data.currency,
                    )}
                  </Label>
                )}
                {application.data.netTerms && (
                  <Label variant="soft" color="default">
                    {convertNetTerms(
                      application.data.netTerms,
                      template?.approvalTerms,
                    )}
                  </Label>
                )}
              </Stack>
            )}
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Submitted
              </Typography>
              <Label variant="soft" color="default">
                {fDateTime(application.createdAt)}
              </Label>
            </Stack>
            {application.lastViewed && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Last Accessed
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.lastViewed)}
                </Label>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          flexGrow={1}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "right",
          }}
        >
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {application.stage == 800 && (
              <Button
                color="primary"
                variant="outlined"
                // endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                onClick={() => {
                  setShowApprovalDialog(true)
                }}
                sx={{ textTransform: "capitalize" }}
              >
                Edit Approval
              </Button>
            )}
            {application.stage < 800 && (
              <>
                <DropdownMenuButton title="Approve/Reject">
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowApprovalDialog(true)
                    }}
                  >
                    <Check />
                    Approve
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      confirm("You are about to reject this application.", {
                        okColor: "error",
                        okLabel: "Reject",
                      })
                        .then(() => {
                          return patchApplication(
                            application.id as string,
                            {
                              stage: 900,
                            },
                            () => undefined,
                            () => undefined,
                            { notifyBuyer: "1" },
                          )
                        })
                        .catch((err) => {
                          error("error in confirm dialog", err)
                        })
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <Close style={{ fill: "red" }} />
                    Reject
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowRequestApprovalDialog(true)
                    }}
                  >
                    <Send />
                    Request Approval
                  </MenuItem>
                </DropdownMenuButton>
              </>
            )}
            <DropdownMenuButton title="Request from Applicant">
              <MenuItem
                disableRipple
                onClick={() => setShowRequestInfoDialog(true)}
              >
                <EditNoteOutlined />
                Additional Information
              </MenuItem>

              <MenuItem
                disableRipple
                onClick={() => setShowCustomDocuments(true)}
              >
                <UploadFileOutlined />
                Custom Document
              </MenuItem>

              {application.stage < 200 ? (
                <MenuItem
                  disableRipple
                  onClick={() => {
                    setShowReminderDialog(true)
                  }}
                >
                  <Icon
                    icon="mdi:email-outline"
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                  />
                  Send Reminder
                </MenuItem>
              ) : (
                <></>
              )}
            </DropdownMenuButton>
          </Stack>
          {showReminderDialog && (
            <SendCompletionReminderDialog
              application={application}
              onClose={() => {
                setShowReminderDialog(false)
              }}
            />
          )}
          <Stack
            flexGrow={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!application.assignee ? (
              <Button
                variant="outlined"
                startIcon={<Iconify icon="fluent:person-money-24-regular" />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                Assign Credit Manager
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    {...stringAvatar(
                      `${
                        application.assignee.firstName
                          ? application.assignee.firstName
                          : application.assignee.email
                      }`,
                      {
                        width: 24,
                        height: 24,
                      },
                    )}
                  />
                  {application.assignee.firstName
                    ? `${application.assignee.firstName}${
                        application.assignee.lastName
                          ? " " + application.assignee.lastName[0]
                          : ""
                      }`
                    : application.assignee.email}
                </Box>
              </Button>
            )}
            {!application.salesRep ? (
              <Button
                variant="outlined"
                startIcon={<SupportAgentOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                Assign Sales Rep
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <SupportAgentOutlined />
                  {application.salesRep.firstName
                    ? `${application.salesRep.firstName}${
                        application.salesRep.lastName
                          ? " " + application.salesRep.lastName[0]
                          : ""
                      }`
                    : application.salesRep.email}
                </Box>
              </Button>
            )}
            {business?.stores && business?.stores.length > 1 && getStoreButton}

            {application &&
              application.seller &&
              !application.seller.name.includes("Quecan") &&
              !(application.stage < 200 || !application.data.zohoReqId) && (
                <>
                  <DropdownMenuButton
                    variant="outlined"
                    title="Download Signed PDF"
                  >
                    <MenuItem
                      disabled={allSignedPDFLoading}
                      onClick={() => {
                        downloadAllSignedPDFs()
                      }}
                    >
                      {allSignedPDFLoading ? (
                        <CircularProgress
                          color="secondary"
                          size={30}
                          sx={{ p: 1 }}
                        />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Entire Application Package
                    </MenuItem>
                    <MenuItem
                      disabled={isFetchingPdf}
                      onClick={() => {
                        downloadSingleSignedPDF()
                      }}
                    >
                      {isFetchingPdf && !allSignedPDFLoading ? (
                        <CircularProgress
                          color="secondary"
                          size={30}
                          sx={{ p: 1 }}
                        />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Signed PDF
                    </MenuItem>
                  </DropdownMenuButton>
                </>
              )}

            {application &&
              application.seller &&
              !application.seller.name.includes("Quecan") &&
              (application.stage < 200 || !application.data.zohoReqId) && (
                <>
                  <DropdownMenuButton
                    variant="outlined"
                    title="Download as PDF"
                  >
                    <MenuItem
                      disabled={allUnsignedPDFLoading}
                      onClick={() => {
                        downloadAllUnsignedPDFs()
                      }}
                    >
                      {allUnsignedPDFLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Entire Application Package
                    </MenuItem>
                    <MenuItem
                      disabled={singleUnsignedPDFLoading}
                      onClick={() => {
                        downloadSingleUnsignedPDF()
                      }}
                    >
                      {singleUnsignedPDFLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Unsigned Credit Agreement
                    </MenuItem>
                  </DropdownMenuButton>
                </>
              )}
          </Stack>
        </Box>
      </Stack>
      {bankReferenceChartData &&
        Object.keys(bankReferenceChartData.balances).length > 0 && (
          <InvisibleBankReferenceChart
            bankReferenceChart={bankReferenceChartData}
          />
        )}
      {showRequestInfoDialog && (
        <RequestInformationDialog
          application={application}
          open
          onClose={() => {
            setShowRequestInfoDialog(false)
            refetchTimeline()
          }}
        />
      )}
      {showCustomDocuments && (
        <RequestCustomDocumentDialog
          application={application}
          open
          onClose={() => {
            setShowCustomDocuments(false)
            refetchTimeline()
            refetchRequests()
          }}
        />
      )}
      {showApprovalDialog && (
        <ApprovalDialog
          application={application}
          open
          onClose={() => {
            refetch()
            refetchTimeline()
            setRefreshCommentTrigger(refreshCommentTrigger.current + 1)
            setShowApprovalDialog(false)
          }}
        />
      )}
      {showRequestApprovalDialog && (
        <RequestApprovalDialog
          application={application}
          open
          onClose={() => {
            setRefreshCommentTrigger(refreshCommentTrigger.current + 1)
            setShowRequestApprovalDialog(false)
          }}
          refetch={refetch}
        />
      )}
      {business?.stores && showAssignmentDialog === "STORE" && (
        <StoreAssignmentDialog
          open
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(store) => {
            setShowAssignmentDialog(undefined)
            patchApplication(
              application.id as string,
              { store: store.id },
              () => {},
            )
          }}
          stores={business?.stores?.sort((a, b) => {
            if (!a.name) {
              return 1
            }
            if (!b.name) {
              return -1
            }
            return a.name < b.name ? -1 : 1
          })}
        />
      )}
      {users && showAssignmentDialog === "CREDIT_MANAGER" && (
        <AssignmentSelectionDialog
          open
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id as string, user.id, () => {
              refetch()
            })
          }}
          contacts={users
            .filter((user) => user?.groups?.includes(Group.CreditManager))
            ?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            })}
        />
      )}
      {users && showAssignmentDialog === "SALES" && (
        <AssignmentSelectionDialog
          open
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id as string, user.id, () => {
              refetch()
            })
          }}
          contacts={
            salesReps?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            }) || []
          }
        />
      )}
    </>
  )
}
