import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { UserToBusinessRequest } from "src/types"

export function useDeleteInternalUserToBusiness(onSuccess?: () => void) {
  const ENDPOINT = "/internal/user-to-business/delete"
  const d = useTypedMutation<UserToBusinessRequest>(
    "/internal/user-to-business/delete",
    onSuccess,
  )

  const execute = (body: UserToBusinessRequest) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT,
        body: body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
