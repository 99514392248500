import { useState } from "react"
import {
  Box,
  TextField,
  Button,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material"
import { confirm } from "src/components/confirm"
import Address from "src/sections/@dashboard/components/Address"
import { CreditApplication, CreditReport } from "src/types"
import { useTransUnionSearch } from "src/queries/vendors/useTransUnionSearch"
import { LoadingButton } from "@mui/lab"

interface SearchFormProps {
  applicationId: string
  onClose: () => void
  data: CreditApplication["data"]
  onResult: (result: CreditReport) => void
}

export const TransUnionSearchDialog = ({
  applicationId,
  onClose,
  data,
  onResult,
}: SearchFormProps) => {
  const { execute, isLoading: isSearching } = useTransUnionSearch(
    (data: CreditReport) => {
      onResult(data)
    },
  )

  const [firstName, setFirstName] = useState(
    data?.ownerName1 && data?.ownerName1.split(" ").length >= 1
      ? data?.ownerName1.split(" ")[0]
      : "",
  )
  const [lastName, setLastName] = useState(
    data?.ownerName1 && data?.ownerName1.split(" ").length > 1
      ? data?.ownerName1.split(" ")[1]
      : "",
  )
  const [ssn, setSsn] = useState(data?.ownerSsn1 || "")
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(
    data?.ownerDob1 ? new Date(data.ownerDob1) : null,
  )

  const [addressData, setAddressData] = useState(
    Object.fromEntries(
      [
        "ownerAddress1",
        "ownerAddressLine21",
        "ownerCity1",
        "ownerRegion1",
        "ownerZipCode1",
        "ownerCountry1",
      ]
        .filter((key) => key in data) // line can be removed to make it inclusive
        .map((key) => [key, data[key]]),
    ),
  )
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    ssn: false,
    dateOfBirth: false,
  })

  const validateForm = () => {
    const newErrors = {
      firstName: !firstName.trim(),
      lastName: !lastName.trim(),
      ssn: !/^\d{9}$/.test(ssn.replace(/-/g, "")),
      dateOfBirth: !dateOfBirth,
    }
    setErrors(newErrors)
    return !Object.values(newErrors).some(Boolean)
  }

  const handleSubmit = () => {
    if (validateForm() && dateOfBirth) {
      execute({
        applicationId,
        firstName,
        lastName,
        ssn,
        dob: dateOfBirth.toISOString().split("T")[0],
        streetNumber: addressData.ownerAddress1.split(" ")[0],
        streetName: addressData.ownerAddress1.split(" ").slice(1).join(" "),
        city: addressData.ownerCity1,
        state: addressData.ownerRegion1,
        zipCode: addressData.ownerZipCode1,
      })
    }
  }

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSsn(e.target.value)
  }

  return (
    <Dialog open maxWidth="md">
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom align="center">
            Search for a TransUnion Credit Report
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              error={errors.firstName}
              helperText={errors.firstName && "First name is required"}
              fullWidth
              inputProps={{
                style: {
                  height: "56px",
                },
              }}
              required
            />

            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              error={errors.lastName}
              helperText={errors.lastName && "Last name is required"}
              fullWidth
              inputProps={{
                style: {
                  height: "56px",
                },
              }}
              required
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <TextField
              label="Social Security Number"
              value={ssn}
              onChange={handleSSNChange}
              error={errors.ssn}
              helperText={
                errors.ssn && "Please enter a valid SSN (XXX-XX-XXXX)"
              }
              fullWidth
              inputProps={{
                maxLength: 11,
                style: {
                  height: "56px",
                },
              }}
              required
            />

            <TextField
              label="Date of Birth"
              type="date"
              value={dateOfBirth ? dateOfBirth.toISOString().split("T")[0] : ""}
              onChange={(e) => setDateOfBirth(new Date(e.target.value))}
              error={errors.dateOfBirth}
              helperText={errors.dateOfBirth ? "Date of birth is required" : ""}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  height: "56px",
                },
              }}
              required
            />
          </Box>

          <Address
            keyPrefix={"owner"}
            keySuffix="1"
            data={addressData}
            errors={undefined}
            onChange={function (key: string, value: string | undefined): void {
              setAddressData((prev) => ({ ...prev, [key]: value }))
            }}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSearching}
          onClick={() => {
            if (addressData.ownerCountry1 !== "United States") {
              return (
                confirm(
                  "TransUnion credit reports are only available for US residents.",
                )
                  .then(
                    () => {
                      return
                    },
                    () => {},
                  )
                  // eslint-disable-next-line promise/no-nesting
                  .catch((e: any) => {
                    console.log("error", e)
                  })
              )
            }
            confirm(
              "You are about to perform a hard credit inquiry. This will affect the applicant's credit score. Please double check the data before proceeding.",
              {
                okLabel: "Proceed",
              },
            )
              .then(
                () => {
                  return handleSubmit()
                },
                () => {},
              )
              // eslint-disable-next-line promise/no-nesting
              .catch((e: any) => {
                console.log("error", e)
              })
          }}
        >
          Search
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
