import { useQuery } from "react-query"
import queryString from "query-string"
import { TradeReferenceTemplate } from "src/types"

export function useTradeReferenceTemplate(business_id?: string) {
  return useQuery<TradeReferenceTemplate>(
    queryString.stringifyUrl({
      url: "/configure/trade_reference_template/" + business_id,
    }),
    {
      enabled: !!business_id,
      meta: { anonymous: true },
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
