import * as React from "react"
import { FormikProps } from "formik"

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material"
import { useLocation, useParams } from "react-router-dom"
import { error } from "src/utils/logger"
import { CreditApplication } from "src/types"

import { Helmet } from "react-helmet-async"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { usePatchBuyerApplication } from "src/queries/credit/usePatchBuyerApplication"
import { useSnackbar } from "notistack"
import { useQueryClient } from "react-query"
import { useAdditionalDataRequest } from "src/queries/credit/additional/useAdditionalDataRequest"
import { usePatchAdditionalDataRequest } from "src/queries/credit/additional/usePatchAdditionalDataRequest"
import ApplicationFormikForm from "src/sections/@dashboard/ApplicationFormikForm"
import AdditionalDataFormComponent from "src/sections/@dashboard/credit/forms/AdditionalDataFormComponent"

import { AxiosError } from "axios"
import { LogoutOutlined } from "@mui/icons-material"
import { useAuth0 } from "@auth0/auth0-react"
import PDFBaseApplicationOutput from "src/components/pdf/output-template/PDFBaseApplicationOutput"
import { pdf } from "@react-pdf/renderer"
import { values } from "lodash"
import { getPdfTemplate } from "src/components/pdf/output-template/template"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"

export default () => {
  const params = useParams()

  const { applicationId, requestId } = params

  const { logout } = useAuth0()

  const {
    data: application,
    refetch: refetchApplication,
    error: applicationError,
  } = useCreditApplication(applicationId)

  const { data: businessData } = useAnonymousBusiness(
    application?.seller?.id || "",
  )

  const {
    data: template,
    dataRequest,
    dataSchema,
    steps,
    isLoading,
  } = useAdditionalDataRequest(requestId, application?.seller?.id)

  const [activeStep, setActiveStep] = React.useState(0)

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const fulfilled = queryParams.get("fulfilled") || ""

  const { execute: updateRequest, isLoading: isSubmitting } =
    usePatchAdditionalDataRequest(requestId, (app) => {
      if (dataRequest?.requestSignature && app.data["signingUrl"]) {
        window.location.href = app.data["signingUrl"]
      } else {
        setActiveStep(99)
      }
    })

  React.useEffect(() => {
    // block the user if request is already fulfilled
    if (dataRequest?.fulfilled) {
      setActiveStep(99)
    }
  }, [dataRequest, setActiveStep])

  React.useEffect(() => {
    // mark the request as fulfilled if the query param is set
    // this will be true after redirecting back from zoho sign
    if (fulfilled === "true") updateRequest({ fulfilled: true }, false)
    // run only once on mount
  }, [])

  const { enqueueSnackbar } = useSnackbar()

  const queryClient = useQueryClient()

  const { execute: saveApplicationProgress } = usePatchBuyerApplication(() => {
    queryClient.removeQueries()
    enqueueSnackbar("Application Saved.", {
      variant: "info",
    })
  })

  const formRef = React.useRef<FormikProps<CreditApplication>>(null)

  const handleNext = React.useCallback(() => {
    if (formRef.current) {
      if (applicationId) {
        saveApplicationProgress(applicationId, formRef.current.values, () => {
          refetchApplication()
            // eslint-disable-next-line promise/no-nesting
            .then((res) => {
              if (activeStep === steps.length - 1) {
                // the backend will check if signature is required
                // if not, it will mark the request as fulfilled
                // otherwise, it will return the signing url
                // that's why we don't update the fulfilled status here
                return pdf(
                  <PDFBaseApplicationOutput
                    data={res.data as CreditApplication}
                    businessData={businessData || {}}
                    customQuestionsTemplate={template?.customFields}
                    pdfTemplate={getPdfTemplate(template)}
                  />,
                ).toBlob()
              } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
                return null
              }
            })
            .then((res) => {
              if (!res) {
                return
              }
              return updateRequest(
                {},
                true,
                new File([res], "application_pdf.pdf"),
              )
            })
            .catch((err) => console.log(err))
        })
      }
    }
  }, [
    applicationId,
    saveApplicationProgress,
    refetchApplication,
    activeStep,
    steps.length,
    businessData,
    template,
    updateRequest,
  ])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  if (!template || steps.length === 0) return <></>

  if (applicationId && !application)
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {![401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && <Typography>Loading application...</Typography>}
        {[401, 403].includes(
          (applicationError as AxiosError)?.response?.status || 0,
        ) && (
          <>
            <Typography>
              The email you are logged in is not associated with this
              application. Please log out using the button below and make sure
              you log in using the email address associated that:
            </Typography>
            <ul>
              <li>you initially submitted this application with, or</li>
              <li>you received the communication at.</li>
            </ul>
            <Button
              variant="contained"
              startIcon={<LogoutOutlined />}
              style={{ marginTop: "1rem" }}
              onClick={() => logout({})}
            >
              Log out
            </Button>
          </>
        )}
      </Box>
    )

  return (
    <>
      <Helmet>
        <title>
          {application?.seller?.name
            ? `${application?.seller?.name} Credit Application`
            : "Credit Application"}
        </title>
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting || isLoading}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <CircularProgress color="inherit" />
          {isSubmitting ? (
            <>
              <Typography>Submitting Application...</Typography>
              <Typography>
                Please note that you might be required to digitally sign the
                Credit Agreement document to finalize your credit application
              </Typography>
            </>
          ) : (
            isLoading && (
              <>
                <Typography>Loading...</Typography>
              </>
            )
          )}
        </Box>
      </Backdrop>
      <ApplicationFormikForm
        template={template}
        application={application as CreditApplication}
        activeStep={activeStep}
        initialValues={application as CreditApplication}
        handleNext={handleNext}
        handleBack={handleBack}
        formRef={formRef}
        completionError={undefined}
        Component={AdditionalDataFormComponent}
        dataSchema={dataSchema}
        steps={steps}
      />
    </>
  )
}
