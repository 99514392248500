import queryString from "query-string"
import { useQuery } from "react-query"

type Response = {
  email: string
  verdict: string
  score: number
  local: string
  host: string
  checks: {
    domain: {
      hasValidAddressSyntax: boolean
      hasMxOrARecord: boolean
      isSuspectedDisposableAddress: boolean
    }
    localPart: {
      isSuspectedRoleAddress: boolean
    }
    additional: {
      hasKnownBounces: boolean
      hasSuspectedBounces: boolean
    }
  }
  source: string
  bounceVerify: {
    result: string
  }
}
export function useValidateEmail(email: string, source = "UNKNOWN") {
  return useQuery<Response>(
    "/email/validate?" +
      queryString.stringify({
        email,
        source,
      }),
    {
      enabled: !!email,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
