import { useQuery } from "react-query"

export function useDocumentPDF(req_id: string, category = "application") {
  return useQuery<string>(
    `/document/pdf?req_id=${req_id}&category=${category}`,
    {
      enabled: false,
      retry: (failureCount, error) => {
        if (failureCount > 1) {
          return false
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
