import {
  Button,
  Container,
  Dialog,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePostInternalBusiness } from "src/queries/internal/usePostInternalBusiness"
import { Business } from "src/types"
import * as yup from "yup"

export default function AddBusinessDialog({
  open,
  onClose,
  refetch,
}: {
  open: boolean
  onClose: () => void
  refetch: () => void
}) {
  const { isLoading, execute } = usePostInternalBusiness()
  const schema = yup.object({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    logo: yup.string().required("Required"),
  })
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <Container sx={{ p: 2 }}>
          <Stack spacing={1}>
            <Typography variant="h6">Add Business</Typography>
          </Stack>
          <Formik
            initialValues={{} as Business}
            onSubmit={(values: Business) => {
              values.preferences = { "": "" }
              values.meta = { "": "" }
              execute(values, () => {
                onClose()
                refetch()
                enqueueSnackbar("Business Added", { variant: "success" })
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <Grid direction={"row"} container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    name="name"
                    margin="normal"
                    fullWidth
                    label="Name"
                    type="text"
                    value={props.values.name}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.name)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    margin="normal"
                    fullWidth
                    label="Email"
                    type="text"
                    value={props.values.email}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.email)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="street"
                    name="street"
                    margin="normal"
                    fullWidth
                    label="street"
                    type="text"
                    value={props.values.street}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.state)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="city"
                    name="city"
                    margin="normal"
                    fullWidth
                    label="City"
                    type="text"
                    value={props.values.city}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.city)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="postalCode"
                    margin="normal"
                    name="postalCode"
                    fullWidth
                    label="Postal Code"
                    type="text"
                    value={props.values.postalCode}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.postalCode)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="state"
                    margin="normal"
                    fullWidth
                    label="State"
                    type="text"
                    value={props.values.state}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.state)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      label="Country"
                      name="country"
                      value={props.values.country}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.country)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>

                      {["United States", "Canada", "US", "CA"].map(
                        (value, index) => (
                          <MenuItem value={value} key={index}>
                            {value}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                      labelId="currency-label"
                      id="currency"
                      name="currency"
                      label="currency"
                      value={props.values.currency}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.currency)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>

                      {["USD", "CAD"].map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="shortenedUrl"
                    margin="normal"
                    fullWidth
                    label="Shortened Url"
                    type="text"
                    value={props.values.shortenedUrl}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.shortenedUrl)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <InputLabel id="language-label">Language</InputLabel>
                    <Select
                      labelId="language-label"
                      id="language"
                      name="language"
                      label="Language"
                      value={props.values.language}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.language)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>

                      {["es", "en", "fr"].map((value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="color"
                    name="color"
                    label="Color (in HEX)"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      ),
                    }}
                    value={props.values.color}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type="file"
                    id="logo"
                    name="logo"
                    placeholder="Company Logo"
                    onChange={(event) => {
                      if (event.nativeEvent?.target) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const target = event.nativeEvent.target as any
                        props.setFieldValue("logo", target.files[0])
                      }
                    }}
                    error={Boolean(props.errors?.logo)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Container>
      </Dialog>
    </>
  )
}
