import { Helmet } from "react-helmet-async"
import { useCallback, useState } from "react"
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material"
import { alpha } from "@mui/material/styles"

import sumBy from "lodash/sumBy"

// components
import Label from "../components/label"
import Iconify from "../components/iconify"
// sections
import { InvoiceListHead } from "../sections/@dashboard/invoice"
import InvoiceViewModal from "../sections/@dashboard/invoice/InvoiceViewModal"
import { useUser } from "../queries"
// import { useLocation } from "react-router-dom"
import { formatCurrency } from "../utils/formatNumber"
import {
  MailOutlined,
  PaymentOutlined,
  ReceiptLongOutlined,
  SyncOutlined,
} from "@mui/icons-material"
import { formatDistanceToNow, isFuture, isToday } from "date-fns"
import { InvoiceAnalytic } from "./invoice-analytic"
import { useQuery } from "react-query"
import { fDate } from "src/utils/formatTime"

// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "buyer", label: "Buyer", alignRight: false },
  { id: "number", label: "Number", alignRight: false },
  { id: "date", label: "Invoice Date", alignRight: false },
  { id: "due_date", label: "Due Date", alignRight: false },
  // { id: "terms", label: "Terms", alignRight: false },
  { id: "total", label: "Total", alignRight: false },

  { id: "status", label: "Status", alignRight: false, filterForBuyer: false },
  {
    id: "communications",
    label: "Communications",
    alignRight: false,
    filterForBuyer: false,
  },

  { id: "" },
]

export default function InvoiceListPage() {
  const [open, setOpen] = useState(null)

  // const [page, setPage] = useState(0)

  // const [rowsPerPage, setRowsPerPage] = useState(10)

  // const { search } = useLocation()
  // const params = new URLSearchParams(search)
  // const [addInvoiceModalOpen, setAddInvoiceModalOpen] = useState(
  //   params.get("add") || false,
  // )

  const [tab, setTab] = useState(0)

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      // handleFilters("status", newValue)
      setTab(newValue)
    },
    [],
  )

  const [invoiceViewId, setInvoiceViewId] = useState(null)

  const { data } = useQuery<any>("integration/invoices")
  const theme = useTheme()

  const { isBuyer, isCreditManager } = useUser()

  if (!data || !data.unified) {
    return <>Loading...</>
  }

  const invoices = data.unified

  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  // const handleChangePage = (event: any, newPage: number) => {
  //   setPage(newPage)
  // }

  // const handleChangeRowsPerPage = (event: any) => {
  //   setPage(0)
  //   setRowsPerPage(parseInt(event.target.value, 10))
  // }

  const getStatus = (row: any) => {
    if (row.status === "draft") {
      return <Label variant="outlined">Draft</Label>
    }

    if (row.status === "paid") {
      return <Label color={"success"}>Paid</Label>
    }

    if (isToday(new Date(row.dueDate))) {
      return <Label color={"warning"}>Due Today</Label>
    }

    return (
      <Label color={isFuture(new Date(row.dueDate)) ? "warning" : "error"}>
        Due{" "}
        {formatDistanceToNow(new Date(row.dueDate), {
          addSuffix: true,
        })}
      </Label>
    )
  }

  const getComms = (row: any) => {
    if (
      row.status === "paid" ||
      row.status === "draft" ||
      new Date(row.dueDate) > new Date()
    ) {
      return <>--</>
    }
    const items = [
      "First Reminder",
      "Second Reminder",
      "Third Reminder",
      "Fourth Reminder",
      "Fifth Reminder",
    ]
    const item = items[Math.floor(Math.random() * items.length)]
    return <Label color={"warning"}>{item}</Label>
  }

  const getInvoiceLength = (status: string) => {
    if (status === "overdue") {
      return invoices.filter(
        (i: any) =>
          i.status === "authorised" && new Date(i.dueDate) < new Date(),
      ).length
    }
    return invoices.filter((item: any) => item.status === status).length
  }

  const getTotalAmount = (status: string) => {
    if (status === "overdue") {
      return sumBy(
        invoices.filter(
          (i: any) =>
            i.status === "authorised" && new Date(i.dueDate) < new Date(),
        ),
        "total",
      )
    }
    return sumBy(
      invoices.filter((item: any) => item.status === status),
      "total",
    )
  }

  const getPercentByStatus = (status: string) => {
    return (getInvoiceLength(status) / invoices.length) * 100
  }

  const FILTERED_HEADS = isBuyer
    ? TABLE_HEAD.filter((h) => !h.filterForBuyer)
    : TABLE_HEAD

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Invoices
          </Typography>
          {isCreditManager && (
            <Button
              variant="contained"
              startIcon={<SyncOutlined />}
              onClick={() => {
                // setAddInvoiceModalOpen(true)
              }}
            >
              Sync
            </Button>
          )}
        </Stack>

        {/* <Card sx={{ mb: { xs: 3, md: 5 } }}>
          <Scrollbar sx={{ minHeight: 108 }}>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 2 }}
            >
             
            </Stack>
          </Scrollbar>
        </Card> */}

        <Card>
          {/* <Stack
            spacing={2}
            alignItems={{ xs: "flex-end", md: "center" }}
            direction={{
              xs: "column",
              md: "row",
            }}
            sx={{
              p: 2.5,
              pr: { xs: 2.5, md: 1 },
            }}
          >
            {isCreditManager && (
              <>
                <FilterAltOutlined />

                <ApplicationListToolbar
                  title="Buyer"
                  field="assignee"
                  values={[]}
                  onFilters={() => undefined}
                  options={invoices?.map(
                    (i: Invoice) => i.businessRelationship?.buyer.name,
                  )}
                />
              </>
            )}
          </Stack> */}

          <Tabs
            sx={{
              px: 2.5,
              boxShadow: (theme) =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
            variant="fullWidth"
            value={tab}
            onChange={handleFilterStatus}
          >
            {/* <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              justifyContent={"space-evenly"}
              width="100%"
              sx={{ py: 2, width: "100%" }}
            > */}
            <Tab
              value={0}
              label={
                <InvoiceAnalytic
                  title="All"
                  total={invoices.length}
                  percent={100}
                  price={sumBy(invoices, "total")}
                  icon="solar:bill-list-bold-duotone"
                  color={theme.palette.info.main}
                />
              }
            />
            <Tab
              value={1}
              label={
                <InvoiceAnalytic
                  title="Paid"
                  total={getInvoiceLength("paid")}
                  percent={getPercentByStatus("paid")}
                  price={getTotalAmount("paid")}
                  icon="solar:file-check-bold-duotone"
                  color={theme.palette.success.main}
                />
              }
            />
            <Tab
              value={2}
              label={
                <InvoiceAnalytic
                  title="Outstanding"
                  total={getInvoiceLength("authorised")}
                  percent={getPercentByStatus("authorised")}
                  price={getTotalAmount("authorised")}
                  icon="solar:sort-by-time-bold-duotone"
                  color={theme.palette.warning.main}
                />
              }
            />
            <Tab
              value={3}
              label={
                <InvoiceAnalytic
                  title="Overdue"
                  total={getInvoiceLength("overdue")}
                  percent={getPercentByStatus("overdue")}
                  price={getTotalAmount("overdue")}
                  icon="solar:bell-bing-bold-duotone"
                  color={theme.palette.error.main}
                />
              }
            />
            {/* </Stack> */}
          </Tabs>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <InvoiceListHead
                headLabel={FILTERED_HEADS}
                rowCount={1}
                numSelected={0}
              />
              <TableBody>
                {invoices.map((row: any) => {
                  return (
                    <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                      <TableCell align="left">
                        <Typography variant="subtitle2" noWrap>
                          {/* {isBuyer
                            ? row.businessRelationship.seller.name
                            : row.businessRelationship.buyer.name} */}
                          {row.customer.fullName}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">{row.invoiceNumber}</TableCell>

                      <TableCell align="left">
                        {fDate(row.issuedDate)}
                      </TableCell>

                      <TableCell align="left">{fDate(row.dueDate)}</TableCell>

                      <TableCell align="left">
                        {formatCurrency(row.total)}
                      </TableCell>

                      {/* <TableCell align="left">{row.netTerms}</TableCell> */}

                      <TableCell align="left">{getStatus(row)}</TableCell>

                      <TableCell align="left">{getComms(row)}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          // row={row.id}
                          size="large"
                          color="inherit"
                          onClick={handleOpenMenu}
                        >
                          <Iconify icon={"eva:more-vertical-fill"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>

      <InvoiceViewModal
        id={invoiceViewId || ""}
        toggle={() => {
          setInvoiceViewId(null)
        }}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 170,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
        // onClick={() => {
        //   setInvoiceViewId(open?.getAttribute("row"))
        //   handleCloseMenu()
        // }}
        >
          <Box style={{ display: "flex", gap: "1rem" }}>
            <ReceiptLongOutlined />
            View
          </Box>
        </MenuItem>
        <MenuItem
        // onClick={() => {
        //   setInvoiceViewId(open?.getAttribute("row"))
        //   handleCloseMenu()
        // }}
        >
          <Box style={{ display: "flex", gap: "1rem" }}>
            <PaymentOutlined />
            Record Payment
          </Box>
        </MenuItem>

        <MenuItem
        // onClick={() => {
        //   setInvoiceViewId(open?.getAttribute("row"))
        //   handleCloseMenu()
        // }}
        >
          <Box style={{ display: "flex", gap: "1rem" }}>
            <MailOutlined />
            Remind
          </Box>
        </MenuItem>

        {/* {isBuyer && (
          <MenuItem
            disabled={
              !open ||
              invoices.find((row) => open.getAttribute("row") === row.id)
                .confirmedBy
            }
            onClick={() => {
              const invoiceObj = invoices.find(
                (row) => open.getAttribute("row") === row.id,
              )

              navigate(
                queryString.stringifyUrl({
                  url: "/invoice/confirm/business",
                  query: { invoice: invoiceObj.id },
                }),
              )
            }}
          >
            <Iconify icon={"mdi:stamper"} sx={{ mr: 2 }} />
            Accept
          </MenuItem>
        )} */}
      </Popover>
    </>
  )
}
