// @mui

import { WarningAmberOutlined } from "@mui/icons-material"
import { CreditApplication, UploadedFile } from "src/types"

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { enqueueSnackbar } from "notistack"

import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import { useSendManualBankReferenceRequest } from "src/queries/credit/useSendManualBankReferenceRequest"

export default function RequestManualBankReferenceDialog({
  application,
  onClose,
}: {
  application: CreditApplication
  onClose: () => void
}) {
  const [enableSendManualRef, setEnableSendManualRef] = useState(false)

  const { data: customDocuments } = useRequestedCustomDocument(
    undefined,
    application.id,
  )

  const [doc, setDoc] = useState<string>("none")

  const { execute: requestBankReference, isLoading: isSendingReference } =
    useSendManualBankReferenceRequest(application.id || "", () => {
      enqueueSnackbar("Request successfully sent to bank", {
        variant: "success",
      })
      onClose()
    })
  return (
    <Dialog open>
      <DialogTitle>Attention!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            You are about to send sensitive information to an external contact
            at <b>{application?.data?.bankContactEmail}</b>. The following
            documents will be attached:
          </Typography>
          <List>
            <ListItem>
              <WarningAmberOutlined style={{ marginRight: "8px" }} />
              Signed Credit Application
            </ListItem>
            <ListItem>
              <WarningAmberOutlined style={{ marginRight: "8px" }} />
              Bank Authorization
            </ListItem>
          </List>
          <Typography style={{ marginBottom: "1rem" }}>
            Please confirm that the bank contact email address shown above is
            correct and click Send to proceed.
          </Typography>
          <Grid container style={{ marginBottom: "1rem" }}>
            <Grid item md={12}>
              <Typography style={{ marginBottom: "1rem" }}>
                Select any files you would like to attach to the email
              </Typography>
              <Select
                labelId="document-select"
                id="document-select"
                value={doc}
                fullWidth
                onChange={(e) => {
                  setDoc(e.target.value)
                }}
              >
                <MenuItem value={"none"} key={"none"}>
                  No Additional Attachments
                </MenuItem>
                <Divider />
                <MenuItem value={-2} key={-2} disabled>
                  Uploaded Files
                </MenuItem>
                {(application?.files as UploadedFile[])?.map(
                  (file: UploadedFile) => {
                    return (
                      <MenuItem value={file.id} key={file.id}>
                        {file.name}
                      </MenuItem>
                    )
                  },
                )}

                {(!application?.files || application?.files.length === 0) && (
                  <MenuItem value={-5} key={-5}>
                    No uploaded files
                  </MenuItem>
                )}

                <Divider />
                <MenuItem value={-1} key={-1} disabled>
                  Requested Documents
                </MenuItem>

                {customDocuments
                  ?.filter((doc) => doc.completed)
                  .map((doc) => {
                    return (
                      <MenuItem value={doc.id} key={doc.id}>
                        {doc.document.name}
                      </MenuItem>
                    )
                  })}

                {!customDocuments?.find((doc) => doc.completed) && (
                  <MenuItem value={-3} key={-3} disabled>
                    No completed requests
                  </MenuItem>
                )}
              </Select>
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                id="agreement-checkbox"
                value="remember"
                color="primary"
                onChange={(event) => {
                  setEnableSendManualRef(event.target.checked)
                }}
                checked={enableSendManualRef}
              />
            }
            label={
              "I have verified that the contact information is correct and agree to share this information with the contact."
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isSendingReference}
          variant="contained"
          disabled={!enableSendManualRef}
          onClick={() => requestBankReference(doc)}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
