import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { Group, User, UserToGroup } from "src/types"
import * as yup from "yup"
import { enqueueSnackbar } from "notistack"
import { usePatchAddUserFromGroup } from "src/queries/internal/usePatchAddUserFromGroup"

export default function AddGroupToUserDialog({
  open,
  onClose,
  user,
  refetch,
}: {
  open: boolean
  onClose: () => void
  user: User
  refetch: () => void
}) {
  const {
    execute: executeAddUserFromGroup,
    isLoading: isLoadingAddUserFromGroup,
  } = usePatchAddUserFromGroup()

  const schema = yup.object({
    userId: yup.string().required("Required"),
    groupId: yup.string().required("Required"),
  })
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">Add User To Group</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={{ userId: user.id } as UserToGroup}
            onSubmit={(values: UserToGroup) => {
              executeAddUserFromGroup(values, () => {
                refetch()
                onClose()
                enqueueSnackbar("User updated", {
                  variant: "success",
                })
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <Stack spacing={3}>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Stack spacing={1} sx={{ m: 1 }}>
                    <Typography variant="body1">
                      User: {user.firstName} {user.lastName} ({user.email})
                    </Typography>
                  </Stack>
                  <Stack spacing={1} sx={{ m: 1 }}>
                    <Typography variant="caption">Group</Typography>
                    <Select
                      fullWidth
                      labelId="group-label"
                      id="groupId"
                      label="groupId"
                      name="groupId"
                      value={props.values.groupId}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.groupId)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>
                      <MenuItem value={Group.CreditManager}>
                        Credit Manager
                      </MenuItem>
                      <MenuItem value={Group.Buyer}>Buyerr</MenuItem>
                      <MenuItem value={Group.NetNowAdmin}>NetNowAdmin</MenuItem>
                      <MenuItem value={Group.Sales}>Sales</MenuItem>
                      <MenuItem value={Group.NeedsSelfOnboarding}>
                        NeedsSelfOnboarding
                      </MenuItem>
                    </Select>
                  </Stack>
                  <Stack spacing={1} sx={{ m: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      fullWidth
                      disabled={isLoadingAddUserFromGroup}
                      onClick={() => {
                        props.handleSubmit()
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Paper>
              </Stack>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
