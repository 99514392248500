import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { AutoCompleteOption } from "../../types/components/autocomplete.types"
import { InputProps, useMediaQuery } from "@mui/material"

interface Props<T> {
  id: string
  options: Array<T>
  style: React.CSSProperties
  onValueChanged: (value: string) => void
  loading: boolean
  placeholder: string
  required?: boolean
  value: string
  error: boolean
  helperText: string | undefined
  inputProps?: Partial<InputProps>
}

export default function AutoComplete<T extends AutoCompleteOption>({
  options,
  style,
  onValueChanged,
  loading,
  placeholder,
  required,
  value,
  error,
  helperText,
  inputProps,
}: Props<T>) {
  const matches = useMediaQuery("(max-width: 899px)")
  return (
    <Autocomplete
      style={style}
      loading={loading}
      freeSolo
      id="business-name-search"
      disableClearable
      options={options.map((option) => option.title)}
      defaultValue={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={matches ? placeholder : ""}
          label={matches ? "" : placeholder}
          error={error}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            type: "search",
          }}
          fullWidth
          required={required}
          onChange={(event) => onValueChanged(event.target.value)}
        />
      )}
    />
  )
}
