import { QueryClient } from "react-query"
import { CreditApplication } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function usePostAnalytics(
  onSuccess?: (data: CreditApplication) => void,
) {
  const ENDPOINT = "/analytics/save/"
  const post = useTypedMutation("post/analytics/save/", onSuccess)

  const execute = (
    event_type: "submit" | "save" | "complete" | "collaborator_invite",
    app_type: "cash" | "credit" | "manual",
    id: string,
    section: string,
    data?: {
      seller?: string
      buyer?: string
      buyerEmail?: string
      buyerPhoneNumber?: string
      signerEmail?: string
    },
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    interface Body {
      event_type: string
      application_type: string
      application_id: string
      section: string
      seller_name?: string
      buyer_name?: string
      buyer_email?: string
      buyer_phone?: string
      signer_email?: string
    }

    const body: Body = {
      event_type: event_type,
      application_type: app_type,
      application_id: id,
      section: section,
    }

    if (data) {
      body.seller_name = data.seller
      body.buyer_name = data.buyer
      body.buyer_email = data.buyerEmail
      body.buyer_phone = data.buyerPhoneNumber
      body.signer_email = data.signerEmail
    }

    post.mutate(
      {
        endpoint: ENDPOINT,
        method: "post",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}
