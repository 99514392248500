import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  useMediaQuery,
  FormControl,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"
import { ApplicationTemplate, CreditApplication } from "src/types"
import { PAGE_LABEL_PERSONAL_GUARANTY } from "./constants"
import { getNumberOfGuanators } from "./schemas/PersonalGuarantySectionSchema"
import { isPageRequired } from "./template_helpers"
import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import { customWordMapping } from "src/statics"
import { useEffect, useState } from "react"
import { useValidateEmail } from "src/queries/vendors/useValidateEmail"

const PAGE_LABEL = PAGE_LABEL_PERSONAL_GUARANTY

type Props = {
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onContinue: () => void
  onSkip?: () => void
  template: ApplicationTemplate
  handleBack: () => void
  activeStep: number
}

export default ({
  data,
  errors,
  onChange,
  onSkip,
  onContinue,
  template,
  handleBack,
  activeStep,
}: Props) => {
  const numberOfGuarantors = getNumberOfGuanators(template)

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")
  const [showError, setShowError] = useState(false)
  const [emailToValidate, setEmailToValidate] = useState("")
  const { data: emailValidationResult } = useValidateEmail(
    emailToValidate ? data[emailToValidate] : "",
    "PERSONALGUARANTOR",
  )

  useEffect(() => {
    if (emailValidationResult?.verdict === "Invalid") {
      setShowError(true)
    }
  }, [emailValidationResult, emailToValidate])

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          Personal Guarantor
          {isPageRequired(PAGE_LABEL, template, data)
            ? ""
            : ` (${customWordMapping("Optional", template)})`}
        </Typography>
      )}
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        A personal guarantor (PG) helps us assess your application. Please fill
        out the form below. The PG document will be sent to the email(s) you
        specify in this form to be digitally signed. Therefore, as a minimum, we
        require that the email address of the guarantor(s) is included.
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}
      <form>
        {numberOfGuarantors > 1 && (
          <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
            Guarantor 1
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <FormControl
            margin={matches ? "normal" : "none"}
            sx={{ width: "100%" }}
          >
            <FormLabel
              labelName="Full Name"
              required={isPageRequired(PAGE_LABEL, template, data)}
              idFor="guaranty-person-name"
            />
            <TextField
              id="guaranty-person-name"
              margin={matches ? "none" : "normal"}
              fullWidth
              label={matches ? "" : "Full Name"}
              placeholder={matches ? "Enter name" : ""}
              type="text"
              required={isPageRequired(PAGE_LABEL, template, data)}
              value={data.guarantorName}
              onChange={(event) => {
                onChange("guarantorName", event.target.value)
              }}
              error={Boolean(errors?.guarantorName)}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "32px",
            alignItems: "center",
          }}
        >
          <FormControl
            margin={matches ? "normal" : "none"}
            sx={{ width: "100%" }}
          >
            <FormLabel
              labelName="Email"
              required={isPageRequired(PAGE_LABEL, template, data)}
              idFor="guaranty-person-email"
            />
            <TextField
              id="guaranty-person-email"
              margin={matches ? "none" : "normal"}
              fullWidth
              label={matches ? "" : "Email"}
              placeholder={matches ? "Enter email" : ""}
              type="email"
              required={isPageRequired(PAGE_LABEL, template, data)}
              value={data.guarantorEmail}
              onChange={(event) => {
                setEmailToValidate("")
                setShowError(false)
                onChange("guarantorEmail", event.target.value)
              }}
              onBlur={(event) => {
                setEmailToValidate("guarantorEmail")
              }}
              error={Boolean(errors?.guarantorEmail)}
            />
          </FormControl>
          <FormControl
            margin={matches ? "normal" : "none"}
            sx={{ width: "100%" }}
          >
            <FormLabel
              labelName="Phone Number"
              required={isPageRequired(PAGE_LABEL, template, data)}
            />

            <ReactPhoneInput
              value={data.guarantorPhoneNumber}
              country={"us"}
              onChange={(event) => {
                onChange("guarantorPhoneNumber", "+" + event)
              }}
              label={matches ? "" : "Phone"}
              component={TextField}
              containerStyle={{ width: "100%", marginTop: "8px" }}
              inputProps={{
                id: "guarantorPhoneNumber",
                required: isPageRequired(PAGE_LABEL, template, data),
                error: Boolean(errors?.guarantorPhoneNumber),
              }}
            />
          </FormControl>
        </Box>

        {numberOfGuarantors > 1 &&
          new Array(numberOfGuarantors - 1).fill(0).map((_, index) => (
            <Stack key={index}>
              <Typography variant="subtitle1" style={{ marginTop: "8px" }}>
                Guarantor {index + 2}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                  alignItems: "center",
                }}
              >
                <FormControl
                  margin={matches ? "normal" : "none"}
                  sx={{ width: "100%" }}
                >
                  <FormLabel
                    labelName="Full Name"
                    idFor="guaranty-person-name-2"
                  />

                  <TextField
                    id="guaranty-person-name-2"
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "Full Name"}
                    placeholder={matches ? "Enter Name" : ""}
                    type="text"
                    value={data["guarantorName" + (index + 2)]}
                    onChange={(event) => {
                      onChange(
                        "guarantorName" + (index + 2),
                        event.target.value,
                      )
                    }}
                    error={Boolean(errors?.["guarantorName" + (index + 2)])}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                  width: "100%",
                  gap: matches ? "0" : "32px",
                  alignItems: "center",
                }}
              >
                <FormControl
                  margin={matches ? "normal" : "none"}
                  sx={{ width: "100%" }}
                >
                  <FormLabel
                    labelName="Email"
                    idFor="guaranty-person-email-2"
                  />

                  <TextField
                    id="guaranty-person-email-2"
                    margin={matches ? "none" : "normal"}
                    fullWidth
                    label={matches ? "" : "Email"}
                    placeholder={matches ? "Enter email" : ""}
                    type="email"
                    value={data["guarantorEmail" + (index + 2)]}
                    onChange={(event) => {
                      setEmailToValidate("")
                      setShowError(false)
                      onChange(
                        "guarantorEmail" + (index + 2),
                        event.target.value,
                      )
                    }}
                    error={Boolean(errors?.["guarantorEmail" + (index + 2)])}
                    onBlur={(event) => {
                      setEmailToValidate("guarantorEmail" + (index + 2))
                    }}
                  />
                </FormControl>
                <FormControl
                  margin={matches ? "normal" : "none"}
                  sx={{ width: "100%" }}
                >
                  <FormLabel labelName="Phone Number" />
                  <ReactPhoneInput
                    value={data["guarantorPhoneNumber" + (index + 2)]}
                    country={"us"}
                    label={matches ? "" : "Phone"}
                    onChange={(event) => {
                      onChange(
                        "guarantorPhoneNumber" + (index + 2),
                        "+" + event,
                      )
                    }}
                    component={TextField}
                    containerStyle={{ width: "100%", marginTop: "8px" }}
                    inputProps={{
                      id: "guarantorPhoneNumber" + (index + 2),
                      error: Boolean(
                        errors?.["guarantorPhoneNumber" + (index + 2)],
                      ),
                    }}
                  />
                </FormControl>
              </Box>
            </Stack>
          ))}
        {matches ? (
          <MobileNavButtonsBlock
            click={onContinue}
            disable={false}
            handleBack={handleBack}
            activeStep={activeStep}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "2rem",
            }}
          >
            <LoadingButton
              onClick={() => {
                onContinue()
              }}
              size="large"
              fullWidth
              endIcon={<ArrowForward />}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save & Continue
            </LoadingButton>
            {template?.formTemplate.pages.find(
              (step) => step.label === PAGE_LABEL,
            )?.skippable &&
              onSkip && (
                <Button
                  id="continue-owner"
                  onClick={() => {
                    onSkip()
                  }}
                  size="large"
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Skip
                </Button>
              )}
          </Box>
        )}
      </form>
      {showError && (
        <Dialog open={showError}>
          <DialogTitle>Did you enter the right email?</DialogTitle>
          <DialogContent>
            <Typography>
              The email <b>{emailValidationResult?.email}</b> seems to not be
              correct. Please double check and make sure the address is valid to
              avoid delays in processing your application.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowError(false)
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
