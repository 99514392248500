import { Formik } from "formik"
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import {
  PAGE_LABEL_PERSONAL_GUARANTY,
  PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
} from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Config, Page } from "src/types"
import { getConfigFromPage, getPageFromTemplate } from "src/utils/utils"
import { usePatchCreditApplicationTemplatePage } from "src/queries/internal/usePatchCreditApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"
import SectionWhenComponent from "../SectionWhenComponent"

export default function CreditSectionPersonalGuarantyDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const page = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_PERSONAL_GUARANTY,
  )

  const { execute, isLoading } = usePatchCreditApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={page ? page : ({} as Page)}
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enable Section"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="required"
                      checked={props.values.required as boolean}
                      onChange={props.handleChange}
                    />
                  }
                  label="Require Section"
                />
                <TextField
                  type="number"
                  name="numberOfGuarantors"
                  label={PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS}
                  value={
                    getConfigFromPage(
                      props.values,
                      PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
                    )
                      ? (getConfigFromPage(
                          props.values,
                          PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
                        )?.value as number)
                      : 0
                  }
                  onChange={(event) => {
                    const config = getConfigFromPage(
                      props.values,
                      PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
                    ) as Config
                    if (config) {
                      props.setFieldValue(
                        "config",
                        props.values.config.map((c) => {
                          if (
                            c.label ===
                            PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS
                          ) {
                            c.value = Number(event.target.value)
                          }
                          return c
                        }),
                      )
                    } else {
                      props.values.config.push({
                        label: PERSONAL_GUARANTY_CONFIG_NUMBER_OF_GUARANTORS,
                        value: Number(event.target.value),
                      })
                    }
                  }}
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
        {page && (
          <SectionWhenComponent
            page={page}
            template={applicationTemplate}
            refetch={refetch}
          />
        )}
      </Stack>
    </>
  )
}
