import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { ClientCreditRiskMonitorCredentials } from "src/types"

export function usePatchInternalCreditRiskMonitorCredentials(
  onSuccess?: () => void,
) {
  const patch = useTypedMutation<ClientCreditRiskMonitorCredentials>(
    "patch/internal/credit-risk-monitor",
    onSuccess,
  )
  const execute = (
    body: ClientCreditRiskMonitorCredentials,
    onSuccess: () => void,
  ) => {
    const id = body.id
    delete body["id"]

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/credit-risk-monitor/" + id + "/",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/credit-risk-monitor".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
