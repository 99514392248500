import { ApplicationTemplate } from "src/types"
import CashSectionUserDetails from "./ApplicationTemplate/CashSectionUserDetails"
import CashSectionBusinessDetails from "./ApplicationTemplate/CashSectionBusinessDetails"
import CashSectionZohoTemplateId from "./ApplicationTemplate/CashSectionZohoTemplateId"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import {
  PAGE_LABEL_USER_DETAILS,
  PAGE_LABEL_BUSINESS_DETAILS,
} from "src/sections/@dashboard/intake_sections/constants"

export default function EditCreditApplicationTemplate({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="zoho-template-content"
          id="zoho-template-header"
        >
          <Typography variant="subtitle2">Zoho Template Section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CashSectionZohoTemplateId
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="user-content"
          id="user-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_USER_DETAILS} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CashSectionUserDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="user-content"
          id="user-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_BUSINESS_DETAILS} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CashSectionBusinessDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
