import { Form } from "formik"

import QontoStepper, { COMPLETE } from "src/sections/@dashboard/components/QontoStepper"

import { Box, useMediaQuery, useTheme } from "@mui/material"

import TradeReferencesSection from "src/sections/@dashboard/intake_sections/TradeReferencesSection"
import { getTradeEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/TradeReferenceSectionSchema"

import BankReferenceSection from "src/sections/@dashboard/intake_sections/BankReferenceSection"
import { getBankEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/BankReferenceSectionSchema"

import OwnersSection from "src/sections/@dashboard/intake_sections/OwnersSection"
import { getOwnersEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"

import CompanyDetailsSection from "src/sections/@dashboard/intake_sections/CompanyDetailsSection"
import { getCompanyDetailsEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"

import PersonalGuarantySection from "src/sections/@dashboard/intake_sections/PersonalGuarantySection"
import { getPersonalGuarantyEnabledSchema } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"
import CustomQuestionsSection from "src/sections/@dashboard/intake_sections/CustomQuestionsSection"

import Complete from "../../intake_sections/Complete"
import { FormComponent } from "src/types/credit/FormComponent"
import { getAdditionalQuestionsSchema } from "../../intake_sections/schemas/CustomQuestionsSectionsSchema"
import {
  PAGE_LABEL_ADDITIONAL_QUESTIONS,
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
} from "../../intake_sections/constants"

export default ({
  steps,
  activeStep,
  handleBack,
  props,
  onContinue,
  onDataFieldUpdated,
  onCustomFieldUpdated,
  application,
  template,
  filesInMemory,
}: FormComponent) => {
  const theme = useTheme()
  // const params = useParams()
  // const { id } = params

  const matchesXl = useMediaQuery("(max-width: 1199px)")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "5%",
        maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      <Box
        style={{ margin: matchesXl ? "0" : "0 5% 32px 5%" }}
        display={"block"}
      >
        <QontoStepper
          steps={steps}
          data={props.values.data}
          activeStep={activeStep}
          handleBack={handleBack}
          template={template}
        />
      </Box>
      <Box
        style={
          matchesXl
            ? {}
            : {
                textAlign: "left",
                padding: "32px 10% 32px 10%",
                border: "solid",
                borderWidth: "1px",
                borderRadius: "16px",
                borderColor: theme.palette.primary.main,
              }
        }
      >
        <Form>
          {/*
          {completionError && (
            <>
              <Typography
                component="h1"
                variant="h5"
                style={{ marginTop: "8px" }}
              >
                Error!
              </Typography>
              <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
                There was an error while completing your application. Please
                refresh this page. If the error persists, please contact us at
                info@netnow.io with the following information.
              </Typography>
              <Divider style={{ margin: "16px 0 16px 0" }} />
              <Typography color="error">{completionError.message}</Typography>
              <Typography color="error">Application ID: {id}</Typography>
              <Typography color="error">
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  completionError.response?.data?.error
                }
              </Typography>
            </>
          )} */}

          {activeStep == COMPLETE && <Complete />}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("business") && (
              <CompanyDetailsSection
                activeStep={activeStep}
                handleBack={handleBack}
                application={application}
                data={props.values.data}
                errors={{
                  ...props.errors.data,
                  salesRep: props.errors.salesRep,
                }}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_BUSINESS_DETAILS)
                }}
                onCustomChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value, PAGE_LABEL_BUSINESS_DETAILS)
                }}
                onContinue={() => {
                  onContinue(
                    getCompanyDetailsEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_BUSINESS_DETAILS,
                  )
                }}
                template={template}
                setFilesInMemory={(filesInMemory) => {
                  filesInMemory.forEach((files, key) => {
                    onCustomFieldUpdated(key, files)
                  })
                }}
                filesInMemory={filesInMemory || []}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("owners") && (
              <OwnersSection
                activeStep={activeStep}
                handleBack={handleBack}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_OWNERS)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value, PAGE_LABEL_OWNERS)
                }}
                onContinue={() => {
                  onContinue(
                    getOwnersEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_OWNERS,
                  )
                }}
                setFilesInMemory={(filesInMemory) => {
                  filesInMemory.forEach((files, key) => {
                    onCustomFieldUpdated(key, files)
                  })
                }}
                filesInMemory={filesInMemory || []}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("trade") && (
              <TradeReferencesSection
                activeStep={activeStep}
                handleBack={handleBack}
                size={3}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_TRADE_REFERENCES)
                }}
                onContinue={() => {
                  onContinue(
                    getTradeEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_TRADE_REFERENCES,
                  )
                }}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("bank") && (
              <BankReferenceSection
                activeStep={activeStep}
                handleBack={handleBack}
                application={application}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_BANK_REFERENCE)
                }}
                onCustomChange={(
                  key: string,
                  value: string | File | undefined,
                ) => {
                  onCustomFieldUpdated(key, value, PAGE_LABEL_BANK_REFERENCE)
                }}
                onContinue={() => {
                  onContinue(
                    getBankEnabledSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_BANK_REFERENCE,
                  )
                }}
                setFilesInMemory={(filesInMemory) => {
                  filesInMemory.forEach((files, key) => {
                    onCustomFieldUpdated(key, files)
                  })
                }}
                filesInMemory={filesInMemory || []}
                template={template}
              />
            )}
          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("guaranty") && (
              <PersonalGuarantySection
                activeStep={activeStep}
                handleBack={handleBack}
                data={props.values.data}
                errors={props.errors.data}
                onChange={(key, val) => {
                  onDataFieldUpdated(key, val, PAGE_LABEL_PERSONAL_GUARANTY)
                }}
                onContinue={() => {
                  onContinue(
                    getPersonalGuarantyEnabledSchema(
                      template,
                      props.values.data,
                    ),
                    undefined,
                    undefined,
                    PAGE_LABEL_PERSONAL_GUARANTY,
                  )
                }}
                template={template}
              />
            )}

          {steps[activeStep] &&
            steps[activeStep].label.toLowerCase().includes("additional") && (
              <CustomQuestionsSection
                application={application}
                errors={props.errors.data}
                data={props.values.data}
                onChange={function (
                  key: string,
                  value: string | File | undefined,
                ): void {
                  onCustomFieldUpdated(key, value)
                }}
                setFilesInMemory={(filesInMemory) => {
                  filesInMemory.forEach((files, key) => {
                    onCustomFieldUpdated(key, files)
                  })
                }}
                filesInMemory={filesInMemory || []}
                onContinue={function (): void {
                  onContinue(
                    getAdditionalQuestionsSchema(template, props.values.data),
                    undefined,
                    undefined,
                    PAGE_LABEL_ADDITIONAL_QUESTIONS,
                  )
                }}
              />
            )}
        </Form>
      </Box>
    </Box>
  )
}
