import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material"

export default ({
  title = "View Terms and Conditions",
  fileUrl,
  onClose,
}: {
  title?: string
  fileUrl: string
  onClose: () => void
}) => {
  return (
    <Dialog
      open={!!fileUrl}
      onClose={() => {
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth={false}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <iframe
            src={fileUrl}
            style={{
              width: "1000px",
              height: "75vh",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
