import { useQuery } from "react-query"
import { TemplateCustomField } from "../../types"

export function useTemplateCustomFields(businessId?: string) {
  let endpoint = "/configure/custom_fields"
  if (businessId) {
    endpoint = `/configure/custom_fields?business_id=${businessId}`
  }
  return useQuery<Array<TemplateCustomField>>(endpoint, {
    retry: (failureCount, error) => {
      if (failureCount < 1) {
        return true
      }
      // do not retry on 404
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (error as any)?.response?.status !== 404
    },
  })
}
