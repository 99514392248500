import { ArticleOutlined, Label } from "@mui/icons-material"
import { Button, Paper, Stack, Typography } from "@mui/material"
import { CreditRiskMonitorPurchasedReport } from "src/types"
import { fDate } from "src/utils/formatTime"

type Props = {
  item: CreditRiskMonitorPurchasedReport
  key: number
  onView: () => void
}
export default ({ item, key, onView }: Props) => {
  return (
    <Stack
      key={key}
      component={Paper}
      variant="outlined"
      spacing={1}
      sx={{
        borderRadius: 2,
        bgcolor: "unset",
        cursor: "pointer",
        position: "relative",
        p: { xs: 2.5, sm: 2 },
        marginBottom: 1,
        "&:hover": {
          bgcolor: "background.paper",
          boxShadow: (theme) => theme.shadows[20],
        },
      }}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack direction="column">
          <Stack direction="row">
            <ArticleOutlined />{" "}
            <Typography variant="subtitle2">{item.businessName}</Typography>
          </Stack>
          <Stack>
            <Typography variant="caption">
              {item.address} {item.city} {item.country}{" "}
              {item.createdAt ? "| " + fDate(item.createdAt) : ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={() => {
              onView()
            }}
          >
            View
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
