import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalBulkCreditApplicationUpload } from "src/queries/internal/useCreateInternalBulkCreditApplicationUpload"
import { APPLICATION_STAGE } from "src/statics"
import { CreditApplicationBulkImport } from "src/types"
import * as yup from "yup"

export default function CreditApplicationBulkUploadDialog({
  open,
  onClose,
  businessId,
  refetch,
}: {
  open: boolean
  onClose: () => void
  businessId: string
  refetch: () => void
}) {
  const { execute, isLoading } = useCreateInternalBulkCreditApplicationUpload()
  const schema = yup.object({
    business: yup.string().required("Required"),
    applications: yup.string().required("Required"),
    duplicateCheck: yup.boolean().required("Required"),
    stage: yup.string().required("Required"),
  })
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">
          Bulk Upload Credit Applications
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={
              {
                business: businessId,
                duplicateCheck: true,
              } as CreditApplicationBulkImport
            }
            onSubmit={(values: CreditApplicationBulkImport) => {
              execute(values, () => {
                enqueueSnackbar(
                  "Credit Application Bulk Upload Process Started. Please Check Your Email for the Result",
                  {
                    variant: "success",
                  },
                )
                onClose()
                refetch()
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <>
                <Stack spacing={4}>
                  <Input
                    type="file"
                    id="applications"
                    name="applications"
                    placeholder="applications"
                    onChange={(event) => {
                      if (event.nativeEvent?.target) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const target = event.nativeEvent.target as any
                        props.setFieldValue("applications", target.files[0])
                      }
                    }}
                    error={Boolean(props.errors?.applications)}
                  />
                  <Stack spacing={1}>
                    <Typography variant="caption">
                      Default Application Stage
                    </Typography>
                    <Select
                      fullWidth
                      labelId="stage-label"
                      id="stage"
                      label="stage"
                      name="stage"
                      value={props.values.stage}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.stage)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.ONE}>One</MenuItem>
                      <MenuItem value={APPLICATION_STAGE.TWO}>Two</MenuItem>
                      <MenuItem value={APPLICATION_STAGE.THREE}>Three</MenuItem>
                      <MenuItem value={APPLICATION_STAGE.FOUR}>Four</MenuItem>
                      <MenuItem value={APPLICATION_STAGE.MANUAL_INCOMPLETE}>
                        Manual Incomplete
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.MANUAL_COMPLETE}>
                        Manual Complete
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.AWAITING_SIGNATURE}>
                        Awaiting Signature
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.COMPLETED_BY_CLIENT}>
                        Complete By Client
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.APPROVED}>
                        Approved
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.REJECTED}>
                        Rejected
                      </MenuItem>
                      <MenuItem value={APPLICATION_STAGE.ARCHIVE}>
                        Archive
                      </MenuItem>
                    </Select>
                  </Stack>
                  <Stack spacing={1}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          name="duplicateCheck"
                          checked={props.values?.duplicateCheck as boolean}
                          onChange={props.handleChange}
                        />
                      }
                      label="Duplicate Check"
                    />
                  </Stack>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
