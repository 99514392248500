import { Formik } from "formik"
import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material"
import { PAGE_LABEL_BUSINESS_DETAILS } from "src/sections/@dashboard/intake_sections/constants"
import { ApplicationTemplate, Page } from "src/types"
import { getPageFromTemplate } from "src/utils/utils"
import { usePatchCashApplicationTemplatePage } from "src/queries/internal/usePatchCashApplicationTemplatePage"
import { enqueueSnackbar } from "notistack"

export default function CreditSectionBusinessDetails({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  const businessDetailsPage = getPageFromTemplate(
    applicationTemplate,
    PAGE_LABEL_BUSINESS_DETAILS,
  )

  const { execute } = usePatchCashApplicationTemplatePage(
    applicationTemplate.id || "",
    () => {
      enqueueSnackbar("Application Template Updated", { variant: "success" })
    },
  )

  return (
    <>
      <Stack sx={{ p: 0.3 }}>
        <Formik
          initialValues={
            businessDetailsPage ? businessDetailsPage : ({} as Page)
          }
          onSubmit={(values) => {
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <FormControl component="fieldset" variant="standard">
              <Stack spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      name="enabled"
                      checked={props.values.enabled}
                      onChange={props.handleChange}
                    />
                  }
                  label="Enabled Section"
                />
              </Stack>
              <Stack sx={{ marginY: 2 }}>
                <Button
                  variant="contained"
                  // disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </Stack>
            </FormControl>
          )}
        </Formik>
      </Stack>
    </>
  )
}
