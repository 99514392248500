import { INVISIBLE_CHART_ID } from "src/statics"
import {
  BankReferenceChart,
  CreditApplication,
  PDFFile,
  UploadedFile,
} from "src/types"
import useGenerateDigitalBankReferencePdf from "./useGenerateDigitalBankReferencePdf"
import { useEffect, useState } from "react"
import { startCase } from "lodash"
import { format } from "date-fns"

import { useGetBankReferenceChart } from "src/queries/credit/useGetBankReferenceChart"
import { useUploadedCreditReport } from "src/queries/credit/useUploadedCreditReport"
import { useCreditRiskMonitorPurchasedReport } from "src/queries/vendors/useCreditRiskMonitorSearch"
import { useCreditsafePurchasedReport } from "src/queries/vendors/useCreditSafeSearch"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { useExperianPurchasedReport } from "src/queries/vendors/useExperianSearch"
import { fileData } from "src/utils/files"
import { createZipFileOfPDFs } from "src/utils/utils"
import useGenerateTradeReferencePdf from "./useGenerateTradeReferencePdf"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import * as Sentry from "@sentry/react"
import useGenerateApplicationPdf from "./useGenerateApplicationPdf"

export default (application: CreditApplication) => {
  const { refetch: fetchPdf, isLoading: isFetchingPdf } = useDocumentPDF(
    application ? application.data.zohoReqId : "",
  )
  const { execute: generatePdf } = useGenerateApplicationPdf(application)
  const [singleUnsignedPDFLoading, setSingleUnsignedPDFLoading] =
    useState(false)
  const [allUnsignedPDFLoading, setAllUnsignedPDFLoading] = useState(false)
  const [allSignedPDFLoading, setAllSignedPDFLoading] = useState(false)

  const downloadSingleSignedPDF = () => {
    fetchPdf()
      .then((res) => {
        function saveByteArray(fileName: string, b64: string) {
          const link = document.createElement("a")
          link.href = "data:application/pdf;base64," + b64
          link.download = fileName
          link.click()
        }
        if (!res.data) throw new Error("No data")
        return saveByteArray(
          `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Application ${format(
            new Date(application.createdAt as string),
            "yyyy/MM/dd",
          )}.pdf`,
          res.data,
        )
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const downloadSingleUnsignedPDF = () => {
    setSingleUnsignedPDFLoading(true)
    generatePdf()
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      })

      .then((res) => {
        const link = document.createElement("a")
        link.href = res as string
        link.download = `${startCase(
          application.data.legalBusinessName ||
            `${application.data.firstName} ${application.data.lastName}`,
        )} Credit Application ${format(
          new Date(application.createdAt as string),
          "yyyy/MM/dd",
        )}.pdf`
        setSingleUnsignedPDFLoading(false)
        return link.click()
      })
      .catch((err) => {
        setSingleUnsignedPDFLoading(false)
        Sentry.captureException(err)
      })
  }

  const { execute: generateTradeReferencePdf } =
    useGenerateTradeReferencePdf(application)

  const { refetch: fetchPersonalGuarantyPdf } = useDocumentPDF(
    application.personalGuaranty
      ? application.personalGuaranty[0]?.documentRequestId
      : "",
    "guaranty",
  )

  const { data: bankReferenceChartData } = useGetBankReferenceChart(
    application.id,
  )

  const { data: purchasedExperianReports } = useExperianPurchasedReport(
    application.id || "",
  )

  const { data: purchasedCreditsafeReports } = useCreditsafePurchasedReport(
    application.id || "",
  )

  const { data: creditRiskMonitorReports } =
    useCreditRiskMonitorPurchasedReport(application.id as string)

  const { data: creditReport } = useUploadedCreditReport(application.id || "")
  const [bankReferenceChartImageUrl, setBankReferenceChartImageUrl] = useState<
    string | undefined
  >("")

  const [creditReportFiles, setcreditReportFiles] = useState<PDFFile[]>([])
  const [pdfFiles, setPdfFiles] = useState<PDFFile[]>([])

  const { execute: generateBankReferencePdf } =
    useGenerateDigitalBankReferencePdf(
      application,
      bankReferenceChartData as BankReferenceChart,
      bankReferenceChartImageUrl,
    )

  const { data: requestedCustomDocuments, refetch: refetchRequests } =
    useRequestedCustomDocument(undefined, application.id)

  const getExtensionFromPath = (path: string) => {
    const extension = ".pdf"
    const extension_list = [
      ".jpeg",
      ".pptx",
      ".potx",
      ".ppsx",
      ".ppam",
      ".pptm",
      ".potm",
      ".ppsm",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".doc",
      ".dot",
      ".xls",
      ".xlt",
      ".xla",
      ".ppt",
      ".pot",
      ".pps",
      ".ppa",
      ".mdb",
      ".png",
      ".jpg",
      ".msg",
      ".bmp",
      ".csv",
      ".txt",
      ".rtf",
    ]
    if (path && !path.toLowerCase().includes(".pdf")) {
      for (const ext of extension_list) {
        if (path.toLowerCase().includes(ext)) {
          return ext
        }
      }
    }
    return extension
  }

  const generateBankReferenceDataURI = () => {
    const bankChart = ApexCharts.getChartByID(INVISIBLE_CHART_ID)
    if (bankChart) {
      bankChart
        .dataURI()
        .then((res) => {
          const { imgURI } = res as { imgURI: string }
          setBankReferenceChartImageUrl(imgURI)
          return
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
    }
  }

  const generateBankReferenceChart = () => {
    return generateBankReferencePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Bank Reference ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        return generateTradeReferencePDF()
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generatePersonalGuaranty = () => {
    return fetchPersonalGuarantyPdf()
      .then((res) => {
        if (res.data) {
          pdfFiles.push({
            name: `${startCase(
              application.data.legalBusinessName ||
                `${application.data.firstName} ${application.data.lastName}`,
            )} Personal Guarantor ${format(
              new Date(application.createdAt as string),
              "yyyy-MM-dd",
            )}`,
            file: res.data,
          })
        }
        return finalizeDownload()
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateTradeReferencePDF = () => {
    return generateTradeReferencePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Trade Reference ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        if (
          application.personalGuaranty &&
          application.personalGuaranty[0]?.documentRequestId
        ) {
          return generatePersonalGuaranty()
        } else {
          return finalizeDownload()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateSignedApplicationPDF = () => {
    fetchPdf()
      .then((res) => {
        if (res.data) {
          pdfFiles.push({
            name: `${startCase(
              application.data.legalBusinessName ||
                `${application.data.firstName} ${application.data.lastName}`,
            )} Credit Application ${format(
              new Date(application.createdAt as string),
              "yyyy-MM-dd",
            )}`,
            file: res.data,
          })
        }

        if (
          bankReferenceChartData &&
          bankReferenceChartData.lastUpdated &&
          bankReferenceChartData.balances &&
          Object.keys(bankReferenceChartData.balances).length > 0
        ) {
          return generateBankReferenceDataURI()
        } else {
          return generateTradeReferencePDF()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateUnsignedApplicationPDF = () => {
    generatePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Application ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        if (
          bankReferenceChartData &&
          bankReferenceChartData.lastUpdated &&
          bankReferenceChartData.balances &&
          Object.keys(bankReferenceChartData.balances).length > 0
        ) {
          return generateBankReferenceDataURI()
        } else {
          return generateTradeReferencePDF()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const finalizeDownload = () => {
    createZipFileOfPDFs(
      `${startCase(
        application.data.legalBusinessName ||
          `${application.data.firstName} ${application.data.lastName}`,
      )} Application Files`,
      pdfFiles,
    )
    setPdfFiles([])
    setAllUnsignedPDFLoading(false)
    setAllSignedPDFLoading(false)
    setBankReferenceChartImageUrl(undefined)
  }

  const downloadAllSignedPDFs = () => {
    setAllSignedPDFLoading(true)
    for (const pdfFile of creditReportFiles) {
      pdfFiles.push(pdfFile)
    }
    generateSignedApplicationPDF()
  }

  const downloadAllUnsignedPDFs = () => {
    setAllUnsignedPDFLoading(true)
    for (const pdfFile of creditReportFiles) {
      pdfFiles.push(pdfFile)
    }
    generateUnsignedApplicationPDF()
  }

  useEffect(() => {
    if (bankReferenceChartImageUrl) {
      generateBankReferenceChart()
    }
  }, [bankReferenceChartImageUrl])

  useEffect(() => {
    const currentReportFiles: PDFFile[] = []
    if (purchasedExperianReports && purchasedExperianReports.length > 0) {
      purchasedExperianReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Experian Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }
    if (purchasedCreditsafeReports && purchasedCreditsafeReports.length > 0) {
      purchasedCreditsafeReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} CreditSafe Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (creditRiskMonitorReports && creditRiskMonitorReports.length > 0) {
      creditRiskMonitorReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} CreditRiskMonitor Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (creditReport && creditReport.length > 0) {
      creditReport.forEach((report, index) => {
        if (report.file) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Report ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )} ${index + 1}`
          const { name = "" } = fileData(report.file, fileName)
          fetch(report.file)
            .then((r) => {
              currentReportFiles.push({
                name,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (requestedCustomDocuments && requestedCustomDocuments.length > 0) {
      requestedCustomDocuments.forEach((document) => {
        if (document.file) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} ${document.document.name}`
          fetch(document.file)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
                extension: getExtensionFromPath(document.file),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (application.files && application.files.length > 0) {
      application.files.forEach((file) => {
        const value = file as UploadedFile
        if (value.name && value.file) {
          fetch(value.file)
            .then((r) => {
              currentReportFiles.push({
                name: value.name,
                file: r.blob(),
                extension: getExtensionFromPath(value.file),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }
    setcreditReportFiles(currentReportFiles)
  }, [
    purchasedExperianReports,
    purchasedCreditsafeReports,
    creditReport,
    creditRiskMonitorReports,
    application.data.legalBusinessName,
    application.data.firstName,
    application.data.lastName,
    application.createdAt,
    requestedCustomDocuments,
    application.files,
  ])

  return {
    bankReferenceChartData,
    downloadSingleSignedPDF,
    downloadSingleUnsignedPDF,
    downloadAllSignedPDFs,
    downloadAllUnsignedPDFs,
    isFetchingPdf,
    singleUnsignedPDFLoading,
    allUnsignedPDFLoading,
    allSignedPDFLoading,
  }
}
