import { ApplicationTemplate } from "src/types"
import CreditSectionUserDetails from "./ApplicationTemplate/CreditSectionUserDetails"
import CreditSectionBusinessDetails from "./ApplicationTemplate/CreditSectionBusinessDetails"
import CreditSectionOwnerAndContactsDetails from "./ApplicationTemplate/CreditSectionOwnerAndContactsDetails"
import CreditSectionTradeReferencesDetails from "./ApplicationTemplate/CreditSectionTradeReferencesDetails"
import CreditSectionBankReferenceDetails from "./ApplicationTemplate/CreditSectionBankReferenceDetails"
import CreditSectionPersonalGuarantyDetails from "./ApplicationTemplate/CreditSectionPersonalGuarantyDetails"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import {
  PAGE_LABEL_BANK_REFERENCE,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_OWNERS,
  PAGE_LABEL_PERSONAL_GUARANTY,
  PAGE_LABEL_TRADE_REFERENCES,
  PAGE_LABEL_USER_DETAILS,
} from "src/sections/@dashboard/intake_sections/constants"

export default function EditCreditApplicationTemplate({
  applicationTemplate,
  refetch,
}: {
  applicationTemplate: ApplicationTemplate
  refetch: () => void
}) {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="user-details-content"
          id="user-details-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_USER_DETAILS} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionUserDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="business-details-content"
          id="business-detail-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_BUSINESS_DETAILS} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionBusinessDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="owners-and-contacts-details-content"
          id="owners-and-contacts-detail-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_OWNERS} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionOwnerAndContactsDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="trade-reference-detail-content"
          id="trade-reference-detail-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_TRADE_REFERENCES} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionTradeReferencesDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="bank-reference-detail-content"
          id="bank-reference-detail-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_BANK_REFERENCE} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionBankReferenceDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="personal-guaranty-reference-detail-content"
          id="personal-guaranty-reference-detail-header"
        >
          <Typography variant="subtitle2">
            {PAGE_LABEL_PERSONAL_GUARANTY} Section
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreditSectionPersonalGuarantyDetails
            applicationTemplate={applicationTemplate}
            refetch={refetch}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
