import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
} from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useState } from "react"
import { useRequestCashCompleteApplication } from "src/queries/cod/useRequestCashCompleteApplication"
import { CashApplication } from "src/types"

export default ({
  application,
  onClose,
}: {
  application: CashApplication
  onClose: () => void
}) => {
  const [reminderRecipient, setReminderRecipient] = useState<
    string | undefined
  >(undefined)

  const { execute: requestApplicationCompletion, isLoading } =
    useRequestCashCompleteApplication(() => {
      enqueueSnackbar("Request sent successfully.", { variant: "success" })
      onClose()
    })
  const collaboratorEmails =
    typeof application.collaborators === "string"
      ? application.collaborators?.split(",")
      : application.collaborators || []
  return (
    <Dialog open>
      <DialogTitle>Send Reminder</DialogTitle>
      <DialogContent>
        Please select a collaborator to send a reminder to complete the
        application.
        <FormControl>
          <RadioGroup
            defaultValue={undefined}
            name="radio-buttons-group"
            value={reminderRecipient}
            onChange={(e) => setReminderRecipient(e.target.value)}
          >
            {collaboratorEmails.map((email) => {
              return (
                <FormControlLabel
                  key={email}
                  value={email}
                  control={<Radio />}
                  label={
                    email === application.data["email"]
                      ? email
                      : `${email} (Assigned Signatory)`
                  }
                />
              )
            })}
            <FormControlLabel
              value={"all"}
              control={<Radio />}
              label="All Collaborators"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
        <LoadingButton
          disabled={!reminderRecipient}
          loading={isLoading}
          onClick={() => {
            if (application.id) {
              if (reminderRecipient === "all") {
                requestApplicationCompletion(application.id)
              } else {
                requestApplicationCompletion(application.id, reminderRecipient)
              }
            } else {
              enqueueSnackbar(
                "Error occurred. Please refresh the page and try again",
                { variant: "error" },
              )
            }
          }}
        >
          Send Reminder
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
