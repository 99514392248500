import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { EmailPreference } from "src/types"

export function usePatchInternalEmailPreferences(onSuccess?: () => void) {
  const patch = useTypedMutation<EmailPreference>(
    "patch/internal/email-preferences/",
    onSuccess,
  )
  const execute = (body: EmailPreference, onSuccess: () => void) => {
    const id = body.id
    delete body["id"]

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/email-preferences/" + id + "/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/email-preferences".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
