import { useState, useEffect } from "react"
import { useUser } from "../../queries"
import { useAuth0 } from "@auth0/auth0-react"
import { Outlet, useLocation } from "react-router-dom"
// @mui
import { styled } from "@mui/material/styles"
//
import Header from "./header"
import Nav from "./nav"
import axios from "axios"
import { Buffer } from "buffer"
import { error } from "../../utils/logger"
import { Helmet } from "react-helmet-async"

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
})

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

export default function InternalDashboard() {
  const [open, setOpen] = useState(false)

  const { data, isLoading: isUserLoading, refetch } = useUser()

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: {
          redirectTo: location.pathname,
          params: location.search,
        },
      })
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, location, refetch])

  return (
    <StyledRoot>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        {data && !isUserLoading && !isLoading && <Outlet />}
        {(!data || isUserLoading || isLoading) && <>Loading...</>}
      </Main>
    </StyledRoot>
  )
}
