import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

type Response = {
  summary: string
  fileBase64: string
}

export function useAdobeExtractPdf(onSuccess?: () => void) {
  const post = useTypedMutation<FormData>("post/pdf/extract", onSuccess)

  const execute = (body: FormData, onSuccess: () => void) => {
    post.mutate(
      {
        method: "post",
        endpoint: "/pdf/extract",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/pdf/extract".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute, data: post.data as Response }
}
