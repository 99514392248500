import { useQuery } from "react-query"
import queryString from "query-string"
export interface TimelineResponse {
  timeline: Step[]
  activeStep: number
}

export type Step = {
  label: string
  date: string
  completed: boolean
  metadata?: any
}

export function useTradeReferenceTimeline(
  tradeReferenceId?: string,
  applicationId?: string,
) {
  return useQuery<TimelineResponse>(
    queryString.stringifyUrl({
      url: "/application/base/trade_reference_timeline",
      query: {
        trade_reference_id: tradeReferenceId,
        application_id: applicationId,
      },
    }),
    {
      enabled: !!tradeReferenceId && !!applicationId,
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
