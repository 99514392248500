import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Box, Button, useMediaQuery, Typography, useTheme } from "@mui/material"
import { cli } from "cypress"

type Props = {
  handleBack: (() => void) | undefined
  activeStep: number | undefined
  onContinue?: (onFormValidationError: () => void) => void
  disable?: boolean
  loading?: boolean
  setLoading?: (a: boolean) => void
  click?: () => void
  continueText?: string
  arrow?: React.ReactNode
  isLongName?: boolean
}

export default function MobileNavButtonsBlock({
  handleBack,
  activeStep,
  disable,
  loading,
  click,
  continueText,
  arrow,
  isLongName = false,
}: Props) {
  const matches = useMediaQuery("(max-width: 900px)")
  const mobileMatches = useMediaQuery("(max-width: 553px)")

  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "5%",
        }}
      >
        {matches && (
          <Button
            id="back"
            onClick={handleBack}
            sx={{
              mt: 3,
              mb: 2,
              width: isLongName ? (mobileMatches ? "auto" : "100%") : "100%",
              color: "#344054",
              borderColor: "#344054",
              textTransform: "none",
              padding: "8px 10px",
              "&:active": {
                backgroundColor: "#34405455",
              },
              "&:hover": {
                backgroundColor: "#34405455",
              },
            }}
            disabled={
              activeStep === 0 || activeStep === 99 || activeStep === -1
            }
            variant="outlined"
          >
            <Typography variant="h4">Back</Typography>
          </Button>
        )}

        <LoadingButton
          id="user-continue"
          disabled={disable}
          loading={loading}
          onClick={click}
          size="large"
          endIcon={(!matches || arrow) && arrow}
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            width: matches
              ? isLongName
                ? mobileMatches
                  ? "auto"
                  : "100%"
                : "100%"
              : "100%",
            backgroundColor: theme.palette.primary.main,
            color: "primary",
            textTransform: "none",
            padding: "8px 10px",
            "&:active": {
              backgroundColor: "#34405455",
            },
            "&:hover": {
              backgroundColor: "#34405455",
            },
          }}
        >
          <Typography
            variant={matches ? "h4" : "inherit"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {continueText ? continueText : "Save & Continue"}
          </Typography>
        </LoadingButton>
      </Box>
    </>
  )
}
