import * as React from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import QontoStepper from "../sections/@dashboard/components/QontoStepper"
import LoadingButton from "@mui/lab/LoadingButton"
import { AddressAutofill } from "@mapbox/search-js-react"
import { useFormik } from "formik"
import {
  Autocomplete,
  Backdrop,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material"

import { useLocation, useParams } from "react-router-dom"
import DebouncedAutocomplete from "../components/autocomplete/DebouncedAutocomplete"
import { useBusinessSearch } from "../queries/vendors/useBusinessSearch"
import { DropzoneDialog } from "mui-file-dropzone"
import { confirm } from "../components/confirm"
import * as yup from "yup"
import {
  ArrowForward,
  Close,
  EmailOutlined,
  PageviewOutlined,
} from "@mui/icons-material"
import { CreditApplication, UploadedFile } from "../types"
import { usePostGenerateAnonymousApplication } from "../queries/credit/usePostGenerateAnonymousApplication"
import { useAnonymousBusiness } from "../queries/credit/useAnonymousBusiness"
import { AxiosError } from "axios"
import { usePostCompleteApplication } from "src/queries/credit/usePostCompleteApplication"
import { info, error } from "src/utils/logger"
// import { sign } from "crypto"

// function Copyright(props: any) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://netnow.io/">
//         NetNow Financial Inc.
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// const theme = createTheme();

const STEPS = [
  {
    label: "Your Details",
    required: true,
    enabled: true,
    description: "",
    config: [],
  },
  {
    label: "Business Details",
    required: true,
    enabled: true,
    description: "",
    config: [],
  },
  {
    label: "Consent Forms",
    enabled: true,
    description: "",
    config: [],
    required: false,
  },
]

// for mapbox
const accessToken =
  "pk.eyJ1Ijoic29yb3VzaG5ldG5vdyIsImEiOiJjbGVzeGFkbDgwMGFvM3FsNDNnZjM4dWUzIn0.w391C51Mlz8fxYoDeNMmLQ"

export default () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const params = useParams()

  const { id } = params

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const consentResult = queryParams.get("result") || ""
  const businessId = queryParams.get("business_id") || ""

  const { data: sellerData } = useAnonymousBusiness(businessId)

  const { execute: generateApplication, isLoading } =
    usePostGenerateAnonymousApplication((data: CreditApplication) => {
      if (data.data["signingUrl"]) {
        window.location.href = data.data["signingUrl"]
      } else {
        setActiveStep(STEPS.length)
      }
    })

  const [open, setOpen] = React.useState(false)

  const { execute: completeApplication } = usePostCompleteApplication(() => {
    info("patched")
  })

  const validationSchema = yup.object({
    data: yup.object().shape({
      legalBusinessName: yup
        .string()
        .required("Legal business name is required"),
      businessDba: yup.string().required("Operating business name is required"),
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .email("Email is required")
        .required("Email is required"),
      apEmail: yup
        .string()
        .email("Email is required")
        .required("Email is required"),
      apPhoneNumber: yup.string().required("AP phone number is required"),
      businessType: yup.string().required("Business type is required"),
      address: yup.string().required("Address is required"),
      city: yup.string().required("City is required"),
      region: yup
        .string()
        .oneOf([
          "Ontario",
          "Quebec",
          "British Columbia",
          "Alberta",
          "Manitoba",
          "Saskatchewan",
          "Nova Scotia",
          "New Brunswick",
          "Newfoundland and Labrador",
          "Prince Edward Island",
          "Northwest Territories",
          "Nunavut",
          "Yukon",
        ]),
      categoriesInterested: yup.array().test({
        message: "Please select at least one product category",
        test: (arr) => {
          return arr !== undefined && arr.length !== 0
        },
      }),
      postCode: yup.string().required("Post code is required"),
      hstNumber: yup.string().required("HST number is required"),
      qstNumber: yup.string().when(["region"], (region) => {
        return region === "Quebec"
          ? yup.string().required("QST number is required")
          : yup.string().notRequired()
      }),
    }),
    stage: yup.mixed().oneOf([1, 200]),
    files: yup.array().test({
      message: "Please upload the required files",
      test: (arr) => {
        return arr !== undefined && arr.length !== 0
      },
    }),
  })

  const formik = useFormik({
    initialValues: {
      data: {
        seller: businessId,
      },
      stage: 1,
      files: new Array<File | UploadedFile>(),
    },
    validationSchema,
    onSubmit: (values: CreditApplication) => {
      generateApplication(values)
    },
  })

  // was consent form signed
  React.useEffect(() => {
    if (["complete", "success"].includes(consentResult)) {
      markComplete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentResult])

  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false)

  const theme = useTheme()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // used to show a blocking error if completion fails
  const [completionError, setCompletionError] = React.useState<
    AxiosError | undefined
  >(undefined)

  const markComplete = () => {
    if (id) {
      completeApplication(
        id,
        () => {
          setActiveStep(STEPS.length)
        },
        (error: AxiosError) => {
          setCompletionError(error)
        },
      )
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleFileUpload = (files: Array<File>) => {
    setUploadDialogOpen(false)
    const values = formik.values
    if (!values.files) {
      values.files = new Array<File | UploadedFile>()
    }
    const newFiles = values.files.concat(files)
    values.files = newFiles
    formik.setFieldValue("files", files)
    // patchApplication(id, values)
  }

  if (!sellerData) {
    return <></>
  }

  const getUser = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          margin: "120px",
        }}
      >
        <Box
          style={{ margin: "0 128px 32px 128px" }}
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            large: "block",
            xlarge: "block",
          }}
        >
          <QontoStepper
            steps={STEPS}
            data={formik.values.data}
            activeStep={activeStep}
            handleBack={handleBack}
          />
        </Box>
        <Box
          style={{
            textAlign: "left",
            padding: "32px 128px 32px 128px",
            border: "solid",
            borderWidth: "1px",
            borderRadius: "16px",
            borderColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h6">Your Details</Typography>
          <br />
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="First Name"
                type="text"
                value={formik.values.data.firstName}
                onChange={(event) => {
                  const data = formik.values.data
                  data.firstName = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.firstName,
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Last Name"
                type="text"
                value={formik.values.data.lastName}
                onChange={(event) => {
                  const data = formik.values.data
                  data.lastName = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.lastName,
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Email"
                type="email"
                value={formik.values.data.email}
                onChange={(event) => {
                  const data = formik.values.data
                  data.email = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(formik.errors.data && formik.errors.data.email)}
              />
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
              <LoadingButton
                onClick={() => {
                  formik
                    .validateForm()
                    .then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        handleNext()
                      } else {
                        info(errors)
                        if (errors.data) {
                          const data = errors.data
                          if (data.firstName) {
                            return
                          } else if (data.lastName) {
                            return
                          } else if (data.email) {
                            return
                          }
                        }
                        formik.setErrors({})
                        handleNext()
                      }
                      return
                    })
                    .catch((err) => {
                      error(err)
                    })
                }}
                size="large"
                fullWidth
                endIcon={<ArrowForward />}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Continue
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Box>
    )
  }

  const getComplete = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          margin: "120px",
        }}
      >
        <Box
          style={{ margin: "0 128px 32px 128px" }}
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            large: "block",
            xlarge: "block",
          }}
        >
          <QontoStepper
            steps={STEPS}
            data={formik.values.data}
            activeStep={activeStep}
            handleBack={handleBack}
          />
        </Box>
        <Box
          style={{
            textAlign: "left",
            padding: "32px",
            border: "solid",
            borderWidth: "1px",
            borderRadius: "16px",
            borderColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h4">🎉 Success! 🎉</Typography>
          <br />
          <Typography variant="body1">
            You have successfully completed your trade credit application.
          </Typography>
          <br />
          <Typography>Here is what to expect:</Typography>
          <List>
            <ListItem>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <PageviewOutlined />
                <ListItemText
                  style={{ flex: "11 11 auto" }}
                  primary={`${sellerData?.name} will review your application and contact you.`}
                />
              </Box>
            </ListItem>
            <ListItem>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <EmailOutlined />
                <ListItemText
                  style={{ flex: "11 11 auto" }}
                  primary={
                    "You will receive an email from us if the status of your application changes."
                  }
                />
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>
    )
  }

  const getCompany = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          margin: "120px",
        }}
      >
        <Box
          style={{ margin: "0 128px 32px 128px" }}
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            large: "block",
            xlarge: "block",
          }}
        >
          <QontoStepper
            steps={STEPS}
            data={formik.values.data}
            activeStep={activeStep}
            handleBack={handleBack}
          />
        </Box>
        <Box
          style={{
            textAlign: "left",
            padding: "32px 128px 32px 128px",
            border: "solid",
            borderWidth: "1px",
            borderRadius: "16px",
            borderColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h6">Business Details</Typography>
          <br />
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <DebouncedAutocomplete
                id="legal-business-name"
                useSuggestions={useBusinessSearch}
                style={{ width: "100%" }}
                placeholder="Legal Business Name"
                required
                value={formik.values.data.legalBusinessName}
                onChange={(val) => {
                  const data = formik.values.data
                  data.legalBusinessName = val
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.legalBusinessName,
                )}
                // helperText={
                //   formik.errors.data &&
                //   (formik.errors.data.legalBusinessName as string)
                // }
              />
              <TextField
                required
                margin="normal"
                fullWidth
                label="Business Operating Name"
                type="text"
                value={formik.values.data.businessDba}
                onChange={(event) => {
                  const data = formik.values.data
                  data.businessDba = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.businessDba,
                )}
                // helperText={
                //   formik.errors.data &&
                //   (formik.errors.data.businessDba as string)
                // }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
              }}
            >
              <FormControl fullWidth margin="normal">
                <InputLabel
                  id="business-type"
                  error={Boolean(
                    formik.errors.data && formik.errors.data.businessType,
                  )}
                >
                  Type of Business
                </InputLabel>
                <Select
                  labelId="business-type-label"
                  id="business-type-select"
                  label="Type of Business"
                  value={formik.values.data.businessType}
                  onChange={(event) => {
                    const data = formik.values.data
                    data.businessType = event.target.value
                    formik.setFieldValue("data", data, false)
                  }}
                  sx={{ height: "33px" }}
                  error={Boolean(
                    formik.errors.data && formik.errors.data.businessType,
                  )}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  <MenuItem value={"Vape Shop"}>Vape Shop</MenuItem>
                  <MenuItem value={"Head Shop"}>Head Shop</MenuItem>
                  <MenuItem value={"Tobacco/Tagabie/Hookah"}>
                    Tobacco/Tagabie/Hookah
                  </MenuItem>
                  <MenuItem value={"Convenience Store"}>
                    Convenience Store
                  </MenuItem>
                  <MenuItem value={"Supermarket/Grocery Store"}>
                    Supermarket/Grocery Store
                  </MenuItem>
                  <MenuItem value={"Petrol/Gas Service Station"}>
                    Petrol/Gas Service Station
                  </MenuItem>
                  <MenuItem value={"Dispensary"}>Dispensary</MenuItem>
                  <MenuItem value={"Sub-Distributor"}>Sub-Distributor</MenuItem>
                  <MenuItem value={"Wholesaler"}>Wholesaler</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
                {/* {formik.errors.data && formik.errors.data.businessType && (
                  <FormHelperText error>
                    {formik.errors.data.businessType as string}
                  </FormHelperText>
                )} */}
              </FormControl>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Accounts Payable Email"
                type="text"
                value={formik.values.data.apEmail}
                error={Boolean(
                  formik.errors.data && formik.errors.data.apEmail,
                )}
                onChange={(event) => {
                  const data = formik.values.data
                  data.apEmail = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
                alignItems: "center",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Accounts Payable Phone Number"
                type="text"
                value={formik.values.data.apPhoneNumber}
                onChange={(event) => {
                  const data = formik.values.data
                  data.apPhoneNumber = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.apPhoneNumber,
                )}
              />
              <FormControl fullWidth size="small" margin="dense">
                <InputLabel id="referrer">
                  Have you been referred by a sales rep?
                </InputLabel>
                <Select
                  labelId="referrer-label"
                  id="referrer-select"
                  label="Have you been referred by a sales rep?"
                  value={formik.values.data.referrer}
                  onChange={(event) => {
                    const data = formik.values.data
                    data.referrer = event.target.value
                    formik.setFieldValue("data", data, false)
                  }}
                  sx={{ height: "33px" }}
                >
                  <MenuItem disabled value={undefined}>
                    Select one
                  </MenuItem>
                  <MenuItem value={"No"}>No</MenuItem>
                  <MenuItem value={"Hussein"}>Yes, Hussein</MenuItem>
                  <MenuItem value={"Faizan"}>Yes, Faizan</MenuItem>
                  <MenuItem value={"Sam"}>Yes, Sam</MenuItem>
                  <MenuItem value={"Franco"}>Yes, Franco</MenuItem>
                  <MenuItem value={"Julie"}>Yes, Julie</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <div style={{ width: "100%" }}>
                <AddressAutofill
                  options={{
                    language: "en",
                    country: "CA",
                  }}
                  accessToken={accessToken}
                  onRetrieve={(res) => {
                    const data = formik.values.data
                    data.address =
                      res.features[0].properties.feature_name.trim()
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data.city = res.features[0].properties.place.trim()
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data.region = res.features[0].properties.region.trim()
                    data.postCode = res.features[0].properties.postcode
                    formik.setFieldValue("data", data, false)
                  }}
                  onChange={(val) => {
                    const data = formik.values.data
                    if (data.address !== val) {
                      data.address = val
                      formik.setFieldValue("data", data, false)
                    }
                  }}
                >
                  <TextField
                    autoComplete="shipping address-line1"
                    required
                    margin="normal"
                    fullWidth
                    name="address"
                    label="Address"
                    type="text"
                    defaultValue={formik.values.data.address}
                    error={Boolean(
                      formik.errors.data && formik.errors.data.address,
                    )}
                  />
                </AddressAutofill>
              </div>
              <TextField
                autoComplete="shipping address-line2"
                margin="normal"
                fullWidth
                name="address2"
                label="Address Line 2"
                type="text"
                value={formik.values.data.addressLine2}
                onChange={(event) => {
                  const data = formik.values.data
                  data.addressLine2 = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
              />
            </Box>
            {formik.values.data.address && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "32px",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  label="City"
                  type="text"
                  value={formik.values.data.city}
                  onChange={(event) => {
                    const data = formik.values.data
                    data.city = event.target.value
                    formik.setFieldValue("data", data, false)
                  }}
                  error={Boolean(formik.errors.data && formik.errors.data.city)}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="region-label">Region</InputLabel>
                  <Select
                    labelId="region-label"
                    id="region-select"
                    value={formik.values.data.region || ""}
                    defaultValue={formik.values.data.region}
                    onChange={(event) => {
                      const data = formik.values.data
                      data.region = event.target.value
                      formik.setFieldValue("data", data, false)
                    }}
                    label="Region"
                    sx={{ height: "33px" }}
                    error={Boolean(
                      formik.errors.data && formik.errors.data.region,
                    )}
                  >
                    <MenuItem value={""} disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={"Ontario"}>ON</MenuItem>
                    <MenuItem value={"Quebec"}>QC</MenuItem>
                    <MenuItem value={"British Columbia"}>BC</MenuItem>
                    <MenuItem value={"Nova Scotia"}>NS</MenuItem>
                    <MenuItem value={"New Brunswick"}>NB</MenuItem>
                    <MenuItem value={"Manitoba"}>MB</MenuItem>
                    <MenuItem value={"Saskatchewan"}>SK</MenuItem>
                    <MenuItem value={"Alberta"}>AB</MenuItem>
                    <MenuItem value={"Newfoundland and Labrador"}>NL</MenuItem>
                    <MenuItem value={"Prince Edward Island"}>PEI</MenuItem>
                    <MenuItem value={"Yukon"}>YT</MenuItem>
                    <MenuItem value={"Northwest Territories"}>NT</MenuItem>
                    <MenuItem value={"Nunavut"}>NU</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Postal Code"
                  type="text"
                  value={formik.values.data.postCode}
                  onChange={(event) => {
                    const data = formik.values.data
                    data.postCode = event.target.value
                    formik.setFieldValue("data", data, false)
                  }}
                  error={Boolean(
                    formik.errors.data && formik.errors.data.postCode,
                  )}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Alcohol Permit Number (if applicable)"
                type="text"
                value={formik.values.data.alcoholPermitNumber}
                onChange={(event) => {
                  const data = formik.values.data
                  data.alcoholPermitNumber = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="GST/HST Number"
                type="text"
                value={formik.values.data.hstNumber}
                onChange={(event) => {
                  const data = formik.values.data
                  data.hstNumber = event.target.value
                  formik.setFieldValue("data", data, false)
                }}
                error={Boolean(
                  formik.errors.data && formik.errors.data.hstNumber,
                )}
              />
              {["QC", "qc", "Qc", "Quebec", "quebec"].includes(
                formik.values.data.region,
              ) && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="QST Number"
                  type="text"
                  value={formik.values.data.qstNumber}
                  onChange={(event) => {
                    const data = formik.values.data
                    data.qstNumber = event.target.value
                    formik.setFieldValue("data", data, false)
                  }}
                  error={Boolean(
                    formik.errors.data && formik.errors.data.qstNumber,
                  )}
                />
              )}
            </Box>

            <Autocomplete
              fullWidth
              id="product-category-search"
              multiple
              sx={{
                width: "100%",
                marginTop: "16px",
                height: "50px",
              }}
              options={[
                "Vaporizers",
                "Smoking Accessories",
                "Convenience Store Items",
                "Wines",
                "Other",
              ]}
              defaultValue={[]}
              value={formik.values.data.categoriesInterested}
              onChange={(_, value) => {
                const data = formik.values.data
                data.categoriesInterested = value
                formik.setFieldValue("data", data, false)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  size="small"
                  label="Select the product categories you are interested in"
                  error={Boolean(
                    formik.errors.data &&
                      formik.errors.data.categoriesInterested,
                  )}
                />
              )}
            />

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "32px",
                marginTop: "16px",
                alignItems: "center",
              }}
            >
              <Typography>
                Please Upload Business Licenses (GST/QST License/Business
                License/Tobacco License/Vape License)
              </Typography>
              <Button
                variant="contained"
                onClick={() => setUploadDialogOpen(true)}
              >
                Upload
              </Button>
            </Box>
            {formik.errors && formik.errors.files && (
              <Typography color="error">
                {formik.errors.files as string}
              </Typography>
            )}
            {formik.values.files &&
              formik.values.files.length > 0 &&
              formik.values.files.map((file) => {
                return (
                  <Chip
                    key={file.name}
                    label={
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        {file.name}
                        <Close />
                      </Box>
                    }
                    color="primary"
                    onClick={() => {
                      confirm(
                        "You are about to remove this file from your saved application?",
                      )
                        .then(
                          () => {
                            const oldValues = formik.values
                            oldValues.files = formik.values.files
                              ? formik.values.files.filter((f) => f !== file)
                              : []
                            // eslint-disable-next-line promise/catch-or-return
                            formik.setFieldValue("files", oldValues.files, true)
                            return
                          },
                          () => {
                            info("cancelled")
                          },
                        )
                        .catch(() => {
                          info("cancelled")
                        })
                    }}
                  />
                )
              })}

            {uploadDialogOpen && (
              <DropzoneDialog
                open={uploadDialogOpen}
                onSave={(newFiles) => {
                  handleFileUpload(newFiles)
                }}
                acceptedFiles={[
                  // "image/jpeg",
                  // "image/png",
                  // "image/bmp",
                  "application/pdf",
                ]}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={() => {
                  setUploadDialogOpen(false)
                }}
                filesLimit={5}
                fileObjects={null}
                dropzoneText={
                  "Upload Business Licenses (GST/QST License/Business License/Tobacco License/Vape License)"
                }
              />
            )}
            <Box style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
              <LoadingButton
                onClick={() => {
                  formik
                    .validateForm()
                    .then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        handleClickOpen()
                      }
                      error(errors)
                      return
                    })
                    .catch((err) => {
                      error(err)
                    })
                }}
                size="large"
                fullWidth
                endIcon={<ArrowForward />}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Continue
              </LoadingButton>
            </Box>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm payment method"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you planning on using a credit card to pay for your
                  orders? If so, you will be required to sign a Credit Card
                  Authorization form.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  loading={isLoading}
                  onClick={() => {
                    handleClose()
                    formik.submitForm()
                  }}
                  variant="contained"
                >
                  No, I will not use a credit card
                </LoadingButton>
                <LoadingButton
                  loading={isLoading}
                  onClick={() => {
                    handleClose()
                    const values = formik.values
                    values.data.creditCardAuthorization = true
                    formik
                      .setFieldValue("data", values.data, true)
                      .then(() => {
                        return formik.submitForm()
                      })
                      .catch((err) => {
                        error("error", err)
                      })
                  }}
                  autoFocus
                  variant="contained"
                >
                  Yes, I would like to sign the form
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      </Box>
    )
  }

  return (
    <>
      {completionError && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Box style={{ display: "flex-column", alignItems: "center" }}>
            <Typography color="white">
              An error occurred while completing your application. Please
              refresh this page and if the error persists, contact
              info@netnow.io with the following information:
            </Typography>
            <Typography color="error">{completionError.message}</Typography>
            <Typography color="error">Application ID: {id}</Typography>
            <Typography color="error">
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                completionError.response?.data?.error
              }
            </Typography>
          </Box>
        </Backdrop>
      )}
      {activeStep == 0 && getUser()}
      {activeStep == 1 && getCompany()}
      {activeStep == 3 && getComplete()}
    </>
  )
}
