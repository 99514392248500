import { useQuery } from "react-query"
import { ClientExperianCredentials } from "../../types"

export function useListInternalExperianCredentials(id: string) {
  return useQuery<ClientExperianCredentials>(
    "/internal/experian?seller=" + id,
    {
      retry: (failureCount, error) => {
        if (failureCount < 1) {
          return true
        }
        // do not retry on 404
        return (error as any)?.response?.status !== 404
      },
    },
  )
}
