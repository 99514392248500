import { Paper, Typography, Grid } from "@mui/material"
import { FileSummary } from "src/types/vendors.types"
import { format } from "date-fns"
import { startCase } from "lodash"

interface FileSummarySectionProps {
  fileSummary: FileSummary
}

export const FileSummarySection = ({
  fileSummary,
}: FileSummarySectionProps) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        File Summary
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">File Hit Indicator</Typography>
          <Typography>{startCase(fileSummary.fileHitIndicator)}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">SSN Match Indicator</Typography>
          <Typography>{startCase(fileSummary.ssnMatchIndicator)}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Consumer Statement</Typography>
          <Typography>
            {startCase(fileSummary.consumerStatementIndicator)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Market/Submarket</Typography>
          <Typography>
            {fileSummary.market}/{fileSummary.submarket}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Credit Data Status</Typography>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <Typography>
                Suppressed: {startCase(fileSummary.creditDataStatus.suppressed)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>
                Do Not Promote:{" "}
                {startCase(fileSummary.creditDataStatus.doNotPromote.indicator)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>
                Freeze:{" "}
                {startCase(fileSummary.creditDataStatus.freeze.indicator)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>
                Minor: {startCase(fileSummary.creditDataStatus.minor)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">In File Since</Typography>
          <Typography>
            {format(
              new Date(fileSummary.inFileSinceDate["#text"]),
              "MMMM d, yyyy",
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
