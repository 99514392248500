import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { ApplicationTemplate, ApplicationTemplateNotes } from "src/types"

export function usePatchCashApplicationTemplate(onSuccess?: () => void) {
  const patch = useTypedMutation<
    ApplicationTemplateNotes | ApplicationTemplate
  >("patch/internal/cash/application-template", onSuccess)
  const execute = (
    body: ApplicationTemplateNotes | ApplicationTemplate,
    onSuccess: () => void,
  ) => {
    const id = body.id
    delete body.id

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/cash/application-template/" + id + "/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [
              "/internal/cash/application-template".replaceAll("/", ""),
            ],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
