// @mui
import Container from "@mui/material/Container"
import Grid from "@mui/material/Unstable_Grid2"

// components
import { useParams } from "../../../../routes/hook"
//
import { useCreditApplication } from "../../../../queries/credit/useCreditApplication"
import CreditApplicationSalesDetailsToolbar from "../components/sales-view/CreditApplicationSalesDetailsToolbar"
import CreditApplicationSalesInfo from "../components/sales-view/CreditApplicationSalesInfo"
import { CircularProgress } from "@mui/material"
// ----------------------------------------------------------------------

export default function CreditApplicationSalesDetailsView() {
  const params = useParams()

  const { id } = params

  const { data } = useCreditApplication(id)

  if (!data) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  return (
    <Container maxWidth={false}>
      <CreditApplicationSalesDetailsToolbar application={data} />
      <Grid container spacing={3}>
        <Grid xs={12}>
          <CreditApplicationSalesInfo applicationId={data.id as string} />
        </Grid>
      </Grid>
    </Container>
  )
}
