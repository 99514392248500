import { QueryClient } from "react-query"
import { AdditionalDataRequest, ApplicationTemplate } from "src/types"
import { useTypedMutation } from "../../useTypedMutation"

export function usePostAdditionalDataRequest(
  onSuccess?: (data: AdditionalDataRequest) => void,
) {
  const ENDPOINT = "/application/base/data_request/"
  const post = useTypedMutation<FormData>(
    "post/application/data_request",
    onSuccess,
  )

  const execute = (
    application: string,
    pages: ApplicationTemplate["formTemplate"]["pages"],
    instructions: string,
    requestSignature: boolean,
    recipient: string,
    recipientFirst: string,
    recipientLast: string,
    file: File,
    onSuccess?: () => void,
  ) => {
    const formData = new FormData()
    formData.append("file0", file)
    formData.append("application", application)
    formData.append("pages", JSON.stringify(pages))
    formData.append("instructions", instructions)
    formData.append("recipient", recipient)
    formData.append("recipientFirst", recipientFirst)
    formData.append("recipientLast", recipientLast)
    formData.append("requestSignature", requestSignature.toString())

    post.mutate(
      {
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }

  return { ...post, execute }
}
