import * as React from "react"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"
import CardHeader from "@mui/material/CardHeader"
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CancelOutlined,
  ClearOutlined,
  SearchOutlined,
} from "@mui/icons-material"
import SearchRow from "src/components/search-row"
import {
  IconButton,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { CustomDocument } from "src/types"
import { on } from "events"

function not(
  a: Array<{ id: string; name: string }>,
  b: Array<{ id: string; name: string }>,
) {
  return a.filter((value) => !b.includes(value))
}

function intersection(
  a: Array<{ id: string; name: string }>,
  b: Array<{ id: string; name: string }>,
) {
  return a.filter((value) => b.includes(value))
}

export default function TransferList({
  options,
  onSelectedChanged,
}: {
  options: Array<{ id: string; name: string }>
  onSelectedChanged: (selectedOptions: string[]) => void
}) {
  const [checked, setChecked] = React.useState<
    Array<{ id: string; name: string }>
  >([])
  const [left, setLeft] =
    React.useState<Array<{ id: string; name: string }>>(options)
  const [right, setRight] = React.useState<Array<{ id: string; name: string }>>(
    [],
  )

  const theme = useTheme()

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: { id: string; name: string }) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  React.useEffect(() => {
    onSelectedChanged(right.map((o) => o.id))
  }, [right, onSelectedChanged])

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
    setChecked([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
    setChecked([])
  }

  const [search, setSearch] = React.useState("")

  const customList = (
    items: Array<{ id: string; name: string }>,
    searchEnabled = true,
  ) => (
    <Card
      style={{
        width: "30vw",
      }}
    >
      <List
        dense
        component="div"
        role="list"
        sx={{
          width: "100%",
          maxWidth: "30vw",
          position: "relative",
          overflow: "auto",
          maxHeight: 300,
          height: "100vh",
          "& ul": { padding: 0 },
        }}
      >
        <li key={"section-header"}>
          <ul>
            <ListSubheader>
              {searchEnabled && (
                <TextField
                  fullWidth
                  placeholder="Search"
                  InputProps={{
                    startAdornment: <SearchOutlined />,
                    endAdornment: (
                      <IconButton onClick={() => setSearch("")}>
                        <ClearOutlined />
                      </IconButton>
                    ),
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </ListSubheader>
            {items.map((value: { id: string; name: string }) => {
              const labelId = `transfer-list-item-${value.id}-label`

              return (
                <ListItemButton
                  key={value.id}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.includes(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
              )
            })}
            {(!items || items.length === 0) && (
              <ListItemButton>
                <ListItemText primary="No items" />
              </ListItemButton>
            )}
          </ul>
        </li>
      </List>
    </Card>
  )

  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        padding: "0 8px 16px 8px",
        margin: "8px 0 28px 0",
        border: "solid",
        borderWidth: "2px 15px 2px 15px",
        borderRadius: "8px",
        borderColor: theme.palette.primary.main,
      }}
    >
      <Grid item>
        <Typography style={{ margin: "8px" }}>Available Items</Typography>
        {customList(left.filter((o) => o.name.includes(search)))}
      </Grid>
      <Grid item>
        <Grid container direction="column" sx={{ alignItems: "center" }}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            All ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            {leftChecked.length} selected <ArrowRightOutlined />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ArrowLeftOutlined /> {rightChecked.length} selected
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪ All
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography style={{ margin: "8px" }}>Selected Items</Typography>
        {customList(right, false)}
      </Grid>
    </Grid>
  )
}
