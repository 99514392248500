import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import {  ClientCreditRiskMonitorCredentials } from "src/types"

export function useDeleteInternalCreditRiskMonitorCredentials(
  onSuccess?: () => void,
) {
  const ENDPOINT = "/internal/credit-risk-monitor/"
  const d = useTypedMutation<ClientCreditRiskMonitorCredentials>(
    "/internal/credit-risk-monitor/",
    onSuccess,
  )

  const execute = (body: ClientCreditRiskMonitorCredentials) => {
    d.mutate(
      {
        method: "delete",
        endpoint: ENDPOINT + body.id + "/",
        body: body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...d, execute }
}
