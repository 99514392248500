import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { Page } from "src/types"

export function usePatchCreditApplicationTemplatePage(
  applicationTemplateId: string,
  onSuccess?: () => void,
) {
  const patch = useTypedMutation<Page>(
    "patch/internal/credit/application-template/page",
    onSuccess,
  )
  const execute = (body: Page, onSuccess: () => void) => {
    patch.mutate(
      {
        method: "patch",
        endpoint:
          "/internal/credit/application-template/page/" +
          applicationTemplateId +
          "/",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [
              "/internal/credit/application-template/page".replaceAll("/", ""),
            ],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
