import PDFViewerDialog from "src/components/pdf/PDFViewerDialog"
import { useCreditRiskMonitorPDF } from "src/queries/vendors/useCreditRiskMonitorSearch"
import { CreditApplication, CreditRiskMonitorBusiness } from "src/types"

type Props = {
  application: CreditApplication
  creditRiskMonitorBusiness: CreditRiskMonitorBusiness
  onClose: () => void
}

export default ({ application, creditRiskMonitorBusiness, onClose }: Props) => {
  const { data: report } = useCreditRiskMonitorPDF(
    application.id as string,
    application.seller?.id as string,
    creditRiskMonitorBusiness.businessId,
    creditRiskMonitorBusiness.businessName,
    creditRiskMonitorBusiness.address,
    creditRiskMonitorBusiness.cityName,
    creditRiskMonitorBusiness.provinceName,
    creditRiskMonitorBusiness.countryName,
  )
  return (
    <>
      {report && (
        <PDFViewerDialog
          title="Credit Report"
          fileUrl={report.url}
          onClose={() => {
            onClose()
          }}
        />
      )}
    </>
  )
}
