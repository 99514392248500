import { QueryClient } from "react-query"
import { AddPersonalGuarantor } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

export function useAddPersonalGuarantor(onSuccess?: () => void) {
  const ENDPOINT = "/application/"
  const post = useTypedMutation<AddPersonalGuarantor>(
    "post/application",
    onSuccess,
  )

  const execute = (
    applicationId: string,
    body: Partial<AddPersonalGuarantor>,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    post.mutate(
      {
        endpoint: ENDPOINT + applicationId + "/personal_guaranty",
        body,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...post, execute }
}
