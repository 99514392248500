import { QueryClient } from "react-query"
import { CreditApplication, TemplateCustomField } from "../../types"
import { useTypedMutation } from "../useTypedMutation"

const getFormData = (body: Partial<CreditApplication>) => {
  const formData = new FormData()
  for (const item of Object.keys(body)) {
    if (item === "files" && body["files"]) {
      body["files"].forEach((file, index) => {
        if (file instanceof File) {
          formData.append("file" + index, file as File)
        } else {
          formData.append("file" + index, JSON.stringify(file))
        }
      })
    } else if (item.includes("data")) {
      const data = body["data"]
      if (data) {
        if (data["customFields"]) {
          const customFields = data[
            "customFields"
          ] as Array<TemplateCustomField>
          Object.entries(customFields).forEach(([key, value]) => {
            if (value.fieldType === "file") {
              formData.append(key, value.file as File)
            } else {
              formData.append(key, value.response || "")
            }
          })
        }
        delete data["customFields"]
        formData.append(item, JSON.stringify(data))
      }
    } else if (item.includes("Date")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append(
        item,
        new Date(body[item as keyof CreditApplication] as Date).toISOString(),
      )
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append(item, body[item as keyof CreditApplication])
    }
  }
  return formData
}
// to be used for generating apps based on the base template
export function usePostGenerateBaseApplication(
  onSuccess?: (data: CreditApplication) => void,
) {
  const ANONYMOUS_ENDPOINT = "/application/generate"
  const ENDPOINT = "/application/base/generate"
  const postGenerateApplication = useTypedMutation<FormData>(
    "post/application/base/generate",
    onSuccess,
  )

  const execute = (body: Partial<CreditApplication>, anonymous = false) => {
    postGenerateApplication.mutate(
      {
        method: "post",
        endpoint: anonymous ? ANONYMOUS_ENDPOINT : ENDPOINT,
        body: getFormData(body),
        headers: {
          "Content-Type": "multipart/form-data",
        },
        anonymous,
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...postGenerateApplication, execute }
}

export function usePostGenerateBaseApplicationNoRedirect(
  onSuccess?: (data: CreditApplication) => void,
) {
  const ENDPOINT = "/application/base/generate"
  const postGenerateApplication = useTypedMutation<FormData>(
    "post/application/base/generate",
    onSuccess,
  )

  const execute = (body: Partial<CreditApplication>) => {
    const formData = getFormData(body)
    formData.append("redirect", "false")
    postGenerateApplication.mutate(
      {
        method: "post",
        endpoint: ENDPOINT,
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
        },
      },
    )
  }
  return { ...postGenerateApplication, execute }
}
