import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalCashApplicationTemplate } from "src/queries/internal/useCreateInternalCashApplicationTemplate"
import { CreateApplicationTemplate } from "src/types"
import { confirm } from "src/components/confirm/"
import { useCashApplicationTemplate } from "src/queries/cod/useCashApplicationTemplate"
import EditCashEmailNotes from "./components/EditCashEmailNotes"
import EditCashApplicationTemplate from "./components/EditCashApplicationTemplate"
import CashUrlPopulation from "./components/ApplicationTemplate/CashUrlPopulation"
import CashApplicationsCard from "./components/ApplicationTemplate/CashApplicationsCard"
import { ExpandMore } from "@mui/icons-material"

export default function ClientCashDetails({ id }: { id: string }) {
  const {
    execute: executeCreateCashApplication,
    isLoading: isLoadingCreateTemplate,
  } = useCreateInternalCashApplicationTemplate(() => {
    enqueueSnackbar("Cash Application Template Created", {
      variant: "success",
    })
  })
  const {
    data,
    refetch,
    isLoading: isFetchingTemplate,
  } = useCashApplicationTemplate(id)
  return (
    <Container sx={{ p: 1 }}>
      {isFetchingTemplate && "Loading..."}
      {!isFetchingTemplate && !data && (
        <>
          <Stack sx={{ p: 1 }}>
            <Button
              variant="contained"
              disabled={isLoadingCreateTemplate}
              onClick={() => {
                confirm(
                  "Are you Sure you want to Activate Cash Applications For This Account?",
                )
                  .then(
                    () => {
                      executeCreateCashApplication(
                        { seller: id } as CreateApplicationTemplate,
                        () => {
                          refetch()
                        },
                      )
                      return
                    },
                    () => {
                      console.log("error")
                    },
                  )
                  .catch(() => {
                    console.log("error")
                  })
              }}
            >
              Activate Cash Application
            </Button>
          </Stack>
        </>
      )}
      {data && (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="user-content"
              id="user-header"
            >
              <Typography variant="subtitle2">View Cash Application</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CashApplicationsCard id={id as string} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="user-content"
              id="user-header"
            >
              <Typography variant="subtitle2">Cash Application URL</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CashUrlPopulation id={id as string} />
            </AccordionDetails>
          </Accordion>
          <EditCashApplicationTemplate
            applicationTemplate={data}
            refetch={refetch}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="notes-content"
              id="notes-header"
            >
              <Typography variant="subtitle2">Notes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EditCashEmailNotes
                applicationTemplate={data}
                refetch={refetch}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Container>
  )
}
