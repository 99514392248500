import * as React from "react"

import {
  Typography,
  Autocomplete,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material"

import { TradeReference } from "src/types"
import { NumericFormat } from "react-number-format"
import { useTradeReferenceTemplate } from "src/queries/credit/useTradeReferenceTemplate"
import { FormikErrors } from "formik"

export default ({
  businessId,
  values,
  setFieldValue,
  errors,
}: {
  businessId: string | undefined
  values: Partial<TradeReference>
  setFieldValue: (field: string, value: string | string[] | number) => void
  errors?: FormikErrors<TradeReference>
}) => {
  const { data: template } = useTradeReferenceTemplate(businessId)

  return (
    <>
      {template?.fields.map((field) => {
        if (field.type === "select") {
          return (
            <Grid container key={field.label}>
              <Grid item md={8}>
                <Typography>{field.label}</Typography>
              </Grid>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <InputLabel id={`${field.key}-label`}>
                    {field.label}
                  </InputLabel>
                  <Select
                    labelId={`${field.key}-label`}
                    id={`${field.key}-select`}
                    value={values[field.key as keyof TradeReference]}
                    label={field.label}
                    onChange={(e) => {
                      setFieldValue(field.key, e.target.value as string)
                    }}
                  >
                    {field.options?.map((option) => (
                      <MenuItem value={option.value} key={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )
        } else if (field.type === "dollar") {
          return (
            <Grid container key={field.label}>
              <Grid item md={8}>
                <Typography>{field.label}</Typography>
              </Grid>
              <Grid item md={4}>
                <NumericFormat
                  customInput={TextField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    error:
                      errors && !!errors[field.key as keyof TradeReference],
                  }}
                  helperText={
                    errors && errors[field.key as keyof TradeReference]
                  }
                  FormHelperTextProps={{
                    style: { color: "red" },
                  }}
                  value={values[field.key as keyof TradeReference] as string}
                  onChange={(e) => {
                    setFieldValue(field.key, e.target.value)
                  }}
                  allowLeadingZeros
                  thousandSeparator=","
                />
              </Grid>
            </Grid>
          )
        } else if (field.type === "date") {
          return (
            <Grid container key={field.label}>
              <Grid item md={8}>
                <Typography>{field.label}</Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  type="date"
                  value={values[field.key as keyof TradeReference]}
                  onChange={(e) => {
                    setFieldValue(field.key, e.target.value)
                  }}
                  error={errors && !!errors[field.key as keyof TradeReference]}
                  helperText={
                    errors && errors[field.key as keyof TradeReference]
                  }
                />
              </Grid>
            </Grid>
          )
        } else if (field.type === "multiselect") {
          return (
            <Grid container key={field.label}>
              <Grid item md={4}>
                <Typography>{field.label}</Typography>
              </Grid>
              <Grid item md={8}>
                <FormControl fullWidth>
                  <Autocomplete
                    fullWidth
                    multiple
                    sx={{
                      width: "100%",
                    }}
                    options={field.options?.map((option) => option.label) || []}
                    defaultValue={
                      (values[field.key as keyof TradeReference] as string[]) ||
                      []
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Response" />
                    )}
                    value={
                      (values[field.key as keyof TradeReference] as string[]) ||
                      []
                    }
                    onChange={(e, value) => {
                      setFieldValue(field.key, value)
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )
        } else if (field.type === "text") {
          return (
            <Grid container key={field.label}>
              <Typography>{field.label}</Typography>
              <Grid item md={12}>
                <TextField
                  value={values[field.key as keyof TradeReference]}
                  onChange={(e) => {
                    setFieldValue(field.key, e.target.value)
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )
        }
      })}
    </>
  )
}
