import { Divider } from "@mui/material"
import { Container } from "@mui/system"
import CreditSafeCard from "./components/CreditSafeCard"
import ExperianCard from "./components/ExperianCard"
import CreditRiskMonitorCard from "./components/CreditRiskMonitorCard"

export default function ClientExternalCredentialsDetails({
  id,
}: {
  id: string
}) {
  return (
    <>
      <Container sx={{ p: 1 }}>
        <CreditSafeCard id={id} />
        <Divider />
        <ExperianCard id={id} />
        <Divider />
        <CreditRiskMonitorCard id={id} />
      </Container>
    </>
  )
}
