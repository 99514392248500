import { LoadingButton } from "@mui/lab"
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
} from "@mui/material"
import { Stack } from "@mui/system"
import { useFormik } from "formik"
import { useBankReferences } from "src/queries/credit/useBankReferences"
import { usePatchBankReference } from "src/queries/credit/usePatchBankReference"
import { BankReference } from "src/types"
import ReactPhoneInput from "react-phone-input-material-ui"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { useState } from "react"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { PAGE_LABEL_BANK_REFERENCE } from "../../intake_sections/constants"
import ReusableCustomQuestions from "../../components/ReusableCustomQuestions"

export default ({
  id,
  onClose,
  open,
}: {
  id: string //application id
  onClose: () => void
  open: boolean
}) => {
  const { execute, isLoading } = usePatchBankReference(() => {
    refetch()
    onClose()
  })

  const { data } = useBankReferences(id)

  const { data: application, refetch } = useCreditApplication(id)

  const { execute: patchApplication } = usePatchApplication()
  const formik = useFormik({
    initialValues: data && data.length > 0 ? data[0] : ({} as BankReference),
    enableReinitialize: true,
    onSubmit: (values: Partial<BankReference>) => {
      patchApplication(id, { data: bankInfo }, () => {
        execute(id, values)
      })
    },
  })

  const [bankInfo, setBankInfo] = useState(application?.data)

  const onChange = (key: string, value: string) => {
    setBankInfo({ ...bankInfo, [key]: value })
  }

  const onChangeCustomQuestions = (key: string, value: string) => {
    let data = { ...bankInfo }
    if (!data) {
      data = {}
    }
    if (!data["customFields"]) {
      data["customFields"] = {}
    }
    if (!data["customResponsesToBeDeleted"]) {
      data["customResponsesToBeDeleted"] = {}
    }
    if (value) {
      data["customFields"][key] = value
      if (data["customResponsesToBeDeleted"][key]) {
        delete data["customResponsesToBeDeleted"][key]
      }
    } else if (data["customFields"][key]) {
      delete data["customFields"][key]
      while (typeof data["customResponsesToBeDeleted"] === "string") {
        data["customResponsesToBeDeleted"] = JSON.parse(
          data["customResponsesToBeDeleted"],
        )
      }
      data["customResponsesToBeDeleted"][key] = 1
    }
    setBankInfo(data)
  }

  if (!application || !bankInfo) return <></>

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Bank Reference</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Typography variant="h6" style={{ marginBottom: "8px" }}>
          Bank Account Details
        </Typography>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            label="Bank Name"
            value={bankInfo.bankName}
            onChange={(event) => {
              onChange("bankName", event.target.value)
            }}
            // error={Boolean(errors?.bankName)}
          />
          {application.data.businessCountry === "Canada" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={bankInfo.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                // error={Boolean(errors?.bankAccountNumber)}
                // helperText={errors?.bankAccountNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Transit Number"
                value={bankInfo.bankTransitNumber}
                onChange={(event) => {
                  onChange("bankTransitNumber", event.target.value.trim())
                }}
                // error={Boolean(errors?.bankTransitNumber)}
                // helperText={errors?.bankTransitNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Institution Number"
                value={bankInfo.bankInstitutionNumber}
                onChange={(event) => {
                  onChange("bankInstitutionNumber", event.target.value.trim())
                }}
                // error={Boolean(errors?.bankInstitutionNumber)}
                // helperText={errors?.bankInstitutionNumber}
              />
            </Box>
          )}
          {application.data.businessCountry === "United States" && (
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "32px",
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                label="Account Number"
                value={bankInfo.bankAccountNumber}
                onChange={(event) => {
                  onChange("bankAccountNumber", event.target.value.trim())
                }}
                // error={Boolean(errors?.bankAccountNumber)}
                // helperText={errors?.bankAccountNumber}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Routing Number"
                value={bankInfo.bankRoutingNumber}
                onChange={(event) => {
                  onChange("bankRoutingNumber", event.target.value.trim())
                }}
                // error={Boolean(errors?.bankRoutingNumber)}
                // helperText={errors?.bankRoutingNumber}
              />
            </Box>
          )}
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Name"
              value={bankInfo.bankContactName}
              onChange={(event) => {
                onChange("bankContactName", event.target.value)
              }}
              // error={Boolean(errors?.bankContactName)}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Account Manager Email"
              value={bankInfo.bankContactEmail}
              onChange={(event) => {
                onChange("bankContactEmail", event.target.value)
              }}
              // error={Boolean(errors?.bankContactEmail)}
            />
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5%",
              }}
            >
              <ReactPhoneInput
                value={bankInfo.bankContactPhoneNumber}
                country={"us"}
                onChange={(event) => {
                  onChange("bankContactPhoneNumber", "+" + event)
                }}
                label="Account Manager Phone Number"
                component={TextField}
                containerStyle={{ width: "100%", marginTop: "8px" }}
                // inputProps={{
                //   required: true,
                //   error: Boolean(errors?.bankContactPhoneNumber),
                // }}
              />
            </Box>
          </Box>
          <ReusableCustomQuestions
            application={application}
            associatedPage={PAGE_LABEL_BANK_REFERENCE}
            data={bankInfo["customFields"]}
            dataKeyResponses={bankInfo}
            errors={formik.errors}
            onChange={onChangeCustomQuestions}
            filesInMemory={new Map()}
            setFilesInMemory={() => undefined}
            overrideHeight
          />
          <Typography variant="h6" style={{ margin: "16px 0 16px 0" }}>
            Last Known Financials
          </Typography>
          <Grid container>
            <Grid item md={8}>
              <Typography>Checking Account Open Date</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                type="date"
                value={formik.values.checkingAccountOpenDate}
                onChange={(e) => {
                  formik.setFieldValue(
                    "checkingAccountOpenDate",
                    e.target.value,
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Savings Account Open Date</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                type="date"
                value={formik.values.savingsAccountOpenDate}
                onChange={(e) => {
                  formik.setFieldValue("savingsAccountOpenDate", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Checking Account Average Balance</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.checkingBalance}
                onChange={(e) => {
                  formik.setFieldValue("checkingBalance", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Savings Account Average Balance</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.savingsBalance}
                onChange={(e) => {
                  formik.setFieldValue("savingsBalance", e.target.value)
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={8}>
              <Typography>Account Overdrawn</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                value={formik.values.overdrawn}
                onChange={(e) => {
                  formik.setFieldValue("overdrawn", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>NSF Checks per Month</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                value={formik.values.nsfChecksPerMonth}
                onChange={(e) => {
                  formik.setFieldValue("nsfChecksPerMonth", e.target.value)
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={12}>
              <Typography>Comments</Typography>
              <TextField
                type="text"
                fullWidth
                value={formik.values.comments}
                onChange={(e) => {
                  formik.setFieldValue("comments", e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => formik.submitForm()}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
