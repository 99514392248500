import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { EditBusiness } from "src/types"

export function usePatchInternalBusiness(onSuccess?: () => void) {
  const patch = useTypedMutation<EditBusiness>(
    "patch/internal/business",
    onSuccess,
  )
  const execute = (body: EditBusiness, onSuccess: () => void) => {
    const id = body.id
    delete body["id"]
    delete body["logo"]
    delete body["terms"]
    if (body["preferences"]) {
      body["editPreferences"] = JSON.stringify(body["preferences"])
    }

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/business/" + id + "/",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/business".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
