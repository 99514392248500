import { Button, Paper, Stack, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { Formik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useCreateInternalExperianCredentials } from "src/queries/internal/useCreateInternalExperianCredentials"
import { useListInternalExperianCredentials } from "src/queries/internal/useListInternalExperianCredentials"
import { usePatchInternalExperianCredentials } from "src/queries/internal/usePatchInternalExperianCredentials"
import { ClientExperianCredentials } from "src/types"
import { confirm } from "src/components/confirm/"
import { useDeleteInternalExperianCredentials } from "src/queries/internal/useDeleteInternalExperianCredentials"

export default function ClientExternalCredentialsDetails({
  id,
}: {
  id: string
}) {
  const { data, isFetching, refetch } = useListInternalExperianCredentials(id)
  const { execute: executeCreate, isLoading: isLoadingCreateRequest } =
    useCreateInternalExperianCredentials(() => {
      enqueueSnackbar("Experian Credentials Added", { variant: "success" })
    })
  const { execute: executePatch, isLoading: isLoadingPatchRequest } =
    usePatchInternalExperianCredentials(() => {
      enqueueSnackbar("Experian Credentials Edit", { variant: "success" })
    })

  const { execute: executeDelete, isLoading: isLoadingDeleteRequest } =
    useDeleteInternalExperianCredentials(() => {
      enqueueSnackbar("Experian Credentials Deleted", { variant: "success" })
      refetch()
    })

  const formFields = () => {
    return (
      <>
        <Formik
          initialValues={
            data ? data : ({ seller: id } as ClientExperianCredentials)
          }
          onSubmit={(values) => {
            if (data) {
              confirm(
                "Are you Sure you want to Update the credentials for this user?",
              )
                .then(
                  () => {
                    executePatch(values, () => {
                      refetch()
                    })
                    return
                  },
                  () => {
                    console.log("error")
                  },
                )
                .catch(() => {
                  console.log("error")
                })
            } else {
              executeCreate(values, () => {
                refetch()
              })
            }
          }}
        >
          {(props) => (
            <>
              <TextField
                fullWidth
                label="Username"
                id="username"
                name="username"
                margin="normal"
                type="text"
                value={props.values.username}
                onChange={props.handleChange}
                error={Boolean(props.errors?.username)}
              />
              <TextField
                fullWidth
                label="Password"
                id="password"
                name="password"
                margin="normal"
                type="text"
                value={props.values.password}
                onChange={props.handleChange}
                error={Boolean(props.errors?.password)}
              />
              <TextField
                fullWidth
                label="ClientId"
                id="clientId"
                name="clientId"
                margin="normal"
                type="text"
                value={props.values.clientId}
                onChange={props.handleChange}
                error={Boolean(props.errors?.clientId)}
              />
              <TextField
                fullWidth
                label="Client Secret"
                id="clientSecret"
                name="clientSecret"
                margin="normal"
                type="text"
                value={props.values.clientSecret}
                onChange={props.handleChange}
                error={Boolean(props.errors?.clientSecret)}
              />
              <TextField
                fullWidth
                label="SubCode"
                id="subcode"
                name="subcode"
                margin="normal"
                type="text"
                value={props.values.subcode}
                onChange={props.handleChange}
                error={Boolean(props.errors?.subcode)}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleSubmit()
                  }}
                  disabled={isLoadingCreateRequest || isLoadingPatchRequest}
                >
                  {data ? "Update" : "Create"}
                </Button>
                {data && data.id && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      confirm(
                        "Are you Sure you want to Delete the credentials for this user?",
                      )
                        .then(
                          () => {
                            executeDelete(data)
                            return
                          },
                          () => {
                            console.log("error")
                          },
                        )
                        .catch(() => {
                          console.log("error")
                        })
                    }}
                    disabled={isLoadingDeleteRequest}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Formik>
      </>
    )
  }
  return (
    <>
      <Paper variant="outlined" sx={{ marginY: 4, p: 1 }}>
        <Typography variant="h6">Experian Credentials</Typography>
        {data && formFields()}
        {!data && formFields()}
      </Paper>
    </>
  )
}
