import { format } from "date-fns"
import {
  Container,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { FileSummarySection } from "./components/transunion/FileSummarySection"
import { AddOnProductsSection } from "./components/transunion/AddOnProductsSection"
import { startCase } from "lodash"
import { CreditReport } from "src/types"

const TransUnionReportDialog = ({
  report,
  onClose,
}: {
  report: CreditReport
  onClose: () => void
}) => {
  const fileSummary =
    report.creditBureau.product.subject.subjectRecord.fileSummary
  const addOnProducts =
    report.creditBureau.product.subject.subjectRecord.addOnProduct
  const person = report.creditBureau.product.subject.subjectRecord.indicative
  const credit = report.creditBureau.product.subject.subjectRecord.custom.credit

  return (
    <Dialog open maxWidth={"md"}>
      <DialogTitle>TransUnion Credit Report</DialogTitle>
      <DialogContent>
        <Container maxWidth={false} sx={{ py: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <FileSummarySection fileSummary={fileSummary} />

            {/* Personal Information */}
            <Card>
              <CardHeader title="Personal Information" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Full Name
                    </Typography>
                    <Typography variant="body1">
                      {person.name.person.first} {person.name.person.middle}{" "}
                      {person.name.person.last}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Social Security Number
                    </Typography>
                    <Typography variant="body1">
                      XXX-XX-{person.socialSecurity.number.slice(-4)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Date of Birth
                    </Typography>
                    <Typography variant="body1">
                      {format(
                        new Date(person.dateOfBirth["#text"]),
                        "MMMM d, yyyy",
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Employment
                    </Typography>
                    <Typography variant="body1">
                      {startCase(person.employment.occupation)} at{" "}
                      {person.employment.employer.unparsed}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Address */}
            <Card>
              <CardHeader title="Current Address" />
              <CardContent>
                <Typography variant="body1">
                  {person.address.street.number}{" "}
                  {person.address.street.preDirectional}{" "}
                  {person.address.street.name}{" "}
                  {person.address.street.postDirectional}
                  <br />
                  {person.address.location.city},{" "}
                  {person.address.location.state}{" "}
                  {person.address.location.zipCode}-
                  {person.address.location.zipExtension}
                </Typography>
              </CardContent>
            </Card>

            {/* Credit Account Details */}
            <Card>
              <CardHeader title="Credit Account Details" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Account Type
                    </Typography>
                    <Typography variant="body1">
                      {startCase(credit.trade.portfolioType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Account Number
                    </Typography>
                    <Typography variant="body1">
                      XXXX-{credit.trade.accountNumber.slice(-4)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Date Opened
                    </Typography>
                    <Typography variant="body1">
                      {format(
                        new Date(credit.trade.dateOpened["#text"]),
                        "MMMM d, yyyy",
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Credit Limit
                    </Typography>
                    <Typography variant="body1">
                      ${parseInt(credit.trade.creditLimit).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Current Balance
                    </Typography>
                    <Typography variant="body1">
                      ${parseInt(credit.trade.currentBalance).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" color="text.secondary">
                      Account Rating
                    </Typography>
                    <Typography variant="body1">
                      {startCase(credit.trade.accountRating)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Recent Inquiries */}
            <Card>
              <CardHeader title="Recent Inquiries" />
              <CardContent>
                <List>
                  {credit.inquiry.map((inquiry, index) => (
                    <ListItem
                      key={index}
                      divider={index !== credit.inquiry.length - 1}
                    >
                      <ListItemText
                        primary={inquiry.subscriber.name.unparsed}
                        secondary={format(
                          new Date(inquiry.date["#text"]),
                          "MMMM d, yyyy",
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            <AddOnProductsSection addOnProducts={addOnProducts} />
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransUnionReportDialog
