import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { UserToGroup } from "src/types"

export function usePatchAddUserFromGroup(onSuccess?: () => void) {
  const patch = useTypedMutation<UserToGroup>(
    "patch/internal/groups/add",
    onSuccess,
  )
  const execute = (body: UserToGroup, onSuccess: () => void) => {

    patch.mutate(
      {
        method: "patch",
        endpoint: "/internal/groups/add",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: ["/internal/groups/add".replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patch, execute }
}
