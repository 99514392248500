import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"
import { useCreateInternalBulkSelectableUserInvite } from "src/queries/internal/useCreateInternalBulkSelectableUserInvite"
import { Business, Group, SelectableUserBulkInvite } from "src/types"
import { enqueueSnackbar } from "notistack"
import { ExpandMore } from "@mui/icons-material"

export default function ViewInvitedUsersDialog({
  open,
  onClose,
  business,
}: {
  open: boolean
  onClose: () => void
  business: Business
}) {
  const { execute, isLoading } = useCreateInternalBulkSelectableUserInvite()
  const schema = yup.object({
    business: yup.string().required("Required"),
    users: yup.string().required("Required"),
    groups: yup.string().required("Required"),
  })
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">Bulk User Invites</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Formik
            initialValues={
              {
                business: business.id,
              } as SelectableUserBulkInvite
            }
            onSubmit={(values: SelectableUserBulkInvite) => {
              execute(values, () => {
                enqueueSnackbar("Invites Sent", {
                  variant: "success",
                })
                onClose()
              })
            }}
            validationSchema={schema}
          >
            {(props) => (
              <>
                <Stack spacing={4}>
                  <Stack spacing={0}>
                    <Paper variant="outlined">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          File Example
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="caption">
                            firstname |required| ,lastname |required| ,email |required|
                            <br />
                            John,Doe,jd@test.com
                            <br />
                            John1,Doe1,jd1@test.com
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Stack>
                  <Input
                    type="file"
                    id="users"
                    name="users"
                    placeholder="users"
                    onChange={(event) => {
                      if (event.nativeEvent?.target) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const target = event.nativeEvent.target as any
                        props.setFieldValue("users", target.files[0])
                      }
                    }}
                    error={Boolean(props.errors?.users)}
                  />
                  <Stack spacing={1}>
                    <Typography variant="caption">Groups</Typography>
                    <Select
                      fullWidth
                      labelId="group-label"
                      id="groups"
                      label="groups"
                      name="groups"
                      value={props.values.groups}
                      onChange={props.handleChange}
                      error={Boolean(props.errors?.groups)}
                    >
                      <MenuItem disabled value={undefined}>
                        Select one
                      </MenuItem>
                      <MenuItem value={Group.CreditManager}>
                        Credit Manager
                      </MenuItem>
                      <MenuItem value={Group.Buyer}>Buyerr</MenuItem>
                      <MenuItem value={Group.NetNowAdmin}>NetNowAdmin</MenuItem>
                      <MenuItem value={Group.Sales}>Sales</MenuItem>
                      <MenuItem value={Group.NeedsSelfOnboarding}>
                        NeedsSelfOnboarding
                      </MenuItem>
                    </Select>
                  </Stack>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading}
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
