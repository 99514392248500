import { CreditApplication } from "./credit.types"
import { CashApplication } from "./cod.types"

export type Business = {
  id?: string
  name: string
  email: string
  street: string
  city: string
  state: string
  country?: string
  shortenedUrl?: string
  postalCode: string
  phoneNumber?: string
  codatRedirect?: string
  currency?: "USD" | "CAD"
  needsRailz?: boolean
  needsPlaid?: boolean
  needsZum?: boolean
  paymentMethod?: string
  logo?: string | File
  color?: string
  terms?: string | File
  language?: string
  services?: string[]
  preferences?: { [key: string]: string | boolean | string[] }
  stores?: Store[]
  meta?: { [key: string]: string }
}

export interface EditBusiness extends Business {
  editPreferences?: { [key: string]: string | boolean | string[] } | string
}

export type BusinessRelationship = {
  id: string
  buyer: Business
  seller: Business
  creditAvailable: number
  creditAmount: number
}

type External = {
  marketplace: "sharetribe"
  externalId: string
}

export enum Group {
  Buyer = 4,
  CreditManager = 3,
  NeedsSelfOnboarding = 2,
  NetNowAdmin = 1,
  Sales = 5,
}

export type User = {
  id: string
  username: string
  firstName: string
  lastName: string
  email: string
  userToBusiness?: {
    business: Business
    external: Array<External>
  }
  groups?: Group[]
  isSuperuser?: boolean
}

export type UserToBusiness = {
  id: string
  business: Business
  user?: User
  active: boolean
}

export type Store = {
  id: string
  name: string
}

export type Invoice = {
  number: string
  netTerms: number
  invoiceDate: Date
  dueDate: Date
  file: File
  businessRelationship?: BusinessRelationship
  totalDue: string
  confirmedBy?: User
  paid: boolean
}

export type DataKeysMap = {
  [key: number]: string
}

export type ApplicationsSearchResponse = {
  creditApplications: CreditApplication[]
  cashApplications: CashApplication[]
}

export type SearchList = {
  group: string
  title: string
  path: string
  subtitle: string | string[] | undefined
  status: number
}

export type AnonymousBusiness = Pick<
  Business,
  "color" | "name" | "terms" | "country" | "preferences"
> & {
  logo?: string
}
