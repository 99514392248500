import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material"
import { confirm } from "src/components/confirm/"
import { Business, User, UserToBusinessRequest } from "src/types"
import { useInternalSelectableUserInvite } from "src/queries/internal/useInternalSelectableUserInvite"
import { useInternalSelectableUserSendInviteEmail } from "src/queries/internal/useInternalSelectableUserSendInviteEmail"
import { enqueueSnackbar } from "notistack"

export default function ViewInvitedUsersDialog({
  open,
  onClose,
  business,
  refetch,
}: {
  open: boolean
  onClose: () => void
  business: Business
  refetch: () => void
}) {
  const { data: usersData, isFetching } = useInternalSelectableUserInvite(
    business.id || "",
  )
  const { execute } = useInternalSelectableUserSendInviteEmail()
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">User Invites</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          {isFetching && <p>Loading...</p>}
          {usersData && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {usersData.map((user: User) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          confirm(
                            "Are you sure you want to send an email invite to" +
                              user.firstName +
                              " " +
                              user.lastName +
                              "(" +
                              user.email +
                              ")?",
                          )
                            .then(
                              () => {
                                execute(
                                  {
                                    user: user.id,
                                    business: business.id,
                                  } as UserToBusinessRequest,
                                  () => {
                                    refetch()
                                    enqueueSnackbar("Email Sent", {
                                      variant: "success",
                                    })
                                  },
                                )
                                return
                              },
                              () => {
                                console.log("Info")
                              },
                            )
                            .catch(() => {
                              console.log("Error")
                            })
                        }}
                      >
                        Send Email Invite
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
