import * as React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"

export default ({}) => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(search)
  const applicationId = queryParams.get("application_id") || ""
  const businessId = queryParams.get("business_id") || ""
  const type = queryParams.get("type") || "trade-credit"

  // a0qps short for auth0 query params
  const p = localStorage.getItem("a0qps")

  React.useEffect(() => {
    if (applicationId && businessId) {
      navigate({
        pathname: `/${type}/${applicationId}`,
        search: queryString.stringify({
          business_id: businessId,
        }),
      })
    } else if (!businessId && p) {
      const params = queryString.parse(p || "")
      if (!params.business_id) {
        return
      }
      if (!params.type) {
        return
      }
      localStorage.removeItem("a0qps")
      navigate({
        pathname: `/${params.type}/base`,
        search: queryString.stringify({
          business_id: params.business_id,
        }),
      })
    }
  }, [applicationId, businessId, type, navigate, p])

  return <>Redirecting...</>
}
