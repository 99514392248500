import { Paper, Typography, Grid, Divider } from "@mui/material"
import { AddOnProduct } from "src/types/vendors.types"
import { startCase } from "lodash"

interface AddOnProductsSectionProps {
  addOnProducts: AddOnProduct[]
}

export const AddOnProductsSection = ({
  addOnProducts,
}: AddOnProductsSectionProps) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Add-On Products
      </Typography>
      {addOnProducts.map((product, index) => (
        <div key={index}>
          {index > 0 && <Divider sx={{ my: 2 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Code</Typography>
              <Typography>{product.code}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Status</Typography>
              <Typography>{startCase(product.status)}</Typography>
            </Grid>

            {product.highRiskFraudAlert && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  High Risk Fraud Alert
                </Typography>
                <Typography>
                  Search Status:{" "}
                  {startCase(product.highRiskFraudAlert["@searchStatus"])}
                </Typography>
              </Grid>
            )}

            {product.scoreModel && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">Score Model</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Results: {product.scoreModel.score.results}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      Derogatory Alert:{" "}
                      {startCase(product.scoreModel.score.derogatoryAlert)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      File Inquiries Impacted Score:{" "}
                      {startCase(
                        product.scoreModel.score.fileInquiriesImpactedScore,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      No Score Reason:{" "}
                      {startCase(product.scoreModel.score.noScoreReason)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {product.CVCreditSummary && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">CV Credit Summary</Typography>
                <Typography>
                  Search Status:{" "}
                  {startCase(product.CVCreditSummary["@searchStatus"])}
                </Typography>
                <Typography variant="subtitle2">
                  Record Counts (
                  {startCase(
                    product.CVCreditSummary.recordCounts["@reportingPeriod"],
                  )}
                  )
                </Typography>
                <Grid container spacing={1}>
                  {Object.entries(product.CVCreditSummary.recordCounts)
                    .filter(([key]) => key !== "@reportingPeriod")
                    .map(([key, value]) => (
                      <Grid item xs={12} md={4} key={key}>
                        <Typography>
                          {startCase(key)}: {value}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
                <Typography variant="subtitle2">
                  Most Recent Delinquency
                </Typography>
                <Typography>
                  Account Rating:{" "}
                  {product.CVCreditSummary.mostRecentDelinquency.accountRating}
                </Typography>
              </Grid>
            )}

            {product.CVAccountHistorySummary && (
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  CV Account History Summary
                </Typography>
                <Typography>
                  Search Status:{" "}
                  {startCase(product.CVAccountHistorySummary["@searchStatus"])}
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      ))}
    </Paper>
  )
}
