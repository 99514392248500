import { useState } from "react"
import { TypeAnimation } from "react-type-animation"
import ReactMarkdown from "react-markdown"
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material"

// components
import { DropzoneDialog } from "mui-file-dropzone"
import { useUser } from "src/queries"

import { useSnackbar } from "notistack"
import { isFileValid } from "src/utils/file-validator"

import { useAdobeExtractPdf } from "src/queries/vendors/useAdobeExtractPdf"

// ----------------------------------------------------------------------
export default function FinancialAnalysisView() {
  const [uploadedFile, setUploadedFile] = useState(null as File | null)
  const [uploadFileToggle, setUploadFileToggle] = useState(false)
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false)

  const { data: userData } = useUser()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { data: response, execute: uploadFiles } = useAdobeExtractPdf()

  if (!userData) {
    return <></>
  }

  return (
    <Container maxWidth={false}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Financial Analysis Tool
        </Typography>
      </Stack>
      <Typography variant="body1" gutterBottom align="center">
        Welcome to our state-of-the-art Financial Analysis Tool, designed to
        streamline the process of extracting key financial insights from your
        Profit & Loss statements.
      </Typography>{" "}
      <Typography variant="body1" gutterBottom align="center" mb={4}>
        Our tool leverages advanced AI technology to provide you with a
        comprehensive and concise summary, highlighting critical points of your
        financial documents.
      </Typography>
      <Grid container spacing={4} style={{ minHeight: "600px" }}>
        <Grid item xs={12} sm={6}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "100%", position: "relative" }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="8px"
                  mb={2}
                >
                  <Typography variant="h6">Attached Files</Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setUploadFileToggle(true)
                    }}
                  >
                    Upload File
                  </Button>
                </Box>
                {uploadedFile && (
                  <Typography variant="body2" alignSelf="center">
                    {uploadedFile.name}
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  position="relative"
                >
                  {uploadedFile ? (
                    response?.fileBase64 ? (
                      <iframe
                        src={`data:application/pdf;base64,${response.fileBase64}`}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          width: "100%",
                          height: "100%",
                          border: "none",
                        }}
                        title="PDF Viewer"
                      />
                    ) : (
                      <CircularProgress />
                    )
                  ) : (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      No files attached - click the button to upload your
                      financial statement
                    </Typography>
                  )}
                </Box>
                <DropzoneDialog
                  open={uploadFileToggle}
                  onSave={(newFiles) => {
                    if (newFiles.length > 0) {
                      const formData = new FormData()
                      let hasValidationError = false
                      newFiles.forEach((file, index) => {
                        setUploadedFile(file)
                        hasValidationError = !isFileValid(file)
                        if (hasValidationError) {
                          return enqueueSnackbar(
                            "Invalid file type for file " + file.name,
                            {
                              variant: "error",
                            },
                          )
                        }
                        formData.append("file" + index, file)
                      })
                      if (!hasValidationError) {
                        setUploadFileToggle(false)

                        const key = enqueueSnackbar("Uploading file...", {
                          variant: "info",
                          autoHideDuration: null, // Prevent auto-hide, we'll close it manually
                        })

                        uploadFiles(formData, () => {
                          if (key) {
                            closeSnackbar(key)
                          }
                          enqueueSnackbar("File uploaded", {
                            variant: "success",
                          })
                          setIsAnimationCompleted(false)
                        })
                      } else {
                        setUploadFileToggle(false)
                      }
                    }
                  }}
                  acceptedFiles={["application/pdf"]}
                  showPreviews={true}
                  maxFileSize={31457280}
                  onClose={() => {
                    setUploadFileToggle(false)
                  }}
                  filesLimit={1}
                  fileObjects={null}
                  dropzoneText={
                    "Drag and drop a PDF here or click to select a file"
                  }
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card style={{ height: "100%" }}>
            <CardHeader title="Financial Summary" />
            <CardContent>
              {!!response?.summary && !isAnimationCompleted && (
                <TypeAnimation
                  sequence={[
                    (response?.summary as string).split("\n\n").join("\n\n"),
                    100,
                    () => setIsAnimationCompleted(true),
                  ]}
                  wrapper="div"
                  cursor={true}
                  speed={99}
                  repeat={0}
                  style={{ whiteSpace: "pre-line" }}
                />
              )}
              {!!response?.summary && isAnimationCompleted && (
                <Box sx={{ mt: 2 }}>
                  <ReactMarkdown>{response?.summary as string}</ReactMarkdown>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
