import {
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  PAGE_LABEL_PERSONAL_GUARANTY,
} from "src/sections/@dashboard/intake_sections/constants"
import {
  getAddressTypes,
  getRevenueEnabled,
} from "src/sections/@dashboard/intake_sections/schemas/CompanyDetailsSectionSchema"
import {
  getNumberOfContacts,
  isAddressEnabled,
  isControllerEnabled,
  isDobEnabled,
  isLicenseEnabled,
  isOwnersPercentageEnabled,
  isOwnersTitleEnabled,
  isSsnEnabled,
} from "src/sections/@dashboard/intake_sections/schemas/OwnersSectionSchema"
import { getNumberOfGuanators } from "src/sections/@dashboard/intake_sections/schemas/PersonalGuarantySectionSchema"
import { isPageEnabled } from "src/sections/@dashboard/intake_sections/template_helpers"
import { customWordMapping } from "src/statics"
import { ApplicationTemplate } from "src/types"
import { PDFPage, PDFTemplate } from "src/types/credit/pdf"

export const getPdfTemplate = (template?: ApplicationTemplate) => {
  const businessDetails = {
    title: "Business Details",
    items: [
      {
        label: "Applicant Type",
        type: "input",
        spacing: "48",
        dataField: "applicantType",
      },
      {
        label: "Business Type",
        type: "input",
        spacing: "23",
        dataField: "businessType",
      },
      {
        label: "Business Type (Other)",
        type: "input",
        spacing: "23",
        dataField: "businessTypeOther",
      },
      {
        label: "Legal Business Name",
        type: "input",
        spacing: "48",
        dataField: "legalBusinessName",
      },
      {
        label: "Doing Business As (DBA)",
        type: "input",
        spacing: "48",
        dataField: "businessDba",
      },
      {
        label: "First Name",
        type: "input",
        spacing: "23",
        dataField: "firstName",
      },
      {
        label: "Last Name",
        type: "input",
        spacing: "23",
        dataField: "lastName",
      },
      {
        label: "Email",
        type: "input",
        spacing: "48",
        dataField: "email",
      },
      {
        label: "Phone Number",
        type: "input",
        spacing: "23",
        dataField: "userPhoneNumber",
      },
      {
        label: "Extension",
        type: "input",
        spacing: "23",
        dataField: "userPhoneNumberExt",
      },
      {
        label: "Role",
        type: "input",
        spacing: "23",
        dataField: "role",
      },
      {
        label: "Role (Other)",
        type: "input",
        spacing: "23",
        dataField: "roleOther",
      },
      {
        label: customWordMapping("Mailing Address", template),
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "businessAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "businessAddressLine2",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "businessCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "businessRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "businessPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "businessCountry",
      },
    ],
  }

  if (getAddressTypes(template).includes(BUSINESS_CONFIG_SHIPPING_ADDRESS)) {
    businessDetails.items = businessDetails.items.concat([
      {
        label: "Shipping Address",
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "shippingAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "shippingAddressLine2",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "shippingCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "shippingRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "shippingPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "shippingCountry",
      },
    ])
  }
  if (
    getAddressTypes(template).includes(
      BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
    )
  ) {
    businessDetails.items = businessDetails.items.concat([
      {
        label: "Billing Address",
        type: "text",
        spacing: "100",
      },
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "billingAddress",
      },
      {
        label: "Address line 2",
        type: "input",
        spacing: "98",
        dataField: "",
      },
      {
        label: "City",
        type: "input",
        spacing: "23",
        dataField: "billingCity",
      },
      {
        label: "Region",
        type: "input",
        spacing: "23",
        dataField: "billingRegion",
      },
      {
        label: "Postal/Zip Code",
        type: "input",
        spacing: "23",
        dataField: "billingPostCode",
      },
      {
        label: "Country",
        type: "input",
        spacing: "23",
        dataField: "billingCountry",
      },
    ])
  }

  if (getRevenueEnabled(template)) {
    businessDetails.items = businessDetails.items.concat([
      {
        label: "What is the annual revenue of your business?",
        type: "text",
        spacing: "48",
      },
      {
        label: "Revenue",
        type: "input",
        spacing: "48",
        dataField: "revenue",
      },
    ])
  }

  const ownersAndContacts = {
    title: "Owners and Contacts",
    items: [
      {
        label: "Owner 1",
        type: "text",
        spacing: "100",
      },
      {
        label: "Full Name",
        type: "input",
        spacing: "48",
        dataField: "ownerName1",
      },
      {
        label: "Email",
        type: "input",
        spacing: "23",
        dataField: "ownerEmail1",
      },
      {
        label: "Phone Number",
        type: "input",
        spacing: "23",
        dataField: "ownerPhoneNumber1",
      },
    ],
  }

  if (isAddressEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "ownerAddress1",
      },
      {
        label: "Address Line 2",
        type: "input",
        spacing: "98",
        dataField: "ownerAddressLine21",
      },
    ])
  }

  if (isOwnersTitleEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Owner Title",
        type: "input",
        spacing: "23",
        dataField: "ownerTitle1",
      },
    ])
  }

  if (isOwnersPercentageEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Owner Percentage",
        type: "input",
        spacing: "23",
        dataField: "ownerPercentage1",
      },
    ])
  }

  if (isLicenseEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Driver License Number",
        type: "input",
        spacing: "48",
        dataField: "ownerDriverLicense1",
      },
    ])
  }

  if (isSsnEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "SSN/SIN",
        type: "input",
        spacing: "23",
        dataField: "ownerSsn1",
      },
    ])
  }

  if (isDobEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Date of Birth",
        type: "input",
        spacing: "23",
        dataField: "ownerDob1",
      },
    ])
  }

  ownersAndContacts.items = ownersAndContacts.items.concat([
    {
      label: "Owner 2",
      type: "text",
      spacing: "100",
    },
    {
      label: "Full Name",
      type: "input",
      spacing: "48",
      dataField: "ownerName2",
    },
    {
      label: "Email",
      type: "input",
      spacing: "23",
      dataField: "ownerEmail2",
    },
    {
      label: "Phone Number",
      type: "input",
      spacing: "23",
      dataField: "ownerPhoneNumber2",
    },
  ])

  if (isAddressEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Address",
        type: "input",
        spacing: "98",
        dataField: "ownerAddress2",
      },
      {
        label: "Address Line 2",
        type: "input",
        spacing: "98",
        dataField: "ownerAddressLine22",
      },
    ])
  }

  if (isOwnersTitleEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Owner Title",
        type: "input",
        spacing: "23",
        dataField: "ownerTitle2",
      },
    ])
  }

  if (isOwnersPercentageEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Owner Percentage",
        type: "input",
        spacing: "23",
        dataField: "ownerPercentage2",
      },
    ])
  }

  if (isLicenseEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Driver License Number",
        type: "input",
        spacing: "48",
        dataField: "ownerDriverLicense2",
      },
    ])
  }

  if (isSsnEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "SSN/SIN",
        type: "input",
        spacing: "23",
        dataField: "ownerSsn2",
      },
    ])
  }

  if (isDobEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Date of Birth",
        type: "input",
        spacing: "23",
        dataField: "ownerDob2",
      },
    ])
  }

  ownersAndContacts.items = ownersAndContacts.items.concat([
    {
      label: "AP Contact 1",
      type: "text",
      spacing: "100",
    },
    {
      label: "Credit/AP Name",
      type: "input",
      spacing: "48",
      dataField: "apName",
    },
    {
      label: "Credit/AP Role",
      type: "input",
      spacing: "48",
      dataField: "apPosition",
    },
    {
      label: "Credit/AP Email",
      type: "input",
      spacing: "40",
      dataField: "apEmail",
    },
    {
      label: "Credit/AP Phone Number",
      type: "input",
      spacing: "30",
      dataField: "apPhoneNumber",
    },
    {
      label: "Credit/AP Extension",
      type: "input",
      spacing: "24",
      dataField: "apPhoneNumberExt",
    },
  ])

  if (getNumberOfContacts(template) > 1) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "AP Contact 2",
        type: "text",
        spacing: "100",
      },
      {
        label: "Credit/AP Name",
        type: "input",
        spacing: "48",
        dataField: "apName2",
      },
      {
        label: "Credit/AP Role",
        type: "input",
        spacing: "48",
        dataField: "apPosition2",
      },
      {
        label: "Credit/AP 2 Email",
        type: "input",
        spacing: "40",
        dataField: "apEmail2",
      },
      {
        label: "Credit/AP 2 Phone Number",
        type: "input",
        spacing: "30",
        dataField: "apPhoneNumber2",
      },
      {
        label: "Credit/AP 2 Extension",
        type: "input",
        spacing: "24",
        dataField: "apPhoneNumberExt2",
      },
    ])
  }

  if (isControllerEnabled(template)) {
    ownersAndContacts.items = ownersAndContacts.items.concat([
      {
        label: "Controller",
        type: "text",
        spacing: "100",
      },
      {
        label: "Controller Name",
        type: "input",
        spacing: "48",
        dataField: "controllerName",
      },
      {
        label: "Controller Email",
        type: "input",
        spacing: "48",
        dataField: "controllerEmail",
      },
      {
        label: "Controller Role",
        type: "input",
        spacing: "40",
        dataField: "controllerPosition",
      },
      {
        label: "Controller Phone Number",
        type: "input",
        spacing: "40",
        dataField: "controllerPhoneNumber",
      },
      {
        label: "Controller Extension",
        type: "input",
        spacing: "16",
        dataField: "controllerPhoneNumberExt",
      },
    ])
  }

  const personalGuaranty: PDFPage = {
    title: "Personal Guarantor",
    items: [
      {
        label: customWordMapping("Personal Guarantor", template) + " 1",
        type: "text",
        spacing: "100",
      },
      {
        label: "Full Name",
        type: "input",
        spacing: "48",
        dataField: "guarantorName",
      },
      {
        label: "Email",
        type: "input",
        spacing: "48",
        dataField: "guarantorEmail",
      },
      {
        label: "SSN (US) or SIN (Canada)",
        type: "input",
        spacing: "48",
        dataField: "guarantorSsn",
      },
      {
        label: "Phone",
        type: "input",
        spacing: "48",
        dataField: "guarantorPhoneNumber",
      },
    ],
  }

  if (template && getNumberOfGuanators(template) > 1) {
    personalGuaranty.items = personalGuaranty.items.concat([
      {
        label: customWordMapping("Personal Guarantor", template) + " 2",
        type: "text",
        spacing: "100",
      },
      {
        label: "Full Name",
        type: "input",
        spacing: "48",
        dataField: "guarantorName2",
      },
      {
        label: "Email",
        type: "input",
        spacing: "48",
        dataField: "guarantorEmail2",
      },
      {
        label: "SSN (US) or SIN (Canada)",
        type: "input",
        spacing: "48",
        dataField: "guarantorSsn2",
      },
      {
        label: "Phone",
        type: "input",
        spacing: "48",
        dataField: "guarantorPhoneNumber2",
      },
    ])
  }

  const pdfTemplate = {
    "Business Details": businessDetails,
    "Owners and Contacts": ownersAndContacts,
    "Trade References": {
      title: "Trade References",
      items: [
        {
          label: "Reference 1",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName1",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber1",
        },
        {
          label: "Credit/AR Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail1",
        },
        {
          label: "Credit/AR Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber1",
        },
        {
          label: "Credit/AR Fax Number",
          spacing: "48",
          type: "input",
          dataField: "referenceFaxNumber1",
        },
        {
          label: "Reference 2",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName2",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber2",
        },
        {
          label: "Credit/AR Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail2",
        },
        {
          label: "Credit/AR Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber2",
        },
        {
          label: "Credit/AR Fax Number",
          spacing: "48",
          type: "input",
          dataField: "referenceFaxNumber2",
        },
        {
          label: "Reference 3",
          spacing: "100",
          type: "text",
        },
        {
          label: "Business Name",
          spacing: "48",
          type: "input",
          dataField: "referenceBusinessName3",
        },
        {
          label: "Account # (Optional)",
          spacing: "48",
          type: "input",
          dataField: "referenceAccountNumber3",
        },
        {
          label: "Credit/AR Email",
          spacing: "48",
          type: "input",
          dataField: "referenceEmail3",
        },
        {
          label: "Credit/AR Phone Number",
          spacing: "48",
          type: "input",
          dataField: "referencePhoneNumber3",
        },
        {
          label: "Credit/AR Fax Number",
          spacing: "48",
          type: "input",
          dataField: "referenceFaxNumber3",
        },
      ],
    },
    "Bank Reference": {
      title: "Bank Reference",
      items: [
        {
          label: "We will not use this information for any payment processing",
          type: "text",
          spacing: "100",
        },
        {
          label: "Bank Name",
          type: "input",
          spacing: "98",
          dataField: "bankName",
        },
        {
          label: "Account Number",
          type: "input",
          spacing: "31",
          dataField: "bankAccountNumber",
        },
        {
          label: "Transit Number",
          type: "input",
          spacing: "31",
          dataField: "bankTransitNumber",
        },
        {
          label: "Institution Number",
          type: "input",
          spacing: "31",
          dataField: "bankInstitutionNumber",
        },
        {
          label: "Account Manager Name",
          type: "input",
          spacing: "48",
          dataField: "bankContactName",
        },
        {
          label: "Account Manager Email",
          type: "input",
          spacing: "48",
          dataField: "bankContactEmail",
        },
        {
          label: "Account Manager Phone Number",
          type: "input",
          spacing: "98",
          dataField: "bankContactPhoneNumber",
        },
      ],
    },
    "Additional Questions": {
      title: "Additional Questions",
      items: [],
    },
    "Personal Guaranty": {} as PDFPage,
  }

  if (template && isPageEnabled(PAGE_LABEL_PERSONAL_GUARANTY, template)) {
    pdfTemplate["Personal Guaranty"] = personalGuaranty
  }

  return pdfTemplate as PDFTemplate
}
