// ----------------------------------------------------------------------

import { ApplicationsSearchResponse, SearchList } from "src/types"

export function getAllItems(data: ApplicationsSearchResponse | undefined) {
  const items: SearchList[] = []

  if (data) {
    if (data.cashApplications) {
      data.cashApplications.forEach((application) => {
        items.push({
          group: "Cash Application",
          title:
            application.data.legalBusinessName ||
            `${application.data.firstName} ${application.data.lastName}`,
          path: "/cash-applications/" + application.id,
          subtitle: application.collaborators,
          status: application.stage,
        })
      })
    }

    if (data.creditApplications) {
      data.creditApplications.forEach((application) => {
        items.push({
          group: "Credit Application",
          title:
            application.data.legalBusinessName ||
            `${application.data.firstName} ${application.data.lastName}`,
          path: "/applications/" + application.id,
          subtitle:
            typeof application.collaborators === "string"
              ? application.collaborators
              : application.collaborators?.join(", "),
          status: application.stage,
        })
      })
    }
  }
  return items
}

// ----------------------------------------------------------------------

export function splitPath(array: Array<any>, key: string) {
  let stack = array.map((item: any) => ({
    path: [item.title],
    currItem: item,
  }))

  while (stack.length) {
    const { path, currItem } = stack.pop() as { path: any[]; currItem: any }

    if (currItem.path === key) {
      return path
    }

    if (currItem.children?.length) {
      stack = stack.concat(
        currItem.children.map((item: any) => ({
          path: path.concat(item.title),
          currItem: item,
        })),
      )
    }
  }
  return null
}

// ----------------------------------------------------------------------

export function handleLoop(array: Array<any>, subheader: string): any {
  return array?.map((list) => ({
    subheader,
    ...list,
    ...(list.children && {
      children: handleLoop(list.children, subheader),
    }),
  }))
}

// ----------------------------------------------------------------------

export function groupedData(array: Array<any>) {
  const group = array.reduce((groups, item) => {
    groups[item.group] = groups[item.group] || []

    groups[item.group].push(item)

    return groups
  }, {})

  return group
}
