import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"
import { CreditApplication } from "../../types"

export function usePatchCreditApplicationUnarchiveStage(
  onSuccess?: (data: string) => void,
) {
  const ENDPOINT = "/application/unarchive-stage/"
  const patchApplicationStage = useTypedMutation<CreditApplication>(
    "patch/application/unarchive-stage/",
    onSuccess,
  )

  const execute = (applicationId: string, onSuccess?: () => void) => {
    patchApplicationStage.mutate(
      {
        endpoint: ENDPOINT + applicationId + "/",
        body: {},
        method: "patch",
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...patchApplicationStage, execute }
}
